import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import VenueCalendar from './VenueCalendar'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";


export default function VenuePopOver({initialValue, callToAction, type}) {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'calendarCreate-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick} >
        {callToAction}
      </Button>
      <Dialog
        id={id}
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='md'
      >
      <DialogContent>
        <VenueCalendar close={handleClose} initialValue={initialValue} type={type}/>
      </DialogContent>
      </Dialog>
    </div>
  );
}

