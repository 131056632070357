import React from 'react'
import Paper from '@material-ui/core/Paper'
import Visibility from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { t } from '../../../i18n'

class PasswordField extends React.Component {
  state = {
    visible : false
  }

  render() {
    const { record } = this.props
    const { passphrase } = record

    return (
      <Paper
        onMouseDown={() => this.setState({visible:true})}
        onMouseUp={() => this.setState({visible:false})}
        onTouchStart={() => this.setState({visible:true})}
        onTouchEnd={() => this.setState({visible:false})}
      >
        <IconButton>
          <Visibility />
        </IconButton>
        {
          this.state.visible ?
            <Typography variant="subheading" style={{display:'inline-block'}}>
              {passphrase}
            </Typography> :
            <Typography variant="subheading" style={{display:'inline-block'}}>
              {t('Order.password')}
            </Typography>
        }
      </Paper>
    )
  }
}

export default PasswordField
