import React from 'react'
import {
    Create,
    SimpleForm,
    FileInput,
    FileField
} from 'react-admin'
import { t } from '../../i18n'

export const MenuCreate = (props) =>
    <Create title={t('Menu.import')} {...props}>
        <SimpleForm redirect="/product">
            <FileInput source="files" label={t('Menu.xlsx')} placeholder={<p>{t('Menu.tip_import')}</p>} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>

export default MenuCreate
