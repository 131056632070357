import React from 'react'
import {
  Edit,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  ImageInput,
  ImageField,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  ArrayInput,
  SelectInput,
  SelectArrayInput,
  FormDataConsumer,
  DisabledInput,
  Labeled,
  minValue,
  number,
  Toolbar,
  SaveButton,
  DeleteButton,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  required
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import PropTypes from 'prop-types'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  }
})

const CustomToolbar = props => {
  const { classes } = props
  return (
    <Toolbar className={classes.line} style={{ display: 'flex', justifyContent: 'space-between' }} {...props} >
      <SaveButton />
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          return (<Button variant="contained" startIcon={<EuroSymbol />} onClick={() => convertProduct(rest.record)}>
            <EuroSymbol className={classes.leftIcon, classes.iconSmall} />
            {t('Product.convert')}
          </Button>)
        }}
      </FormDataConsumer>
      <DeleteButton undoable={false} />
    </Toolbar>
  )
}

CustomToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

const ProductEditToolbar = withStyles(styles)(CustomToolbar)

const convertProduct = (record) => {
  const jwt = localStorage.getItem('token')
  const id = localStorage.getItem('venue')

  fetch(new Request(`${window._env_.REACT_APP_API_URL}/menu/${id}/product/${record.uuid}/cost`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
    }),
  })).then(() => {
    window.location.href = `/#/cost/${record.uuid}`
  })
}

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

export const ProductEdit = ({ permissions, ...props }) => {

  const deleteProductImage = (record) => {
    const jwt = localStorage.getItem('token')
    const id = localStorage.getItem('venue')

    fetch(new Request(`${window._env_.REACT_APP_API_URL}/menu/${id}/product/${record.uuid}/image`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`
      }),
    })).then(() => {
      window.location.reload()
    })
  }

  return (
    <Edit title={t('Product.edit')} undoable={false} {...props}>
      <TabbedForm redirect="list" toolbar={<ProductEditToolbar />}>
        <FormTab label={t('Product.form_general')}>
          <SelectInput source="type_id" choices={[
            { id: 1, name: t('Product.type_single') },
            { id: 2, name: t('Product.type_bundle') },
            { id: 3, name: t('Product.type_set') }
          ]} validate={required()} style={{ width: '100%' }} disabled/>
          <ImageInput source="pictures" label={t('Product.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Product.error_image')) }} placeholder={<p>{t('Product.tip_image')}</p>} multiple={false}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (!formData.pictures && rest.record.image != null) {
                return (
                  <>
                    <div>
                      <Labeled label={t('Product.original_image')}>
                        <ImageField source="image" {...rest} />
                      </Labeled>
                    </div>
                    <div>
                      <Button variant="outlined" style={{ color: 'red', borderColor: 'red' }} onClick={() => deleteProductImage(rest.record)}>{t('Venue.delete_image')}</Button>
                    </div>
                  </>
                )
              }
            }}
          </FormDataConsumer>
          <TextInputField source='name' label={t('Product.name')} validate={true}  />
          <TextInputField source="recipe" label={t('Product.recipe')} />
          {permissions && permissions.premium && <ReferenceArrayInput label={t('Product.instructions')} source="instructions" reference="instruction" style={{ width: '100%' }}>
            <SelectArrayInput source="id" optionText="name" style={{ width: '100%' }} />
          </ReferenceArrayInput>}
          <ReferenceArrayInput source="categories" label={t('Product.categories')} reference="category" style={{ width: '100%' }}>
            <SelectArrayInput source="id" optionText="name" style={{ width: '100%' }} />
          </ReferenceArrayInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.type_id === 1 || formData.type_id === 2) {
                return (
                  <>
                    <NumberInput label={t('Product.incl_tax_price')} source="net_price" defaultValue={1} validate={[required(), minValue(0)]} style={{ width: '100%' }} />
                    <NumberInput label={t('Product.tax_rate')} format={v => v && v * 100} parse={v => v && v / 100} source="tax_rate" style={{ width: '100%' }} />
                    <FormDataConsumer>
                      {
                        ({ formData, ...rest }) => {
                          if (formData && formData.net_price && formData.tax_rate) {
                            return <DisabledInput
                              label={t('Product.excl_tax_price')}
                              source="price_ht"
                              format={v => Math.round(v * 100) / 100}
                              defaultValue={formData.net_price / (formData.tax_rate + 1)}
                              style={{ width: '100%' }}
                            />
                          }
                        } 
                      }
                    </FormDataConsumer>
                  </>
                )
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData, ...rest }) => {
                if (formData.type_id === 2) {
                  return (
                    <ArrayInput label={t('Category.subcategories')} source="bundle_categories" style={{ width: '100%' }} validate={required()}>
                      <SimpleFormIterator>
                        <TextInputField source='name' label={t('Category.name')} validate={true}  />
                        <BooleanInput source="multiple_choice" label={t('Product.multiple_choice')} style={{ width: '100%' }} />
                        <ArrayInput label={t('Product.title')} source="products" style={{ width: '100%' }} validate={required()}>
                          <SimpleFormIterator>
                            <ReferenceInput label=" " source="id" reference="bundle-products" style={{ width: '100%' }}>
                              <AutocompleteInput optionText="name" style={{ width: '100%' }} />
                            </ReferenceInput>
                            <BooleanInput label={t('Product.custom_price')} source="custom_price" style={{ width: '100%' }} />
                            <FormDataConsumer>
                              {
                                ({ getSource, scopedFormData }) => {
                                  if (scopedFormData && scopedFormData.custom_price) {
                                    return (
                                      <>
                                        <NumberInput label={t('Product.incl_tax_price')} source={getSource("net_price")} defaultValue={0} validate={number()} style={{ width: '100%' }} />
                                      </>
                                    )
                                  }
                                }
                              }
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                } else if (formData.type_id === 3) {
                  return (
                    <ReferenceArrayInput label={t('Product.title')} source="products" reference="bundle-products" validate={required()} style={{ width: '100%' }}>
                      <AutocompleteArrayInput source="id" optionText="name" validate={required()} style={{ width: '100%' }} />
                    </ReferenceArrayInput>
                  )
                } else {
                  return null
                }
              }
            }
          </FormDataConsumer>
          <BooleanInput source="unavailable" label={t('Product.unavailable')} />
          <BooleanInput source="standalone" label={t('Product.standalone')} />
        </FormTab>
        <FormTab label={t('Product.recommendations')}>
          <ReferenceArrayInput label={t('Product.recommended')} source="recommendations" reference="recommended">
            <AutocompleteArrayInput source="id" optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
       
        <FormTab label={t('Product.exceptions')}>
                  <ArrayInput source="exceptions" label={t('Product.exceptions')} fullWidth>
                    <SimpleFormIterator>
                    <FormDataConsumer>
            {
              ({formData, getSource}) => {
                return (
                    <>
                        <ReferenceInput label={t('Calendar.calendars')} source={getSource("calendar_id")} reference="calendar" validate={required()}>
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                        <div style={{display:'flex', width:'100%', alignItems:'baseline'}}> 
                          {
                            (formData.type_id === 1 || formData.type_id === 2) && 
                             <NumberInput label={t('Product.incl_tax_price')} source={getSource("net_price")} defaultValue={formData.net_price} validate={number()} style={{marginRight:50}} />
                          }
                          <NumberInput label={t('Product.position')} source={getSource("position")} defaultValue={formData.position} style={{marginRight:50}} />
                          <BooleanInput source={getSource("unavailable")} label={t('Product.unavailable')} defaultValue={formData.unavailable}/>
                        </div>
                      </>
                      )
              }
            }
          </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
        </FormTab>
  
        <FormTab label={t('Product.translations')}>
          <FormDataConsumer>
            {
              ({ formData, ...rest }) => {
                if (formData.name) {
                  return (<DisabledInput label={t('Product.name')} source="name" style={{ width: '100%' }} />)
                }
              }
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData, ...rest }) => {
                if (formData.recipe) {
                  return (<DisabledInput label={t('Product.recipe')} source="recipe" style={{ width: '100%' }} />)
                }
              }
            }
          </FormDataConsumer>
          <ArrayInput label={t('Product.translations')} source="languages" style={{ width: '100%' }}>
            <SimpleFormIterator>
              <TextInput source="name" label={t('Product.name')} style={{ width: '100%' }} />
              <LongTextInput source="recipe" label={t('Product.recipe')} />
              <SelectInput source="locale" label={t('Product.language')} choices={locales} style={{ width: '100%' }} />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {
              ({ formData, ...rest }) => {
                if (formData.type_id === 2) {
                  return (<ArrayInput label={t('Category.subcategories')} source="bundle_categories" style={{ width: '100%' }}>
                    <SimpleFormIterator disableRemove>
                      <FormDataConsumer>
                        {
                          ({ getSource, scopedFormData }) => {
                            if (scopedFormData && scopedFormData.name) {
                              return (<DisabledInput label={t('Category.name')} source={getSource("name")} style={{ width: '100%' }} />)
                            }
                          }
                        }
                      </FormDataConsumer>
                      <ArrayInput label={t('Product.translations')} source="languages" style={{ width: '100%' }}>
                        <SimpleFormIterator>
                          <TextInput source="name" label={t('Product.name')} validate={required()} style={{ width: '100%' }} />
                          <SelectInput source="locale" label={t('Product.language')} choices={locales} style={{ width: '100%' }} />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </SimpleFormIterator>
                  </ArrayInput>)
                }
              }
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ProductEdit
