import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import { t } from '../../i18n'


const InstructionList = (props) =>
    <List {...props} bulkActionButtons={false} pagination={null} title={t('Instruction.title')}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={t('Instruction.name')} sortable={false} />
      </Datagrid>
    </List>

export default InstructionList
