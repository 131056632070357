import { put, call, takeEvery } from "redux-saga/effects";
import { showNotification } from "react-admin";

import {
  CHANGE_MODE,
  CHANGE_SELECTOR,
  CHANGE_STYLE,
  CANCEL_SAVE_CURRENT_STYLE,
  CHANGE_STYLE_PERIMETER,
  RESTORE_STYLES,
  RESET_ALL_STYLES,
} from "../reducers/styles";
import {
  CANCEL_SAVE_CURRENT_STYLE_REQUEST,
  CHANGE_MODE_REQUEST,
  CHANGE_SELECTOR_REQUEST,
  CHANGE_STYLES_REQUEST,
  CHANGE_STYLE_PERIMETER_REQUEST,
  RECEIVED_CUSTOM_STYLES,
  REQUEST_CUSTOM_STYLES,
  RESTORE_STYLES_REQUEST,
  SAVE_CURRENT_STYLES_REQUEST,
  SHOW_HIDE_HIDDEN_ELEMENTS_REQUEST,
  UPLOAD_FONT_REQUEST,
  RESET_ALL_STYLES_REQUEST,
} from "../actions/styles";
import { ENTER_SELECT_MODE, HIDE_HIDDEN_ELEMENTS, IFRAME, QUIT_SELECT_MODE, SHOW_HIDDEN_ELEMENTS } from "../components/menuCustomization/constants";
import { modes } from "../reducers/styles";
import { t } from "../i18n";

function* changeMode({ payload: { mode } }) {
  const iframe = document.getElementById(IFRAME);

  if (mode === modes.SELECT) {
    iframe.contentWindow.postMessage({ type: ENTER_SELECT_MODE, mode }, "*");
  } else {
    iframe.contentWindow.postMessage({ type: QUIT_SELECT_MODE, mode }, "*");
  }

  yield put({ type: CHANGE_MODE, payload: { mode } });
}

function* changeSelector({ payload: { selector } }) {
  yield put({ type: CHANGE_SELECTOR, payload: { selector } });
}

function* showOrHideHiddenElements({ payload: { elements, show } }) {
  const iframe = document.getElementById(IFRAME);

  if (show) {
    iframe.contentWindow.postMessage({ type: SHOW_HIDDEN_ELEMENTS, elements }, "*");
  } else {
    iframe.contentWindow.postMessage({ type: HIDE_HIDDEN_ELEMENTS, elements }, "*");
  }

  yield put({ type: SHOW_HIDDEN_ELEMENTS });
}

function* changeStyles({ payload: { selector, property, value } }) {
  const iframe = document.getElementById(IFRAME);
  iframe.contentWindow.postMessage({ type: CHANGE_STYLE, selector, property, value }, "*");

  yield put({ type: CHANGE_STYLE, payload: { selector, property, value } });
}

function* restoreStyles({ payload: { selector } }) {
  const iframe = document.getElementById(IFRAME);
  iframe.contentWindow.postMessage({ type: RESTORE_STYLES, selector }, "*");
  yield put({ type: RESTORE_STYLES, payload: { selector } });
}

function* saveCurrentStyles({ payload: { venueId, styles } }) {
  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  };

  if (!config.REACT_APP_STYLE_URL) {
    console.error(`Missing REACT_APP_STYLE_URL environment variable`);
    return;
  }

  try {
    const stylesDeletionRequest = new Request(`${config.REACT_APP_STYLE_URL}/styles?venueId=${venueId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const stylesDeletionResponse = yield call(fetch, stylesDeletionRequest);
    if (stylesDeletionResponse.status < 200 || stylesDeletionResponse.status >= 300) {
      console.log(stylesDeletionResponse.status, "Unable to delete old styles");
      yield put(showNotification(t("Customization.unableToSaveStyles"), "warning"));
      return;
    }

    let stylesToSave = [];

    for (const selector in styles) {
      for (const property in styles[selector]) {
        const value = styles[selector][property];

        stylesToSave.push({ selector, property, value });
      }
    }

    const saveStyle = async (selector, property, value) => {
      const stylesCreationRequest = await fetch(`${config.REACT_APP_STYLE_URL}/styles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ venueId, selector, property, value }),
      });
      if (stylesCreationRequest.status < 200 || stylesCreationRequest.status >= 300) {
        console.log(stylesCreationRequest.status, "Unable to create a style");
        return;
      }
    };

    stylesToSave.forEach((style) => {
      saveStyle(style.selector, style.property, style.value);
    });

    //Force reset
    setTimeout(() => {
      saveStyle("reset", "font-size", "0px");
    }, 500);
    yield put(showNotification(t("Customization.changesSaved")));

    const iframe = document.getElementById(IFRAME);
    iframe.contentWindow.postMessage({ type: SAVE_CURRENT_STYLES_REQUEST }, "*");
  } catch (error) {
    yield put(showNotification(`Error: ${error.message}`, "warning"));
  }
}

function* resetAllStyles({ payload: { venueId } }) {
  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  };

  if (!config.REACT_APP_STYLE_URL) {
    console.error(`Missing REACT_APP_STYLE_URL environment variable`);
    return;
  }
  if (!venueId) {
    console.error(`venueId missing`);
    return;
  }
  try {
    const stylesDeletionRequest = new Request(`${config.REACT_APP_STYLE_URL}/styles?venueId=${venueId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const stylesDeletionResponse = yield call(fetch, stylesDeletionRequest);
    if (stylesDeletionResponse.status < 200 || stylesDeletionResponse.status >= 300) {
      console.log(stylesDeletionResponse.status, "Unable to delete old styles");
      yield put(showNotification(t("Customization.unableToSaveStyles"), "warning"));
      return;
    }

    yield put(showNotification(`Reset`));
    const iframe = document.getElementById(IFRAME);
    iframe.contentWindow.postMessage({ type: RESET_ALL_STYLES }, "*");

    // Trigger reset of css stylesheet
    const saveStyle = async (selector, property, value) => {
      const stylesCreationRequest = await fetch(`${config.REACT_APP_STYLE_URL}/styles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ venueId, selector, property, value }),
      });
      if (stylesCreationRequest.status < 200 || stylesCreationRequest.status >= 300) {
        console.log(stylesCreationRequest.status, "Unable to create a style");
        return;
      }
    };

    //Force reset
    setTimeout(() => {
      saveStyle("reset", "font-size", "0px");
    }, 500);

    yield put({ type: RESET_ALL_STYLES });
    yield put({ type: REQUEST_CUSTOM_STYLES, payload: { venueId } });
  } catch (error) {
    yield put(showNotification(t("Customization.unableToReset"), "warning"));
  }
}

function* cancelSaveCurrentStyle() {
  const iframe = document.getElementById(IFRAME);
  iframe.contentWindow.postMessage({ type: CANCEL_SAVE_CURRENT_STYLE }, "*");

  yield put({ type: CANCEL_SAVE_CURRENT_STYLE });
}

function* changeStylePerimeter({ payload: { selector } }) {
  const iframe = document.getElementById(IFRAME);
  iframe.contentWindow.postMessage({ type: CHANGE_SELECTOR, selector }, "*");

  yield put({ type: CHANGE_STYLE_PERIMETER, payload: { selector } });
}

function* getCustomStyles({ payload: { venueId } }) {
  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  };

  if (!config.REACT_APP_STYLE_URL) {
    console.error(`Missing REACT_APP_STYLE_URL environment variable`);
    return;
  }

  try {
    const customStylesRequest = new Request(`${config.REACT_APP_STYLE_URL}/styles?venueId=${venueId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const customStylesResponse = yield call(fetch, customStylesRequest);
    if (customStylesResponse.status < 200 || customStylesResponse.status >= 300) {
      return;
    }
    const customStylesJson = yield call([customStylesResponse, customStylesResponse.json]);

    const customStylesData = customStylesJson.data.venueStyles || [];

    let savedStyles = {};

    customStylesData.forEach((style) => {
      savedStyles[style.selector] = { ...savedStyles[style.selector], [style.property]: style.value };
    });
    const savedFonts = customStylesJson.data.fonts || [];

    let uniqueFonts = [];

    for (let i = 0; i < savedFonts.length; i++) {
      uniqueFonts[savedFonts[i].name] = savedFonts[i];
    }
    const uniqueFontsArray = Object.values(uniqueFonts);

    yield put({ type: RECEIVED_CUSTOM_STYLES, payload: { savedStyles, savedFonts: uniqueFontsArray } });
  } catch (error) {
    yield put(showNotification(`Error: ${error.message}`, "warning"));
  }
}

function* uploadFont({ payload: { fontFile, venueId } }) {
  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  };

  if (!config.REACT_APP_STYLE_URL) {
    console.error(`Missing REACT_APP_STYLE_URL environment variable`);
    return;
  }
  if (!fontFile) {
    return;
  }
  const formData = new FormData();

  formData.append("file", fontFile);
  try {
    const uploadFontRequest = new Request(`${config.REACT_APP_STYLE_URL}/fonts`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6WyJhcGkiXSwiaWF0IjoxNjQwMDk5OTYwLCJleHAiOjkuOTg5OTk5OTg5OTEwMTE2ZSsyM30.8yYUVbDC-oc5Q0CZ8qVW9qWMdlPH3M2pss8tU5gxOn8"}`,
      },
      body: formData,
    });

    const fontUploadResponse = yield call(fetch, uploadFontRequest);
    if (fontUploadResponse.status < 200 || fontUploadResponse.status >= 300) {
      yield put(showNotification(t("Customization.fontNotUploaded"), "warning"));
      return;
    }
    const fontUploadJson = yield call([fontUploadResponse, fontUploadResponse.json]);

    const linkFontToVenueRequest = new Request(`${config.REACT_APP_STYLE_URL}/venuefonts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6WyJhcGkiXSwiaWF0IjoxNjQwMDk5OTYwLCJleHAiOjkuOTg5OTk5OTg5OTEwMTE2ZSsyM30.8yYUVbDC-oc5Q0CZ8qVW9qWMdlPH3M2pss8tU5gxOn8"}`,
      },
      body: JSON.stringify({ fontId: fontUploadJson.id, venueId }),
    });

    const linkFontToVenueResponse = yield call(fetch, linkFontToVenueRequest);
    if (linkFontToVenueResponse.status < 200 || linkFontToVenueResponse.status >= 300) {
      yield put(showNotification(t("Customization.fontNotUploaded"), "warning"));
      return;
    }

    yield put({ type: REQUEST_CUSTOM_STYLES, payload: { venueId } });
    yield put(showNotification(t("Customization.fontUploaded")));
  } catch (error) {
    yield put(showNotification(`Error: ${error.message}`, "warning"));
  }
}

export default function* stylesSaga() {
  yield takeEvery(CHANGE_SELECTOR_REQUEST, changeSelector);
  yield takeEvery(CHANGE_MODE_REQUEST, changeMode);
  yield takeEvery(CHANGE_STYLES_REQUEST, changeStyles);
  yield takeEvery(SAVE_CURRENT_STYLES_REQUEST, saveCurrentStyles);
  yield takeEvery(CANCEL_SAVE_CURRENT_STYLE_REQUEST, cancelSaveCurrentStyle);
  yield takeEvery(CHANGE_STYLE_PERIMETER_REQUEST, changeStylePerimeter);
  yield takeEvery(REQUEST_CUSTOM_STYLES, getCustomStyles);
  yield takeEvery(RESTORE_STYLES_REQUEST, restoreStyles);
  yield takeEvery(UPLOAD_FONT_REQUEST, uploadFont);
  yield takeEvery(SHOW_HIDE_HIDDEN_ELEMENTS_REQUEST, showOrHideHiddenElements);
  yield takeEvery(RESET_ALL_STYLES_REQUEST, resetAllStyles);
}
