import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormatSizeIcon from "@material-ui/icons/FormatSize";

import { t } from "../../../i18n";

const FontSizeInput = ({ handleChangeStyle, property, perimeter, value }) => {
  const [fontSize, setFontSize] = useState(value["font-size"] || "");

  useEffect(
    () => {
      setFontSize(value["font-size"] || "");
    },
    [value]
  );

  const handleChange = (event) => {
    setFontSize(event.target.value);
    handleChangeStyle(event.target.value, property, perimeter);
  };

  return (
    <FormControl style={{ minWidth: 30, marginRight: 10 }}>
      <InputLabel style={{ fontSize: 14 }}>
        <FormatSizeIcon fontSize='inherit' />
      </InputLabel>
      <Select value={fontSize} onChange={handleChange} style={{ fontSize: 14 }}>
        {Array.from({ length: 59 }, (v, k) => k + 2).map((size, index) => (
          <MenuItem key={`font-size ${size}-${index}`} value={`${size}px`}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default FontSizeInput;
