import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SegmentedControl from './SegmentedControl'
import { Bar, Line } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { t } from '../../../i18n'
import * as Analytics from '../Analytics'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Slider from '@material-ui/lab/Slider'
import Select from 'react-select'
import IntervalPicker from './IntervalPicker'
import { formatDate, sheetName, graphTitle } from '../dashboardHelpers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ExportXLSXButton from './ExportXLSXButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

const styles = theme => ({
	popover: {
		marginTop: '10px',
	},
	popoverContainer: {
		margin: "0px 5px",
		width: '70px',
		height: '20px',
		border: "1px solid black",
		borderRadius: '4px',
		textAlign: 'center',
		fontWeight: 'bold'
	},
	paper: {
		padding: theme.spacing.unit,
	},
	slider: {
		display: 'flex',
		height: '150px',
		margin: '10px 0px 20px 0px',
	},
	title: {
		fontWeight: 'bold',
		height: '10px'
	}
})

const TotalSelector = withStyles(styles)(props => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const { classes } = props
	const [threshold, setThreshold] = useState(5)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
		props.onChange(threshold)
	}

	const handleChange = (event, value) => {
		setThreshold(value)
	}

	return (
		<div className={classes.popoverContainer}>
			<Typography className={classes.title} aria-owns={open ? 'mouse-over-popover' : null} aria-haspopup="true" onMouseEnter={handlePopoverOpen}>
				{"Top " + threshold}
			</Typography>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{ paper: classes.paper }}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={handlePopoverClose}
				disableRestoreFocus>
				<div className={classes.slider}>
					<Slider value={threshold} onChange={handleChange} vertical min={5} step={1} max={props.max} onDragEnd={handlePopoverClose} />
				</div>
			</Popover>
		</div>
	)
})

TotalSelector.propTypes = {
	classes: PropTypes.object.isRequired,
}

const ProductsCard = props => {
	const [productData, setProductData] = useState({})
	const [venue, setVenue] = useState(null)
	const [height, setHeight] = useState(400)
	const [showCalendarPicker, setShowCalendarPicker] = useState(false)
	const [interval, setInterval] = useState(1)
	const [dateRange, setDateRange] = useState(null)
	const [cached, setCached] = useState(null)
	const [top, setTop] = useState(5)
	const [title, setTitle] = useState('')
	const [sheet, setSheet] = useState('')
	const [productList, setProductList] = useState([])
	const [showAll, setShowAll] = useState(false)
	const [loading, setLoading] = useState(false)
	const [filter, setFilter] = useState(null)
	const [selectedControl, setSelectedControl] = useState(1)

	const backgroundColors = [
		'rgba(255, 0, 0, 0.3)',
		'rgba(255, 165, 0, 0.3)',
		'rgba(255, 255, 0, 0.3)',
		'rgba(0, 128, 0, 0.3)',
		'rgba(0, 0, 255, 0.3)',
		'rgba(75, 0, 130, 0.3)',
		'rgba(238, 130, 238, 0.3)',
	]
	const borderColors = [
		'rgb(255, 0, 0)',
		'rgb(255, 165, 0)',
		'rgb(255, 255, 0)',
		'rgb(0, 128, 0)',
		'rgb(0, 0, 255)',
		'rgb(75, 0, 130)',
		'rgb(238, 130, 238)',
	]

	const findTop = (products, limit) => {
		return products.sort((a, b) => b.values.reduce((a, b) => a + b, 0) - a.values.reduce((a, b) => a + b, 0)).slice(0, limit)
	}

	const getProductData = (days, interval) => {
		setLoading(true)
		setProductData(null)
		setDateRange(null)
		setShowCalendarPicker(false)
		setTitle(graphTitle(days))
		setSheet(sheetName(days))
		let now = new Date()
		now.setDate(now.getDate() - 1)
		let end = formatDate(now)
		now.setDate(now.getDate() - days + 1)
		let start = formatDate(now)
		Analytics.getProductViewsInRange(props.venue, start, end, interval).then(
			products => {
				if (products != null && products.length > 0) {
					if (interval === 1) {
						setHeight((products.length + 1) * 30)
						let data = {
							labels: products.map(p => p.label),
							datasets: [{
								data: products.map(p => p.value),
								backgroundColor: backgroundColors,
								borderColor: borderColors,
								borderWidth: 1
							}]
						}
						setProductData(data)
						setCached(data)
						if (filter) {
							filterData(filter, data)
						}
					} else {
						setProductData({
							labels: products[0].labels.map((l, i) => {
								if (l === '00:00') {
									let newDay = new Date(now)
									newDay.setDate(now.getDate() + i / 24)
									return newDay.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' ' + l
								} else {
									return l
								}
							}),
							datasets: findTop(products, top).map((p, i) => ({
								label: p.name,
								data: p.values,
								backgroundColor: backgroundColors[i % backgroundColors.length],
								borderColor: borderColors[i % borderColors.length],
								borderWidth: 3,
								pointRadius: 0,
								fill: true
							}))
						})
						setCached({
							labels: products[0].labels.map((l, i) => {
								if (l === '00:00') {
									let newDay = new Date(now)
									newDay.setDate(now.getDate() + i / 24)
									return newDay.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' ' + l
								} else {
									return l
								}
							}),
							datasets: products.map((p, i) => ({
								label: p.name,
								data: p.values,
								backgroundColor: backgroundColors[i % backgroundColors.length],
								borderColor: borderColors[i % borderColors.length],
								borderWidth: 3,
								pointRadius: 0,
								fill: true
							}))
						})
					}
					setLoading(false)
				} else {
					setLoading(false)
					setProductData(null)
				}
			}
		).catch(error => {
			localStorage.clear()
			props.push('/login')
		})
	}

	const controlOptions = () => {
		switch (interval) {
			case 1: return [
				{
					label: t('Analytics.week'),
					action: () => { getProductData(7, interval); setSelectedControl(0) }
				},
				{
					label: t('Analytics.month'),
					action: () => { getProductData(30, interval); setSelectedControl(1) }
				},
				{
					label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
					action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
				}
			]
			case -3600000: return [
				{
					label: t('Analytics.day'),
					action: () => { getProductData(1, interval); setSelectedControl(0) }
				},
				{
					label: t('Analytics.week'),
					action: () => { getProductData(7, interval); setSelectedControl(1) }
				},
				{
					label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
					action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
				}
			]
		}
	}

	const graphBarOptions = {
		indexAxis: 'y',
		maintainAspectRatio: false,
		barThickness: 20,
		minBarLength: 50,
		animation: !showAll,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: context => {
						var label = context.dataset.label || ''
						if (context.parsed.x !== null) {
							label = t('Analytics.views') + context.parsed.x
						}
						return label
					}
				}
			},
			datalabels: {
				anchor: 'end',
				align: 'left',
				offset: 10,
				font: {
					weight: 'bold'
				}
			}
		},
		scales: {
			x: {
				display: false
			},
			y: {
				grid: {
					display: false
				},
				ticks: {
					autoSkip: false,
					callback: (value, index, values) => productData.labels[index].length > 30 ? productData.labels[index].substring(0, 29) + "..." : productData.labels[index]
				}
			}
		}
	}

	const graphLineOptions = {
		interaction: {
			intersect: false,
			mode: 'nearest',
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: context => {
						var label = context.dataset.label || ''
						if (context.parsed.y !== null) {
							label = context.dataset.label + ': ' + context.parsed.y
						}
						return label
					}
				}
			}
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 1,
				},
				grace: '10%'
			}
		}
	}

	const onDatesPicked = value => {
		if (value && value.length === 2) {
			setLoading(true)
			setProductData(null)
			setTitle(graphTitle(null, value))
			setSheet(sheetName(null, value))
			setShowCalendarPicker(false)
			setDateRange(value)
			Analytics.getProductViewsInRange(props.venue, formatDate(value[0]), formatDate(value[1]), interval).then(
				products => {
					if (products != null && products.length > 0) {
						setHeight((products.length + 1) * 30)
						if (interval === 1) {
							let data = {
								labels: products.map(p => p.label),
								datasets: [{
									data: products.map(p => p.value),
									backgroundColor: backgroundColors,
									borderColor: borderColors,
									borderWidth: 1
								}]
							}
							setProductData(data)
							setCached(data)
							if (filter) {
								filterData(filter, data)
							}
						} else {
							setProductData({
								labels: products[0].labels.map((l, i) => {
									if (l === '00:00') {
										let newDay = new Date(value[0])
										newDay.setDate(value[0].getDate() + i / 24)
										return newDay.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' ' + l
									} else {
										return l
									}
								}),
								datasets: products.map((p, i) => ({
									label: p.name,
									data: p.values,
									backgroundColor: backgroundColors[i % backgroundColors.length],
									borderColor: borderColors[i % backgroundColors.length],
									borderWidth: 3,
									pointRadius: 0,
									fill: true
								}))
							})
							setCached({
								labels: products[0].labels.map((l, i) => {
									if (l === '00:00') {
										let newDay = new Date(value[0])
										newDay.setDate(value[0].getDate() + i / 24)
										return newDay.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' ' + l
									} else {
										return l
									}
								}),
								datasets: products.map((p, i) => ({
									label: p.name,
									data: p.values,
									backgroundColor: backgroundColors[i % backgroundColors.length],
									borderColor: borderColors[i % backgroundColors.length],
									borderWidth: 3,
									pointRadius: 0,
									fill: true
								}))
							})
						}
						setLoading(false)
					} else {
						setLoading(false)
						setProductData(null)
					}
				}
			).catch(error => {
				localStorage.clear()
				props.push('/login')
			})
		}
	}

	const onIntervalPicked = i => {
		if (!isNaN(i)) {
			document.querySelector('#sc30').checked = true
			setInterval(i)
			const days = i === 1 ? 7 : 1
			getProductData(days, i)
		}
	}

	const onTotalPicked = total => {
		setTop(total)
		setProductData({
			labels: cached.labels,
			datasets: cached.datasets.sort((a, b) => b.data.reduce((a, b) => a + b, 0) - a.data.reduce((a, b) => a + b, 0)).slice(0, total)
		})
	}

	const filterData = (options, data) => {
		const findAllIndexes = (arr, val) => {
			var indexes = [], i
			for (i = 0; i < arr.length; i++)
				if (arr[i].toLowerCase().startsWith(val.toLowerCase()))
					indexes.push(i)
			return indexes
		}

		let labels = cached.labels
		let datasets = cached.datasets
		let filteredLabels = []
		let filteredData = []

		if (interval > 0) {
			if (options.length === 0) {
				setHeight((labels.length + 1) * 30)
				setProductData(cached)
				setFilter(null)
				return
			}
			if (data) {
				labels = data.labels
				datasets = data.datasets
			}
			options.forEach(option => {
				findAllIndexes(labels, option.value).forEach(i => {
					filteredLabels.push(labels[i])
					filteredData.push(datasets[0].data[i])
				})
			})
			setProductData({
				labels: filteredLabels,
				datasets: [{
					data: filteredData,
					backgroundColor: backgroundColors,
					borderColor: borderColors,
					borderWidth: 1
				}]
			})
			setHeight((filteredData.length + 1) * 30)
			setFilter(options)
		} else {
			labels = datasets.map(d => d.label)
			options.forEach(option => {
				findAllIndexes(labels, option.value).forEach(i => {
					filteredLabels.push(labels[i])
					filteredData.push(datasets[i].data)
				})
			})
			setProductData({
				labels: cached.labels,
				datasets: filteredData.map((d, i) => ({
					label: filteredLabels[i],
					data: d,
					backgroundColor: backgroundColors[i % backgroundColors.length],
					borderColor: borderColors[i % borderColors.length],
					borderWidth: 3,
					pointRadius: 0,
					fill: true
				}))
			})
		}
	}

	const getFullProductList = () => {
		fetch(`${window._env_.REACT_APP_API_URL}/menu/${localStorage.getItem('venue')}/product`, {
			method: 'GET',
			headers: new Headers({ 'Authorization': `Bearer ${localStorage.getItem('token')}` })
		})
			.then(response => response.json())
			.then(list => setProductList(list.map(p => p.name)))
	}

	const showAllProducts = () => {
		let show = !showAll
		setShowAll(show)
		if (!show) {
			let values = []
			let labels = []
			productData.datasets[0].data.forEach((p, i) => {
				if (p != 0) {
					values.push(p)
					labels.push(productData.labels[i])
				}
			})
			setHeight((labels.length + 1) * 30)
			setProductData({ labels: labels, datasets: [{ ...productData.datasets[0], data: values }] })
		} else {
			if (productData && productData.datasets && productData.datasets.length) {
				let values = productData.datasets[0].data
				let labels = productData.labels
				productList.forEach(p => {
					let index = labels.indexOf(p)
					if (index === -1) {
						values.push(0)
						labels.push(p)
					}
				})
				setHeight((labels.length + 1) * 30)
				setProductData({ labels: labels, datasets: [{ ...productData.datasets[0], data: values }] })
			}
		}
	}

	const exportSchema = () => {
		if (interval === 1) {
			return [
				{
					column: t('Analytics.product_name'),
					type: String,
					value: product => product.name
				},
				{
					column: t('Analytics.product_amount'),
					type: Number,
					format: '#,##0',
					value: product => product.value
				}
			]
		} else {
			if (productData && productData.datasets) {
				return [
					{
						column: t('Analytics.date'),
						type: String,
						value: product => product.date,
						width: 15
					},
					...productData.datasets.map(d => ({
						column: d.label,
						type: Number,
						format: '#,##0',
						value: product => product[d.label],
						width: d.label.length + 2
					}))
				]
			}
			return []
		}
	}

	const exportData = () => {
		let data = []
		if (interval === 1) {
			if (productData && productData.labels) {
				productData.labels.forEach((l, i) => {
					data.push({ name: l, value: productData.datasets[0].data[i] })
				})
			}
			return data
		} else {
			if (productData && productData.labels && productData.datasets) {
				let obj = {}
				var i
				for (i = 0; i < productData.labels.length; i++) {
					productData.datasets.forEach(d => obj[d.label] = d.data[i])
					data.push({ date: productData.labels[i], ...obj })
				}
			}
			return data
		}
	}

	useEffect(() => {
		if (props.venue && !venue) {
			setVenue(props.venue)
			getFullProductList()
			getProductData(30, 1)
		}
	}, [])

	return (
		<Card style={interval === 1 ? { width: '97%', height: '450px', margin: '5px', overflow: 'scroll' } : { width: '97%', minHeight: '400px', margin: '5px', overflow: 'scroll' }}>
			<CardContent style={interval === 1 ? { height: height + 'px', position: 'relative' } : { minHeight: '400px', position: 'relative' }}>
				{loading &&
					<div style={{ backgroundColor: 'white', position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
						<CircularProgress style={{ position: 'absolute', top: '50%', left: '45%', zIndex: 2 }} size={50} thickness={3} />
					</div>
				}
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', position: 'sticky', top: '0px', backgroundColor: 'white', zIndex: 1 }}>
					<Typography variant="headline" style={{ float: 'left' }}>{t('Analytics.product_views')}</Typography>
					<div style={{ flex: 1 }} />
					<div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
						{cached && <Select
							isMulti
							name="products"
							placeholder={t('Product.search')}
							styles={{
								control: (provided, state) => ({
									...provided,
									boxShadow: "none",
									border: "none",
									maxWidth: 500,
									width: 500,
									fontSize: 15,
									fontFamily: "Roboto, sans-serif"
								}),
								option: (provided, state) => ({
									...provided,
									backgroundColor: state.isFocused && "lightgray",
									color: state.isFocused && "blue",
									fontFamily: "Roboto, sans-serif"
								})
							}}
							options={interval === 1 ? cached.labels.map(l => ({ value: l, label: l })) : cached.datasets.map(d => ({
								value: d.label,
								label: d.label
							}))}
							className="basic-multi-select"
							classNamePrefix="select"
							onChange={(value, meta) => filterData(value)}
						/>}
						{interval === 1 && <FormControlLabel
							control={<Checkbox style={{ height: "20px" }} checked={showAll} onChange={showAllProducts} color="primary" />}
							label={t("Analytics.show_all")}
						/>}
						{interval < 0 && <TotalSelector max={cached && cached.datasets ? cached.datasets.length : 100} onChange={onTotalPicked} />}
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<IntervalPicker onIntervalPicked={onIntervalPicked} />
							<SegmentedControl key="sc3" className="sc3" options={controlOptions()} selected={selectedControl} />
							<ExportXLSXButton fileName="products.xlsx" data={exportData()} schema={exportSchema()} sheet={sheet} />
						</div>
					</div>
				</div>
				{showCalendarPicker &&
					<div style={{ display: 'block', position: 'absolute', right: '5%', zIndex: 10 }}>
						<Calendar onChange={onDatesPicked} value={dateRange} defaultView="month" selectRange={true} returnValue="range" />
					</div>
				}
				<Typography variant="body2" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{title}</Typography>
				{interval === 1 && productData && Object.keys(productData).length > 0 && <Bar key="productBar" data={productData} options={graphBarOptions} plugins={[ChartDataLabels]} />}
				{interval === 1 && <div style={{ height: '50px', width: '100%', background: 'linear-gradient(0deg, white, white 80%, rgba(255, 255, 255, 0.75) 85%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0.25) 95%, rgba(255, 255, 255, 0) 100%)', position: 'sticky', bottom: '0px', zIndex: 0 }} />}
				{interval < 0 && productData && Object.keys(productData).length > 0 && <Line id="productLine" key="productLine" data={productData} options={graphLineOptions} />}
				{(!productData || (productData.datasets || []).length === 0) && <Typography variant="headline" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{t('Analytics.no_data')}</Typography>}
			</CardContent>
		</Card>
	)
}

ProductsCard.propTypes = {
	push: PropTypes.func
}

export default connect(
	null,
	{ push }
)(ProductsCard)