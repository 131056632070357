import React from 'react'
import { List, Datagrid, TextField, DateField, Pagination } from 'react-admin'
import { t } from '../../i18n'

const CustomerPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const CustomerList = (props) =>
    <List {...props} bulkActionButtons={false} perPage={25} pagination={<CustomerPagination />} title={t('Product.title')}>
      <Datagrid>
        <TextField source="name" label={t('Customer.name')} sortable={false} />
        <DateField source="creation_date" showTime={true} label={t('Customer.creation_date')} sortable={false}/>
        <TextField source="email" label={t('Customer.email')} sortable={false} />
        <TextField source="phone" label={t('Customer.phone')} sortable={false} />
      </Datagrid>
    </List>

export default CustomerList
