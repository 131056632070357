import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import { t } from "../../i18n";

import { uploadFont } from "../../actions/styles";

const styles = (theme) => ({});

const FontUpload = ({ venueId, ...props }) => {
  const { uploadFont } = props;

  const [fontName, setFontName] = useState("");
  const [fontFile, setFontFile] = useState();

  const handleFont = (e) => {
    e.preventDefault();
    setFontName(e.target.files[0].name);
    setFontFile(e.target.files[0]);
  };

  const handleUploadFont = (e) => {
    e.preventDefault();

    if (!fontFile || !venueId) {
      console.log("font File or venueId missing");
      return;
    }
    uploadFont(fontFile, venueId);
    setFontFile();
    setFontName("");
  };

  return (
    <>
      {fontName && fontFile ? (
        <Button
          variant='contained'
          color='primary'
          onClick={handleUploadFont}
          style={{ fontSize: 10, display: "flex", flexDirection: "column", marginRight: 15 }}
        >
          {t("Customization.save")}
          {" : "}
          {fontName}
        </Button>
      ) : (
        <Button variant='outlined' color='primary' component='label' style={{ marginRight: 15 }}>
          {t("Customization.uploadFont")}
          <input type='file' name='font' hidden onChange={handleFont} />
        </Button>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  { uploadFont }
)(withStyles(styles)(FontUpload));
