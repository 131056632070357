import React, { useState } from 'react'
import { List, Datagrid, TextField, ReferenceArrayField, SingleFieldList, ChipField, Mutation, Filter, TextInput, BooleanField } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import { t } from '../../i18n'

const UnavailableSwitch = ({ source, record }) => {

  const [checked, setChecked] = useState(!!record[source])

  return (
    <Mutation
      type="UPDATE"
      resource="category-availability"
      payload={{ id: record.id, data: { ...record, [source]: !checked } }}
      options={{ undoable: false }}>
      {
        (toggleUnavailable) => (
          <Switch
            onClick={event => {
              event.stopPropagation()
              setChecked(!checked)
              record[source] = !record[source]
              toggleUnavailable()
            }}
            color="primary"
            checked={checked} />
        )
      }
    </Mutation>
  )
}

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t('Category.search')} source="name" alwaysOn style={{ width: '300px' }} />
  </Filter>
)

export default ({ permissions, ...props }) =>
  permissions && permissions.reader ?
    <List {...props} bulkActionButtons={false} pagination={null} title={t('Category.title')} filters={<CategoryFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" label={t('Category.name')} sortable={true} />
        <ReferenceArrayField label={t('Product.menus')} reference="section" source="sections">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="unavailable" label={t('Category.unavailable')} sortable={false} />
      </Datagrid>
    </List>
    :
    <List {...props} bulkActionButtons={false} pagination={null} title={t('Category.title')} filters={<CategoryFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={t('Category.name')} sortable={true} />
        <ReferenceArrayField label={t('Product.menus')} reference="section" source="sections">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <UnavailableSwitch source="unavailable" label={t('Category.unavailable')} sortable={false} />
      </Datagrid>
    </List>