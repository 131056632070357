import React from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import { IFRAME, PREPARE_IFRAME } from "./constants";

const styles = (theme) => ({
  iphone8: {
    height: "667px",
    width: "375px",
    borderRadius: 20,
    transform: "scale(0.76)",
    transformOrigin: "top",
    border: "12px solid black",
  },

  iphoneX: {
    height: "812px",
    width: "375px",
    borderRadius: 20,
    transform: "scale(0.63)",
    transformOrigin: "top",
    border: "12px solid black",
  },
});

const MenuPreview = ({ venueId, selectMode, ...props }) => {
  const { classes } = props;
  const { previewFormat } = props;
  const config = {
    REACT_APP_WEB_URL: window._env_.REACT_APP_WEB_URL,
  };
  const prepareIframe = () => {
    const iframe = document.getElementById(IFRAME);
    iframe.contentWindow.postMessage({ type: PREPARE_IFRAME }, "*");
  };

  return (
    <div style={{ display: "flex", flex: 1, justifyContent: "center", position: "relative" }}>
      <iframe
        id={IFRAME}
        src={`${config.REACT_APP_WEB_URL}/#${venueId}`}
        className={previewFormat === "Iphone 8" ? classNames(classes.iphone8) : classNames(classes.iphoneX)}
        title='menu'
        onLoad={() => prepareIframe()}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    previewFormat: state.styles.previewFormat,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(MenuPreview));
