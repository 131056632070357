import React from 'react'
import { Edit } from 'react-admin'
import RestaurantReadyOrderForm from './edit/RestaurantReadyOrderForm'
import RestaurantInProgressOrderForm from './edit/RestaurantInProgressOrderForm'
import InertOrderForm from './edit/InertOrderForm'

const FormSwitch = (props) => {
  const { record } = props
  const { status } = record

  return (
    status === 'READY' ? <RestaurantReadyOrderForm {...props}/> :
    status === 'IN_PROGRESS' ? <RestaurantInProgressOrderForm {...props}/> :
    <InertOrderForm {...props}/>
  )
}

class OrderEdit extends React.Component {
  render() {
    return (
      <Edit {...this.props}>
        <FormSwitch />
      </Edit>
    );
  }
}

export default OrderEdit
