import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import RestaurantReadyOrderForm from './edit/RestaurantReadyOrderForm'
import RestaurantInProgressOrderForm from './edit/RestaurantInProgressOrderForm'
import OnHoldOrderForm from './edit/OnHoldOrderForm'
import RestaurantPendingOrderForm from './edit/RestaurantPendingOrderForm'
import InertOrderForm from './edit/InertOrderForm'

const FormSwitch = (props) => {
  const { record } = props
  const { status } = record

  switch (status) {
    case 'ON_HOLD': return <OnHoldOrderForm {...props} />
    case 'PENDING': return <RestaurantPendingOrderForm {...props} />
    case 'IN_PROGRESS': return <RestaurantInProgressOrderForm {...props} />
    case 'READY': return <RestaurantReadyOrderForm {...props} />
    default: return <InertOrderForm {...props} />
  }
}

export default props =>
    <Edit title=" " {...props}>
        <FormSwitch {...props} />
    </Edit>