import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SegmentedControl from './SegmentedControl'
import { t } from '../../../i18n'
import * as Analytics from '../Analytics'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { formatDate, sheetName, graphTitle } from '../dashboardHelpers'
import ExportXLSXButton from './ExportXLSXButton'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

const LinksCard = props => {
	const [linkData, setLinkData] = useState([])
	const [showCalendarPicker, setShowCalendarPicker] = useState(false)
	const [dateRange, setDateRange] = useState()
	const [title, setTitle] = useState('')
	const [sheet, setSheet] = useState('')
	const [selectedControl, setSelectedControl] = useState(1)
	const sources = ["facebook", "instagram", "maps", "tripadvisor", "website"]

	const getLinkData = days => {
		setShowCalendarPicker(false)
		setTitle(graphTitle(days))
		setSheet(sheetName(days))
		let now = new Date()
		now.setDate(now.getDate() - 1)
		let end = formatDate(now)
		now.setDate(now.getDate() - days)
		let start = formatDate(now)
		Analytics.getLinksInRange(props.venue, start, end).then(
			data => {
				if (data && data.length) {
					setLinkData(sources.map(s => ({ label: s, value: (data.find(e => e.label === s) || {value : 0}).value })))
				} else {
					setLinkData(sources.map(s => ({ label: s, value: 0 })))
				}
			}
		).catch(error => {
			localStorage.clear()
			props.push('/login')
		})
	}

	const controlOptions = [
		{
			label: t('Analytics.week'),
			action: () => { getLinkData(7); setSelectedControl(0) }
		},
		{
			label: t('Analytics.month'),
			action: () => { getLinkData(30); setSelectedControl(1) }
		},
		{
			label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
			action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
		}
	]

	const exportSchema = () => {
		return [
			{
				column: t('Analytics.links'),
				type: String,
				value: link => link.label,
				width: 15
			},
			{
				column: t('Analytics.users'),
				type: Number,
				format: '#,##0',
				value: link => link.value
			}
		]
	}

	useEffect(() => {
		if (props.venue) {
			getLinkData(30)
		}
	}, [])

	const onDatesPicked = value => {
		if (value && value.length === 2) {
			setDateRange(value)
			setShowCalendarPicker(false)
			setTitle(graphTitle(null, value))
			setSheet(sheetName(null, value))
			Analytics.getLinksInRange(props.venue, formatDate(value[0]), formatDate(value[1])).then(
				data => {
					if (data && data.length) {
						setLinkData(sources.map(s => ({ label: s, value: (data.find(e => e.label === s) || {value : 0}).value })))
					} else {
						setLinkData(sources.map(s => ({ label: s, value: 0 })))
					}
				}
			).catch(error => {
				localStorage.clear()
				props.push('/login')
			})
		}
	}

	const linkIcon = link => {
		switch (link) {
			case "facebook": return "/facebook-icon.png"
			case "instagram": return "/instagram-icon.png"
			case "tripadvisor": return "/tripadvisor-icon.png"
			case "maps": return "/gmaps-icon.png"
			case "website": return "/web-icon.png"
		}
	}

	return (
		<Card style={{ width: '97%', height: '170px', margin: '5px', textAlign: 'center' }}>
			<CardContent>
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
					<Typography variant="headline">{t('Analytics.links')}</Typography>
					<div style={{ flex: 1 }}></div>
					<SegmentedControl key="sc5" className="sc5" options={controlOptions} style={{ flex: 1, width: '100%' }} selected={selectedControl} />
					<ExportXLSXButton fileName="links.xlsx" data={linkData} schema={exportSchema()} sheet={sheet} />
				</div>
				<Typography variant="body2" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{title}</Typography>
				{showCalendarPicker &&
					<div style={{ display: 'block', position: 'absolute', right: '5%', zIndex: 10 }}>
						<Calendar onChange={onDatesPicked} value={dateRange} defaultView="month" selectRange={true} returnValue="range" />
					</div>
				}
				{linkData && linkData.length > 0 &&
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'left' }}>
						<div style={{ display: "flex", flexDirection: "row", height: "150px", textTransform: "capitalize", marginTop: "20px", width: "100%", justifyContent: 'space-between' }}>
							{
								linkData.map(l =>
									<div key={l.label} style={{ textAlign: "left", fontFamily: "Roboto, sans-serif", lineHeight: "30px" }}>
										<img src={linkIcon(l.label)} className="icon" alt="insta" height="30px" style={{ marginRight: "5px", verticalAlign: "middle" }} />
										{l.label + `: ${l.value}`}
									</div>)
							}
						</div>
					</div>
				}
				{(!linkData || linkData.length === 0) && <Typography variant="headline" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{t('Analytics.no_data')}</Typography>}
			</CardContent>
		</Card>
	)
}

LinksCard.propTypes = {
	push: PropTypes.func
}

export default connect(
	null,
	{ push }
)(LinksCard)