import React from 'react'
import {
	Show,
	SimpleShowLayout,
	TextField,
	ImageField,
	BooleanField,
	ArrayField,
	SingleFieldList,
	ChipField
} from 'react-admin'
import { t } from '../../i18n'

export default props =>
	<Show title={t('Menu.edit')} {...props}>
		<SimpleShowLayout>
			<ImageField source="image" title="title" />
			<TextField source='name' label={t('Menu.name')} />
			<TextField source="description" label={t('Menu.description')} />
			<TextField source="external_url" label={t('Menu.external_url')} style={{ width: '100%' }} />
			<BooleanField label={t('Menu.unavailable')} source="unavailable" />
			<ArrayField source="categories" reference="category">
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ArrayField>
		</SimpleShowLayout>
	</Show>
	