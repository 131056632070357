import React from 'react'
import { Admin, Resource, resolveBrowserLocale } from 'react-admin'
import { Route } from 'react-router-dom'

import SimpleLogin from './components/login/SimpleLogin'

import Dashboard from './components/dashboard/Dashboard'

import ApperoLayout from './components/layout/Layout'

import UserList from './components/user/UserList'
import UserCreate from './components/user/UserCreate'
import UserEdit from './components/user/UserEdit'

import VenueList from './components/venue/VenueList'
import VenueCreate from './components/venue/VenueCreate'
import VenueEdit from './components/venue/VenueEdit'

import SectionList from './components/section/SectionList'
import SectionCreate from './components/section/SectionCreate'
import SectionEdit from './components/section/SectionEdit'
import SectionShow from './components/section/SectionShow'

import CategoryList from './components/category/CategoryList'
import CategoryCreate from './components/category/CategoryCreate'
import CategoryEdit from './components/category/CategoryEdit'
import CategoryShow from './components/category/CategoryShow'

import ProductList from './components/product/ProductList'
import ProductCreate from './components/product/ProductCreate'
import ProductEdit from './components/product/ProductEdit'
import ProductShow from './components/product/ProductShow'

import MenuCustomization from './components/menuCustomization/MenuCustomization'

import TagList from './components/tag/TagList'
import TagCreate from './components/tag/TagCreate'
import TagEdit from './components/tag/TagEdit'

import BannerList from './components/banner/BannerList'
import BannerEdit from './components/banner/BannerEdit'
import BannerCreate from './components/banner/BannerCreate'

import MenuCreate from './components/menu/MenuCreate'

import CostList from './components/cost/CostList'
import CostCreate from './components/cost/CostCreate'
import CostEdit from './components/cost/CostEdit'

import InstructionList from './components/instruction/InstructionList'
import InstructionCreate from './components/instruction/InstructionCreate'
import InstructionEdit from './components/instruction/InstructionEdit'

import CustomerList from './components/customer/CustomerList'

import ContactList from './components/contact/ContactList'

import TableList from './components/table/TableList'
import TableCreate from './components/table/TableCreate'
import TableEdit from './components/table/TableEdit'

import RestaurantOrderList from './components/order/RestaurantOrderList'
import RestaurantOrderEdit from './components/order/RestaurantOrderEdit'
import RestaurantDeliveriesList from './components/order/RestaurantDeliveriesList'
import RestaurantDeliveriesEdit from './components/order/RestaurantDeliveriesEdit'

import ProfileEdit from './components/profile/ProfileEdit'

import Console from './components/console/Console'

import HistoryList from './components/history/HistoryList'
import HistoryDump from './components/history/HistoryDump'

import PassphraseList from './components/pass/PassphraseList'
import PassphraseCreate from './components/pass/PassphraseCreate'
import PassphraseEdit from './components/pass/PassphraseEdit'

import DiscountList from './components/discount/DiscountList'
import DiscountCreate from './components/discount/DiscountCreate'
import DiscountEdit from './components/discount/DiscountEdit'

import { authProvider, dataProvider, rtProvider } from './clients/providers'

import People from '@material-ui/icons/People'
import Place from '@material-ui/icons/Place'
import Label from '@material-ui/icons/Label'
import LabelOutline from '@material-ui/icons/LabelOutline'
import LocalBar from '@material-ui/icons/LocalBar'
import Payment from '@material-ui/icons/Payment'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import History from '@material-ui/icons/History'
import Lock from '@material-ui/icons/Lock'
import Publish from '@material-ui/icons/Publish'
import RestaurantMenu from '@material-ui/icons/RestaurantMenu'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import Contacts from '@material-ui/icons/Contacts'
import Info from '@material-ui/icons/Info'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CallToActionIcon from '@material-ui/icons/CallToAction';

import venueSaga from './sagas/venue'
import venueReducer from './reducers/venue'
import stylesSaga from './sagas/styles'
import stylesReducer from './reducers/styles'

import { t } from './i18n'
import frenchMessages from 'ra-language-french'
import englishMessages from 'ra-language-english'
import spanishMessages from 'ra-language-spanish'
import portugueseMessages from 'ra-language-portuguese'
import germanMessages from 'ra-language-german'
import italianMessages from 'ra-language-italian'


const adminResources = (permissions) => [
  permissions.premium && <Resource name="order" list={RestaurantOrderList} edit={RestaurantOrderEdit} icon={Payment} options={{ label: t('Resources.order') }} />,
  permissions.premium && <Resource name="delivery" list={RestaurantDeliveriesList} edit={RestaurantDeliveriesEdit} icon={HourglassEmpty} options={{ label: t('Resources.delivery') }} />,
  permissions.premium && <Resource name="history" list={HistoryList} icon={History} options={{ label: t('Resources.history') }} />,
  (permissions.premium || permissions.smart) && <Resource name="section" list={SectionList} create={SectionCreate} edit={SectionEdit} icon={LabelOutline} options={{ label: t('Resources.menu') }} />,
  (permissions.premium || permissions.smart) && <Resource name="category" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} icon={Label} options={{ label: t('Resources.category') }} />,
  (permissions.premium || permissions.smart) && <Resource name="product" list={ProductList} create={ProductCreate} edit={ProductEdit} icon={LocalBar} options={{ label: t('Resources.product') }} />,
  // (permissions.premium || permissions.smart) && <Resource name='tag' list={TagList} create={TagCreate} edit={TagEdit} icon={LocalOfferIcon} options={{ label: t('Resources.tag') }} />,
  // (permissions.premium || permissions.smart) && <Resource name='banner' list={BannerList} create={BannerCreate} edit={BannerEdit} icon={CallToActionIcon} options={{ label: t('Resources.banner') }} />,
  (permissions.premium || permissions.smart) && <Resource name="instruction" list={InstructionList} create={InstructionCreate} edit={InstructionEdit} icon={Info} options={{ label: t('Resources.instruction') }} />,
  permissions.premium && <Resource name="cost" list={CostList} create={CostCreate} edit={CostEdit} icon={EuroSymbol} options={{ label: t('Resources.cost') }} />,
  permissions.premium && <Resource name="table" list={TableList} create={TableCreate} edit={TableEdit} icon={RadioButtonUnchecked} options={{ label: t('Resources.tables') }} />,
  (permissions.premium || permissions.smart) && <Resource name="guest" list={CustomerList} icon={ImportContactsIcon} options={{ label: t('Resources.guest') }} />,
  (permissions.premium || permissions.smart) && <Resource name="customer" list={ContactList} icon={Contacts} options={{ label: t('Resources.customer') }} />,
  (permissions.premium || permissions.smart) && <Resource name="menu" create={MenuCreate} icon={Publish} options={{ label: t('Resources.import') }} />,
  <Resource name="recommended" />,
  <Resource name="user" list={UserList} create={UserCreate} edit={UserEdit} icon={People} options={{ label: t('Resources.user') }} />,
  <Resource name="venue" list={VenueList} create={VenueCreate} edit={VenueEdit} icon={Place} options={{ label: t('Resources.venue') }} />,
  <Resource name="dump" list={HistoryDump} options={{ label: t('Resources.dump') }} icon={History} />,
  <Resource name="pass" list={PassphraseList} create={PassphraseCreate} edit={PassphraseEdit} options={{ label: 'Passphrases' }} icon={Lock} />,
  <Resource name="discount" list={DiscountList} create={DiscountCreate} edit={DiscountEdit} options={{ label: t('Resources.discount') }} icon={MonetizationOnIcon} />,
  <Resource name="profile" />,
  <Resource name="businesses" />,
  <Resource name="registered-user" />,
  <Resource name="bundle-products" />,
  <Resource name="section-availability" />,
  <Resource name="category-availability" />,
  <Resource name="product-availability" />,
  <Resource name='calendar' />,
]

const basicResources = (permissions) => [
  <Resource name="venue" />,
  <Resource name="user" />,
  <Resource name="profile" />,
  <Resource name="registered-user" />,
  <Resource name="bundle-products" />,
  <Resource name="businesses" />,
  <Resource name='calendar' />,
]

const smartResources = (permissions) => {
  let rights = []
  if (permissions.manager) {
    rights = [
      ...rights,
      <Resource name="section" list={SectionList} create={SectionCreate} edit={SectionEdit} icon={LabelOutline} options={{ label: t('Resources.menu') }} />,
      <Resource name="category" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} icon={Label} options={{ label: t('Resources.category') }} />,
      <Resource name="product" list={ProductList} create={ProductCreate} edit={ProductEdit} icon={LocalBar} options={{ label: t('Resources.product') }} />,
      // <Resource name='tag' list={TagList} create={TagCreate} edit={TagEdit} icon={LocalOfferIcon} options={{ label: t('Resources.tag') }} />,
      // <Resource name='banner' list={BannerList} create={BannerCreate} edit={BannerEdit} icon={CallToActionIcon} options={{ label: t('Resources.banner') }} />,
      <Resource name="guest" list={CustomerList} icon={ImportContactsIcon} options={{ label: t('Resources.guest') }} />,
      <Resource name="customer" list={ContactList} icon={Contacts} options={{ label: t('Resources.customer') }} />,
      <Resource name="menu" create={MenuCreate} icon={Publish} options={{ label: t('Resources.import') }} />,
      <Resource name="profile" />,
      <Resource name="calendar" />,
      <Resource name="registered-user" />,
      <Resource name="bundle-products" />,
      <Resource name="businesses" />,
      <Resource name="recommended" />,
      <Resource name="section-availability" />,
      <Resource name="category-availability" />,
      <Resource name="product-availability" />
    ]
  }
  return rights
}

const premiumResources = (permissions) => {
  let rights = []
  if (permissions.bartender) {
    rights = [
      ...rights,
      <Resource name="order" list={RestaurantOrderList} edit={RestaurantOrderEdit} icon={Payment} options={{ label: t('Resources.order') }} />,
      <Resource name="delivery" list={RestaurantDeliveriesList} edit={RestaurantDeliveriesEdit} icon={HourglassEmpty} options={{ label: t('Resources.delivery') }} />,
      <Resource name="history" list={HistoryList} icon={History} options={{ label: t('Resources.history') }} />
    ]
  }
  if (permissions.manager) {
    rights = [
      ...rights,
      <Resource name="section" list={SectionList} create={SectionCreate} edit={SectionEdit} icon={LabelOutline} options={{ label: t('Resources.menu') }} />,
      <Resource name="category" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} icon={Label} options={{ label: t('Resources.category') }} />,
      <Resource name="product" list={ProductList} create={ProductCreate} edit={ProductEdit} icon={LocalBar} options={{ label: t('Resources.product') }} />,
      // <Resource name='tag' list={TagList} create={TagCreate} edit={TagEdit} icon={LocalOfferIcon} options={{ label: t('Resources.tag') }} />,
      // <Resource name='banner' list={BannerList} create={BannerCreate} edit={BannerEdit} icon={CallToActionIcon} options={{ label: t('Resources.banner') }} />,
      <Resource name="instruction" list={InstructionList} create={InstructionCreate} edit={InstructionEdit} icon={Info} options={{ label: t('Resources.instruction') }} />,
      <Resource name="cost" list={CostList} create={CostCreate} edit={CostEdit} icon={EuroSymbol} options={{ label: t('Resources.cost') }} />,
      <Resource name="table" list={TableList} create={TableCreate} edit={TableEdit} icon={RadioButtonUnchecked} options={{ label: t('Resources.tables') }} />,
      <Resource name="guest" list={CustomerList} icon={ImportContactsIcon} options={{ label: t('Resources.guest') }} />,
      <Resource name="customer" list={ContactList} icon={Contacts} options={{ label: t('Resources.customer') }} />,
      <Resource name="menu" create={MenuCreate} icon={Publish} options={{ label: t('Resources.import') }} />,
      <Resource name="profile" />,
      <Resource name="calendar" />,
      <Resource name="registered-user" />,
      <Resource name="bundle-products" />,
      <Resource name="businesses" />,
      <Resource name="recommended" />,
      <Resource name="section-availability" />,
      <Resource name="category-availability" />,
      <Resource name="product-availability" />
    ]
  }
  return rights
}

const readonlyResources = (permissions) => [
  <Resource name="section" list={SectionList} create={SectionCreate} show={SectionShow} icon={LabelOutline} options={{ label: t('Resources.menu') }} />,
  <Resource name="category" list={CategoryList} show={CategoryShow} icon={Label} options={{ label: t('Resources.category') }} />,
  <Resource name="product" list={ProductList} show={ProductShow} icon={LocalBar} options={{ label: t('Resources.product') }} />,
  // <Resource name='tag' list={TagList} show={TagEdit} icon={LocalOfferIcon} options={{ label: t('Resources.tag') }} />,
  // <Resource name='banner' list={BannerList} show={BannerEdit} icon={CallToActionIcon} options={{ label: t('Resources.banner') }} />,
  <Resource name="guest" list={CustomerList} icon={ImportContactsIcon} options={{ label: t('Resources.guest') }} />,
  <Resource name="customer" list={ContactList} icon={Contacts} options={{ label: t('Resources.customer') }} />,
  <Resource name="menu" create={MenuCreate} icon={Publish} options={{ label: t('Resources.import') }} />,
  <Resource name="profile" />,
  <Resource name="calendar" />,
  <Resource name="registered-user" />,
  <Resource name="bundle-products" />,
  <Resource name="businesses" />,
  <Resource name="recommended" />,
  <Resource name="section-availability" />,
  <Resource name="category-availability" />,
  <Resource name="product-availability" />
]

const messages = {
  fr: frenchMessages,
  en: englishMessages,
  es: spanishMessages,
  pt: portugueseMessages,
  de: germanMessages,
  it: italianMessages,
}

const i18nProvider = locale => {
  return locale === 'fr'
    || locale === 'en'
    || locale === 'es'
    || locale === 'pt'
    || locale === 'de'
    || locale === 'it' ? messages[locale] : messages['fr']
}

export default () => {
  return (
    <div className="App">
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        customSagas={[rtProvider, venueSaga, stylesSaga]}
        customReducers={{ venue: venueReducer, styles: stylesReducer }}
        customRoutes={[<Route key="profile" path={"/venue/" + localStorage.getItem('venue')} component={ProfileEdit} />,
        <Route key="console" path="/console" component={Console} />, <Route key="customization" path={"/customization/" + localStorage.getItem('venue')} component={MenuCustomization} />]}
        loginPage={SimpleLogin}
        appLayout={ApperoLayout}
        locale={localStorage.getItem('locale') || resolveBrowserLocale()}
        i18nProvider={i18nProvider}>
        {permissions => {
          if (permissions.admin) {
            return adminResources(permissions)
          } else if (permissions.smart) {
            return permissions.reader ? readonlyResources(permissions) : smartResources(permissions)
          } else if (permissions.premium) {
            return permissions.reader ? readonlyResources(permissions) : premiumResources(permissions)
          } else {
            return basicResources(permissions)
          }
        }
        }
      </Admin>
    </div>
  )
}