import React, { useState } from "react";
import { CREATE, UPDATE, DELETE } from "react-admin";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ColorPicker from "material-ui-color-picker";
import { t } from "../../i18n";
import { TextField as TextInputField } from "muiCustom";
import "./VenueCalendar.css";
import Timetable from "./Timetable";
import { dataProvider } from "../../clients/providers";
// import TextInputField from "../textInputField/TextInputField";

const VenueCalendar = ({ close, initialValue = {}, type }) => {
  const [name, setName] = useState(initialValue.name || "");
  const [timetable, setTimetable] = useState(initialValue.timetable || {});
  const [highlight, setHighlight] = useState(initialValue.highlight || 0);
  const [highlight_text, setHighlight_text] = useState(initialValue.highlight_text || "");
  const [highlight_color, setHighlight_color] = useState(initialValue.highlight_color || "");

  const [formAction, setFormAction] = useState();

  const handleHighlighSwitch = () => {
    setHighlight(!highlight);
  };

  const submit = (e) => {
    e.preventDefault();

    switch (formAction) {
      case "create":
        handleCreate();
        break;
      case "delete":
        handleDelete();
        break;
      case "modify":
        handleModify();
        break;
      default:
        break;
    }
  };
  const handleCreate = () => {
    dataProvider(CREATE, "calendar", {
      data: {
        name,
        highlight,
        highlight_text,
        highlight_color,
        timetable,
      },
    });
    close();
    //Reload() since this version of React Admin does not provide the useRefresh hook
    document.location.reload();
  };

  const handleModify = () => {
    dataProvider(UPDATE, "calendar", {
      data: {
        name,
        highlight,
        highlight_text,
        highlight_color,
        timetable,
      },
      id: initialValue.id || "",
    });
    close();
    document.location.reload();
  };

  const handleDelete = () => {
    dataProvider(DELETE, "calendar", {
      data: {
        name,
        highlight,
        highlight_text,
        highlight_color,
        timetable,
      },
      id: initialValue.id || "",
    });
    close();
    document.location.reload();
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={styles.formContainer}>
        <Typography variant='headline'>{t("Calendar.new_calendar")}</Typography>
        <form onSubmit={submit} autoComplete='off'>
          <TextField
            label={t("Calendar.calendar_name")}
            style={{ width: "50%", marginTop: 30 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            helperText={name === "" ? t("Calendar.name_validation") : ""}
            error={name === ""}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 25,
              height: "15%",
              justifyContent: "center",
            }}
          >
            <div style={styles.input}>
              <FormControlLabel
                control={<Switch checked={highlight} onChange={handleHighlighSwitch} name='highlight' color='primary' />}
                label={t("Calendar.activate_highlight")}
                style={{ width: "25%", height: "100px", alignSelf: "center" }}
              />
              {highlight ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <TextInputField
                    label={t("Calendar.highlight_text")}
                    variant='outlined'
                    style={{ width: "50%", color: highlight_color || "" }}
                    InputLabelProps={{
                      style: { color: highlight_color || "" },
                    }}
                    InputProps={{
                      style: { color: highlight_color || "" },
                    }}
                    value={highlight_text}
                    onChange={(e) => setHighlight_text(e.target.value)}
                  />
                  <ColorPicker
                    label={`${t("Calendar.color")} ${highlight_color}`}
                    InputLabelProps={{
                      style: { color: highlight_color || "" },
                    }}
                    InputProps={{
                      style: { color: highlight_color || "" },
                    }}
                    style={{ width: "50%", color: highlight_color || "" }}
                    value={highlight_color}
                    onChange={(color) => setHighlight_color(color)}
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div style={styles.input} />
          </div>
          <div style={{ marginTop: "5vh" }}>
            <Timetable timetable={timetable} onChange={setTimetable} color={highlight_color || "#3F51B5"} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
            <Button color='primary' onClick={close} style={{ marginRight: 20 }}>
              {t("Calendar.cancel")}
            </Button>
            {type === "Create" ? (
              <Button variant='contained' color='primary' type='submit' onClick={() => setFormAction("create")}>
                {t("Calendar.create")}
              </Button>
            ) : (
              <>
                <Button type='submit' onClick={() => setFormAction("delete")} style={{ marginRight: 30, color: "red" }}>
                  {t("Calendar.delete")}
                </Button>
                <Button variant='contained' color='primary' type='submit' onClick={() => setFormAction("modify")}>
                  {t("Calendar.modify")}
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const styles = {
  formContainer: {
    position: "relative",
    width: "90%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  closeBtn: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  input: {
    width: "100%",
    display: "flex",
  },
  table: {
    fontFamily: "Roboto, sans-serif",
  },
  tableHeadRow: {
    fontSize: 12,
    color: "#818181",
  },
  tableHeadCol: {
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-start",
    color: "#818181",
    position: "relative",
  },
};

export default VenueCalendar;
