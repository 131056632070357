import React from 'react'
import { List, Datagrid, TextField, NumberField, BooleanField } from 'react-admin'
import { t } from '../../i18n'

export default (props) =>
  <List {...props} bulkActionButtons={false} pagination={null} title={t('Discount.title')}>
    <Datagrid rowClick="edit">
      <TextField source="venue" label={t('Discount.venue')} sortable={false}/>
      <NumberField source="price" label={t('Discount.price')} options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
      <TextField source="offer" label={t('Discount.offer')} sortable={false}/>
      <TextField source="duration" label={t('Discount.duration')} sortable={false}/>
      <BooleanField source="used" label={t('Discount.used')} sortable={false}/>
    </Datagrid>
  </List>