import React, { useState } from 'react'
import { connect } from 'react-redux'
import { changeLocale as changeLocaleAction } from 'react-admin'
import ReactFlagsSelect from 'react-flags-select'
import './LocaleSwitcher.css'

const LocaleSwitcher = props => {
	const { changeLocale } = props
	let locale = localStorage.getItem('locale') || 'fr'
	if (locale === 'en') locale = 'gb'

	const [selected, setSelected] = useState(locale.toUpperCase())

	const onSelect = code => {
		setSelected(code)
		if (code === 'GB') {
			changeLocale('en')
			localStorage.setItem('locale', 'en')
		} else {
			changeLocale(code.toLowerCase())
			localStorage.setItem('locale', code.toLowerCase())
		}
		window.location.reload()
	}

	return (
		<div>
			<ReactFlagsSelect
				className="locale-select"
				selectButtonClassName="locale-select-button"
				countries={["GB", "FR", "ES", "PT", "IT", "DE"]}
				showSelectedLabel={false}
				showSecondarySelectedLabel={false}
				showOptionLabel={false}
				fullWidth={false}
				selected={selected}
				onSelect={onSelect} />
		</div>
	)
}

export default connect(undefined, { changeLocale: changeLocaleAction })(LocaleSwitcher)