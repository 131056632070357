import React, { useState } from 'react'
import { List, Datagrid, TextField, DateField, DELETE, Pagination, Filter, TextInput } from 'react-admin'
import { t } from '../../i18n'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { dataProvider } from "../../clients/providers"
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  bin: {
    float: "right"
  }
})

const DeleteButton = withStyles(styles)(props => {
  const { record, classes } = props
  const [showDialog, setShowDialog] = useState(false)
  const onRemove = id => {
    setShowDialog(false)
    dataProvider(DELETE, "customer", { id: id }).then(() => window.location.reload())
  }

  return (
    <>
      <Dialog fullWidth open={showDialog} onClose={() => setShowDialog(false)} aria-label="Are you sure?">
        <DialogTitle>{t('Contact.delete_ask')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('Contact.will_delete')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary" variant="contained">{t('Contact.cancel')}</Button>
          <Button className={classnames('ra-delete-button', classes.deleteButton)} style={{ marginRight: '1em' }} onClick={() => onRemove(record.id)}>
            {t('Contact.delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton className={classes.bin} size="small" onClick={() => setShowDialog(true)}>
        <DeleteIcon color="error" />
      </IconButton>
    </>
  )
})

const ContactPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const ContactFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t('Contact.search')} source="name" alwaysOn />
  </Filter>
)

export default props =>
  <List {...props} bulkActionButtons={false} perPage={25} pagination={<ContactPagination />} title={t('Product.title')} filters={<ContactFilter />}>
    <Datagrid>
      <TextField source="name" label={t('Customer.name')} sortable={false} />
      <DateField source="creation_date" showTime={true} label={t('Customer.creation_date')} sortable={false} />
      <TextField source="email" label={t('Customer.email')} sortable={false} />
      <TextField source="phone" label={t('Customer.phone')} sortable={false} />
      <DeleteButton />
    </Datagrid>
  </List>
