import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import { connect } from "react-redux";
import { changeStylePerimeter, restoreStyles } from "../../actions/styles";
import {
  ALL_ELEMENTS,
  BACKGROUND_COLOR,
  CATEGORY,
  CONTAIN,
  COVER,
  DISPLAY,
  FLEX,
  FONT_COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  MARGIN,
  MENU,
  OBJECT_FIT,
  RESTORE_STYLES,
  SINGLE_ELEMENT,
  VISIBILITY,
} from "./constants";
import FontFamilyInput from "./styleInputs/FontFamilyInput";
import FontSizeInput from "./styleInputs/FontSizeInput";
import FontColorInput from "./styleInputs/FontColorInput";
import BackgroundColorInput from "./styleInputs/BackgroundColorInput";
import VisibilityInput from "./styleInputs/VisibilityInput";
import JustifyInput from "./styleInputs/JustifyInput";

import { t } from "../../i18n";
import ImageFormatInput from "./styleInputs/ImageFormatInput";

const styles = () => ({
  root: {
    width: "90%",
  },
  heading: {
    width: "90%",
  },
  properties: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    padding: 5,
    border: "1px solid gray",
    borderRadius: 5,
  },
  content: {
    padding: "5px 15px",
  },
});

const Selectors = ({ selector, handleChangeStyle, value, setProperty, ...props }) => {
  const { classes, changeStylePerimeter, restoreStyles, currentStyles, savedFonts } = props;

  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  };
  // Load custom fonts
  useEffect(() => {
    const fontFaceImports = savedFonts.map(
      (font) => `
@font-face {
font-family: "${font.name.split(".")[0]}";
src: url("${config.REACT_APP_STYLE_URL}/fonts/${font.id}/binary") format("woff");
}
`
    );

    const fontFaceImportsText = fontFaceImports.join(" ");
    const styleSheet = document.createElement("style");
    styleSheet.innerText = fontFaceImportsText;
    styleSheet.id = "FONT_FACE_IMPORTS";

    document.head.appendChild(styleSheet);
  }, []);

  // Panels
  const [expanded, setExpanded] = useState("singleElementPanel");
  const handlePanel = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : null);
    switch (panel) {
      case SINGLE_ELEMENT:
        setSelectedStylePerimeter(stylePerimeter.singleElement);
        changeStylePerimeter(stylePerimeter.singleElement);
        break;
      case CATEGORY:
        setSelectedStylePerimeter(stylePerimeter.category);
        changeStylePerimeter(stylePerimeter.category);
        break;
      case MENU:
        setSelectedStylePerimeter(stylePerimeter.menu);
        changeStylePerimeter(stylePerimeter.menu);
        break;
      case ALL_ELEMENTS:
        setSelectedStylePerimeter(stylePerimeter.allElements);
        changeStylePerimeter(stylePerimeter.allElements);
        break;

      default:
        break;
    }
  };

  //Buttons
  const [propertySelected, setPropertySelected] = useState(null);

  const HandleReset = (property, selector) => {
    setPropertySelected(property);
    setProperty(property);
    setSelectedStylePerimeter(selector);
    changeStylePerimeter(selector);
  };

  const [stylePerimeter, setStylePerimeter] = useState({
    singleElement: "",
    category: "",
    menu: "",
    allElements: "",
  });
  const [selectedStylePerimeter, setSelectedStylePerimeter] = useState(null);

  const selectors = selector && selector.split(".").filter((selector) => selector);

  const selectorDescription = () => {
    const regexAllElements = `${selectors[0]}`;
    const regexMenu = /cst-menu/;
    const regexCategory = /cst-category/;
    const regexSingleElement = /cst-item/;

    const selectorSingleElement = selectors.filter((selector) => selector.match(regexSingleElement))[0]
      ? `.${selectors.filter((selector) => selector.match(regexSingleElement))[0]}`
      : "";
    const selectorCategory = selectors.filter((selector) => selector.match(regexCategory))[0]
      ? `.${selectors.filter((selector) => selector.match(regexCategory))[0]}`
      : "";
    const selectorMenu = selectors.filter((selector) => selector.match(regexMenu))[0]
      ? `.${selectors.filter((selector) => selector.match(regexMenu))[0]}`
      : "";
    const selectorAllElements = selectors.filter((selector) => selector.match(regexAllElements))[0]
      ? `.${selectors.filter((selector) => selector.match(regexAllElements))[0]}`
      : "";

    setStylePerimeter({
      singleElement: selectorSingleElement && `${selectorSingleElement}${selectorCategory}${selectorMenu}${selectorAllElements}`,
      category: selectorCategory && `${selectorCategory}${selectorMenu}${selectorAllElements}`,
      menu: selectorMenu && `${selectorMenu}${selectorAllElements}`,
      allElements: selectorAllElements && `${selectorAllElements}`,
    });
  };
  useEffect(
    () => {
      selectorDescription();
    },
    [selector]
  );

  return (
    <div className={classNames(classes.root)}>
      {stylePerimeter && stylePerimeter.singleElement && (
        <ExpansionPanel defaultExpanded expanded={expanded === SINGLE_ELEMENT} onChange={handlePanel(SINGLE_ELEMENT)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {currentStyles[stylePerimeter.singleElement] && Object.keys(currentStyles[stylePerimeter.singleElement]).length ? (
              <Typography className={classes.heading}>
                {t("Customization.thisElement")}{" "}
                <span style={{ fontWeight: "bold", color: "orange" }}>({Object.keys(currentStyles[stylePerimeter.singleElement]).length}) </span>
              </Typography>
            ) : (
              <Typography className={classes.heading}>{t("Customization.thisElement")}</Typography>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <div style={{ position: "relative" }}>
                {!stylePerimeter.allElements.includes(".cst-image-product-list") &&
                !stylePerimeter.allElements.includes(".cst-product-image-fp") &&
                !stylePerimeter.allElements.includes(".cst-product") ? (
                  <>
                    <FontFamilyInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.singleElement] || ""}
                      property={FONT_FAMILY}
                      perimeter={stylePerimeter.singleElement}
                    />
                    <FontSizeInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.singleElement] || ""}
                      property={FONT_SIZE}
                      perimeter={stylePerimeter.singleElement}
                    />
                    <FontColorInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.singleElement] || ""}
                      property={FONT_COLOR}
                      perimeter={stylePerimeter.singleElement}
                    />
                    {stylePerimeter.allElements.includes(".cst-banner") || stylePerimeter.allElements.includes(".cst-tag") ? (
                      <BackgroundColorInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.singleElement] || ""}
                        property={BACKGROUND_COLOR}
                        perimeter={stylePerimeter.singleElement}
                      />
                    ) : null}
                    {stylePerimeter.allElements.includes(".cst-_category") ? (
                      <VisibilityInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.singleElement] || ""}
                        property={DISPLAY}
                        perimeter={stylePerimeter.singleElement}
                        defaultValue={FLEX}
                      />
                    ) : null}
                  </>
                ) : stylePerimeter.allElements.includes(".cst-product") ? (
                  <BackgroundColorInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.singleElement] || ""}
                    property={BACKGROUND_COLOR}
                    perimeter={stylePerimeter.singleElement}
                  />
                ) : (
                  <ImageFormatInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.singleElement] || ""}
                    property={OBJECT_FIT}
                    perimeter={stylePerimeter.singleElement}
                    defaultValue={CONTAIN}
                  />
                )}

                <Button
                  size='small'
                  style={{
                    color:
                      !currentStyles[stylePerimeter.singleElement] || !Object.keys(currentStyles[stylePerimeter.singleElement]).length
                        ? "rgba(0, 0, 0, 0.54)"
                        : "red",
                    marginLeft: 20,
                  }}
                  onClick={() => {
                    HandleReset(RESTORE_STYLES, stylePerimeter.singleElement);
                  }}
                  disabled={!currentStyles[stylePerimeter.singleElement] || !Object.keys(currentStyles[stylePerimeter.singleElement]).length}
                >
                  <RotateLeftIcon color='inherit' />
                </Button>
              </div>

              {propertySelected === RESTORE_STYLES && selectedStylePerimeter === stylePerimeter.singleElement && (
                <div>
                  <Button
                    size='small'
                    style={{ color: "red", border: "1px solid red", marginTop: 20, marginRight: 10 }}
                    onClick={() => {
                      restoreStyles(stylePerimeter.singleElement);
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.reset")}
                  </Button>
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.cancel")}
                  </Button>
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {stylePerimeter && stylePerimeter.category && (
        <ExpansionPanel expanded={expanded === CATEGORY} onChange={handlePanel(CATEGORY)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {currentStyles[stylePerimeter.category] && Object.keys(currentStyles[stylePerimeter.category]).length ? (
              <Typography className={classes.heading}>
                {t("Customization.elementsInThisCategory")}{" "}
                <span style={{ fontWeight: "bold", color: "orange" }}>({Object.keys(currentStyles[stylePerimeter.category]).length}) </span>
              </Typography>
            ) : (
              <Typography className={classes.heading}>{t("Customization.elementsInThisCategory")}</Typography>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <div style={{ position: "relative" }}>
                {!stylePerimeter.allElements.includes(".cst-image-product-list") &&
                !stylePerimeter.allElements.includes(".cst-product-image-fp") &&
                !stylePerimeter.allElements.includes(".cst-product") ? (
                  <>
                    <FontFamilyInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.category] || ""}
                      property={FONT_FAMILY}
                      perimeter={stylePerimeter.category}
                    />
                    <FontSizeInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.category] || ""}
                      property={FONT_SIZE}
                      perimeter={stylePerimeter.category}
                    />
                    <FontColorInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.category] || ""}
                      property={FONT_COLOR}
                      perimeter={stylePerimeter.category}
                    />
                    {stylePerimeter.allElements.includes(".cst-banner") || stylePerimeter.allElements.includes(".cst-tag") ? (
                      <BackgroundColorInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.category] || ""}
                        property={BACKGROUND_COLOR}
                        perimeter={stylePerimeter.category}
                      />
                    ) : null}
                    {stylePerimeter.allElements.includes(".cst-_category") ? (
                      <VisibilityInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.category] || ""}
                        property={DISPLAY}
                        perimeter={stylePerimeter.category}
                        defaultValue={FLEX}
                      />
                    ) : null}
                  </>
                ) : stylePerimeter.allElements.includes(".cst-product") ? (
                  <BackgroundColorInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.category] || ""}
                    property={BACKGROUND_COLOR}
                    perimeter={stylePerimeter.category}
                  />
                ) : (
                  <ImageFormatInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.category] || ""}
                    property={OBJECT_FIT}
                    perimeter={stylePerimeter.category}
                    defaultValue={CONTAIN}
                  />
                )}

                <Button
                  size='small'
                  style={{
                    color:
                      !currentStyles[stylePerimeter.category] || !Object.keys(currentStyles[stylePerimeter.category]).length
                        ? "rgba(0, 0, 0, 0.54)"
                        : "red",
                    marginLeft: 20,
                  }}
                  onClick={() => {
                    HandleReset(RESTORE_STYLES, stylePerimeter.category);
                  }}
                  disabled={!currentStyles[stylePerimeter.category] || !Object.keys(currentStyles[stylePerimeter.category]).length}
                >
                  <RotateLeftIcon color='inherit' />
                </Button>
              </div>

              {propertySelected === RESTORE_STYLES && selectedStylePerimeter === stylePerimeter.category && (
                <div>
                  <Button
                    size='small'
                    style={{ color: "red", border: "1px solid red", marginTop: 20, marginRight: 10 }}
                    onClick={() => {
                      restoreStyles(stylePerimeter.category);
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.reset")}
                  </Button>
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.cancel")}
                  </Button>
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {stylePerimeter && stylePerimeter.menu && (
        <ExpansionPanel expanded={expanded === MENU} onChange={handlePanel(MENU)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {currentStyles[stylePerimeter.menu] && Object.keys(currentStyles[stylePerimeter.menu]).length ? (
              <Typography className={classes.heading}>
                {t("Customization.elementsInThisMenu")}{" "}
                <span style={{ fontWeight: "bold", color: "orange" }}>({Object.keys(currentStyles[stylePerimeter.menu]).length}) </span>
              </Typography>
            ) : (
              <Typography className={classes.heading}>{t("Customization.elementsInThisMenu")}</Typography>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <div style={{ position: "relative" }}>
                {!stylePerimeter.allElements.includes(".cst-image-product-list") &&
                !stylePerimeter.allElements.includes(".cst-product-image-fp") &&
                !stylePerimeter.allElements.includes(".cst-product") ? (
                  <>
                    <FontFamilyInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.menu] || ""}
                      property={FONT_FAMILY}
                      perimeter={stylePerimeter.menu}
                    />
                    <FontSizeInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.menu] || ""}
                      property={FONT_SIZE}
                      perimeter={stylePerimeter.menu}
                    />
                    <FontColorInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.menu] || ""}
                      property={FONT_COLOR}
                      perimeter={stylePerimeter.menu}
                    />
                    {stylePerimeter.allElements.includes(".cst-banner") || stylePerimeter.allElements.includes(".cst-tag") ? (
                      <BackgroundColorInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.menu] || ""}
                        property={BACKGROUND_COLOR}
                        perimeter={stylePerimeter.menu}
                      />
                    ) : null}
                    {stylePerimeter.allElements.includes(".cst-_category") ? (
                      <VisibilityInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.menu] || ""}
                        property={DISPLAY}
                        perimeter={stylePerimeter.menu}
                        defaultValue={FLEX}
                      />
                    ) : null}
                  </>
                ) : stylePerimeter.allElements.includes(".cst-product") ? (
                  <BackgroundColorInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.menu] || ""}
                    property={BACKGROUND_COLOR}
                    perimeter={stylePerimeter.menu}
                  />
                ) : (
                  <ImageFormatInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.menu] || ""}
                    property={OBJECT_FIT}
                    perimeter={stylePerimeter.menu}
                    defaultValue={CONTAIN}
                  />
                )}

                <Button
                  size='small'
                  style={{
                    color:
                      !currentStyles[stylePerimeter.menu] || !Object.keys(currentStyles[stylePerimeter.menu]).length ? "rgba(0, 0, 0, 0.54)" : "red",
                    marginLeft: 20,
                  }}
                  onClick={() => {
                    HandleReset(RESTORE_STYLES, stylePerimeter.menu);
                  }}
                  disabled={!currentStyles[stylePerimeter.menu] || !Object.keys(currentStyles[stylePerimeter.menu]).length}
                >
                  <RotateLeftIcon color='inherit' />
                </Button>
              </div>

              {propertySelected === RESTORE_STYLES && selectedStylePerimeter === stylePerimeter.menu && (
                <div>
                  <Button
                    size='small'
                    style={{ color: "red", border: "1px solid red", marginTop: 20, marginRight: 10 }}
                    onClick={() => {
                      restoreStyles(stylePerimeter.menu);
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.reset")}
                  </Button>
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.cancel")}
                  </Button>
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {stylePerimeter && stylePerimeter.allElements && (
        <ExpansionPanel expanded={expanded === ALL_ELEMENTS} onChange={handlePanel(ALL_ELEMENTS)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {currentStyles[stylePerimeter.allElements] && Object.keys(currentStyles[stylePerimeter.allElements]).length ? (
              <Typography className={classes.heading}>
                {t("Customization.allElements")}{" "}
                <span style={{ fontWeight: "bold", color: "orange" }}>({Object.keys(currentStyles[stylePerimeter.allElements]).length}) </span>
              </Typography>
            ) : (
              <Typography className={classes.heading}>{t("Customization.allElements")}</Typography>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <div style={{ position: "relative" }}>
                {!stylePerimeter.allElements.includes(".cst-image-product-list") &&
                !stylePerimeter.allElements.includes(".cst-product-image-fp") &&
                !stylePerimeter.allElements.includes(".cst-product") ? (
                  <>
                    <FontFamilyInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.allElements] || ""}
                      property={FONT_FAMILY}
                      perimeter={stylePerimeter.allElements}
                    />
                    <FontSizeInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.allElements] || ""}
                      property={FONT_SIZE}
                      perimeter={stylePerimeter.allElements}
                    />
                    <FontColorInput
                      handleChangeStyle={handleChangeStyle}
                      value={currentStyles[stylePerimeter.allElements] || ""}
                      property={FONT_COLOR}
                      perimeter={stylePerimeter.allElements}
                    />
                    {stylePerimeter.allElements.includes(".cst-banner") || stylePerimeter.allElements.includes(".cst-tag") ? (
                      <BackgroundColorInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.allElements] || ""}
                        property={BACKGROUND_COLOR}
                        perimeter={stylePerimeter.allElements}
                      />
                    ) : null}
                    {stylePerimeter.allElements.includes(".cst-_category") ? (
                      <VisibilityInput
                        handleChangeStyle={handleChangeStyle}
                        value={currentStyles[stylePerimeter.allElements] || ""}
                        property={DISPLAY}
                        perimeter={stylePerimeter.allElements}
                        defaultValue={FLEX}
                      />
                    ) : null}
                  </>
                ) : stylePerimeter.allElements.includes(".cst-product") ? (
                  <BackgroundColorInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.allElements] || ""}
                    property={BACKGROUND_COLOR}
                    perimeter={stylePerimeter.allElements}
                  />
                ) : (
                  <ImageFormatInput
                    handleChangeStyle={handleChangeStyle}
                    value={currentStyles[stylePerimeter.allElements] || ""}
                    property={OBJECT_FIT}
                    perimeter={stylePerimeter.allElements}
                    defaultValue={CONTAIN}
                  />
                )}

                <Button
                  size='small'
                  style={{
                    color:
                      !currentStyles[stylePerimeter.allElements] || !Object.keys(currentStyles[stylePerimeter.allElements]).length
                        ? "rgba(0, 0, 0, 0.54)"
                        : "red",
                    marginLeft: 20,
                  }}
                  onClick={() => {
                    HandleReset(RESTORE_STYLES, stylePerimeter.allElements);
                  }}
                  disabled={!currentStyles[stylePerimeter.allElements] || !Object.keys(currentStyles[stylePerimeter.allElements]).length}
                >
                  <RotateLeftIcon color='inherit' />
                </Button>
              </div>

              {propertySelected === RESTORE_STYLES && selectedStylePerimeter === stylePerimeter.allElements && (
                <div>
                  <Button
                    size='small'
                    style={{ color: "red", border: "1px solid red", marginTop: 20, marginRight: 10 }}
                    onClick={() => {
                      restoreStyles(stylePerimeter.allElements);
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.reset")}
                  </Button>
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setSelectedStylePerimeter(null);
                      setPropertySelected(null);
                    }}
                  >
                    {t("Customization.cancel")}
                  </Button>
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentStyles: state.styles.currentStyles,
    savedFonts: state.styles.savedFonts,
  };
}

export default connect(
  mapStateToProps,
  { changeStylePerimeter, restoreStyles }
)(withStyles(styles)(Selectors));
