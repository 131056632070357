import React, { useState } from 'react'
import { List, Datagrid, TextField, ChipField, SelectField, NumberField, Mutation, Pagination, Filter, TextInput, ReferenceInput, SelectInput, ReferenceArrayField, SingleFieldList, BooleanField } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import { t } from '../../i18n'

const UnavailableSwitch = ({ source, record, ...props }) => {

  const [checked, setChecked] = useState(!!record[source])

  return (
    <Mutation
      type="UPDATE"
      resource="product-availability"
      payload={{ id: record.id, data: { ...record, [source]: !checked } }}
      options={{ undoable: false }}>
      {
        (toggleUnavailable) => (
          <Switch
            onClick={event => {
              event.stopPropagation()
              setChecked(!checked)
              record[source] = !record[source]
              toggleUnavailable()
            }}
            color="primary"
            checked={checked} />
        )
      }
    </Mutation>
  )
}

const ProductPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t('Product.search')} source="name" alwaysOn style={{ width: '300px' }} />
    <ReferenceInput source="category_id" label={t('Category.title')} reference="category" alwaysOn>
      <SelectInput source="id" optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="sections" label={t('Product.menus')} reference="section" alwaysOn>
      <SelectInput source="id" optionText="name" />
    </ReferenceInput>
    <SelectInput source="type_id" choices={[
      { id: 1, name: t('Product.type_single') },
      { id: 2, name: t('Product.type_bundle') },
      { id: 3, name: t('Product.type_set') },
    ]} alwaysOn />
  </Filter>
)

const ProductList = ({ permissions, ...props }) =>
  permissions && permissions.reader ?
    <List {...props} bulkActionButtons={false} perPage={25} title={t('Product.title')} filters={<ProductFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" label={t('Product.name')} sortable={false} />
        <SelectField source="type_id" label={t('Product.type')} choices={[
          { id: 1, name: t('Product.type_single') },
          { id: 2, name: t('Product.type_bundle') },
          { id: 3, name: t('Product.type_set') },
        ]} sortable={false} />
        <TextField source="recipe" label={t('Product.recipe')} sortable={false} />
        <ReferenceArrayField label={t('Product.categories')} reference="category" source="categories" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="net_price" label={t('Product.incl_tax_price')} options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
        <BooleanField source="unavailable" label={t('Product.unavailable')} sortable={false} />
      </Datagrid>
    </List>
    :
    <List {...props} bulkActionButtons={false} perPage={25} title={t('Product.title')} pagination={<ProductPagination />} filters={<ProductFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={t('Product.name')} sortable={false} />
        <SelectField source="type_id" label={t('Product.type')} choices={[
          { id: 1, name: t('Product.type_single') },
          { id: 2, name: t('Product.type_bundle') },
          { id: 3, name: t('Product.type_set') },
        ]} sortable={false} />
        <TextField source="recipe" label={t('Product.recipe')} sortable={false} />
        <ReferenceArrayField label={t('Product.categories')} reference="category" source="categories" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="net_price" label={t('Product.incl_tax_price')} options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
        <UnavailableSwitch source="unavailable" label={t('Product.unavailable')} sortable={false} />
      </Datagrid>
    </List>


export default ProductList
