import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin'

import decodeJwt from 'jwt-decode'

const onTokenLoaded = (token) => {
  const decoded = decodeJwt(token)
  const {mgr = [], bar = [], reader = []} = decoded
  const venues = [...mgr, ...bar, ...reader]
  const venue  = venues[0] || ''

  localStorage.setItem('venue', venue)

  if (!decoded.smrt.includes(venue) && !decoded.prmm.includes(venue)) {
    setTimeout(() => window.location.href = `/#/venue/${venue}`, 200)
  }
}

const onTokenRemoved = () => {
  localStorage.removeItem('venue');
}


const createAuthProvider = (apiURL) => (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${apiURL}/auth/token`, {
      method : 'GET',
      headers : new Headers({
         'Content-Type' : 'application/json',
         'Authorization' : 'Basic ' + Buffer(username + ':' + password).toString('base64')
       })
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ jwt }) => {
        localStorage.setItem('token', jwt)
        localStorage.setItem('email', username)
        onTokenLoaded(jwt)
      })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.clear()
    return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
    const status  = params.status;

    if (status === 401 || status === 403) {
      localStorage.clear()
      return Promise.reject()
    }
    return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    const requiresAuth = localStorage.getItem('requiresAuth')
    const token        = localStorage.getItem('token')

    return !requiresAuth || token ? Promise.resolve() : Promise.reject()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const venue   = localStorage.getItem('venue')
    const token   = localStorage.getItem('token')
    const decoded = decodeJwt(token)

    if (!token || !decoded || !decoded.prmm || !decoded.smrt) {
      return Promise.reject()
    }

    const manager   = (decoded.mgr || []).includes(venue)
    const bartender = (decoded.bar || []).includes(venue)
    const smart     = (decoded.smrt || []).includes(venue)
    const premium   = (decoded.prmm || []).includes(venue)
    const reader    = (decoded.reader || []).includes(venue)
    const { admin } = decoded

    return Promise.resolve({admin, manager, bartender, smart, premium, reader})
  }

  return Promise.resolve()
};


export default createAuthProvider
