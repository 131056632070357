import React from 'react'
import { Datagrid, List, TextField, NumberField, FunctionField, CardActions } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { t } from '../../i18n'

const styles = {
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-begin'
  }
}

const productsCount = ({ items }) =>
  (items || []).reduce((a, n) => a + n.qty, 0)

const totalPrice = ({ items }) =>
  (items || []).reduce((a, n) => a + n.net_price, 0)

const TotalPriceField = ({ record, source }) => {
  const price = totalPrice(record)
  const newRecord = { ...record, [source]: price }
  return <NumberField source={source} options={{ style: 'currency', currency: 'EUR' }} record={newRecord} />
}

const ItemsField = withStyles(styles)(({ record, source, classes }) => {
  const items = record[source]

  return (
    <div>
      {
        items.map(item =>
          <div key={item.name} className={classes.itemContainer}>
            <div>
              <Typography variant="body1">
                {item.qty} x
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                &nbsp;{item.name}
              </Typography>
            </div>
            {
              item.sub_products ?
                <div className={classes.itemContainer}>
                  <div><Typography variant="body1">&nbsp;(</Typography></div>
                  {
                    item.sub_products.map(subitem =>
                      <div key={subitem.name} className={classes.itemContainer}>
                        <div>
                          <Typography variant="body1">
                            {subitem.qty} x
                        </Typography>
                        </div>
                        <div>
                          <Typography variant="body1">
                            &nbsp;{subitem.name}&nbsp;
                          </Typography>
                        </div>
                      </div>
                    )
                  }
                  <div><Typography variant="body1">)</Typography></div>
                </div>
                :
                null
            }
          </div>
        )
      }
    </div>
  )
})

const createRowStyle = (record) => {
  if (record.status === 'IN_PROGRESS') {
    return {
      backgroundColor: '#FFAAAA'
    }
  }
  if (record.status === 'ON_HOLD') {
    return {
      backgroundColor: '#CCCCCC'
    }
  }
}

const Toolbar = (props) => {
  const { ids } = props;

  return (
    <CardActions>
      <Typography variant="headline">
        {t('Order.queue', { queue: ids.length })}
      </Typography>
    </CardActions>
  )
}


const RestaurantOrderDataGrid = ({ ids, data, ...props }) => <Datagrid
  ids={ids.filter(id => {
    const order = data[id] || {};
    return order.status === 'PENDING' || order.status === 'ON_HOLD';
  }).sort((a, b) => new Date(b.creation_date).getTime() - new Date(b.creation_date).getTime())}
  data={data}
  {...props}
/>

const RestaurantOrderList = (props) =>
  <List actions={<Toolbar />} {...props} bulkActionButtons={false} pagination={null} title={t('Order.new_orders')}>
    <RestaurantOrderDataGrid rowClick="edit" rowStyle={createRowStyle}>
      <TextField label={t('Order.client')} source="customer.name" sortable={false} />
      <TextField label={t('Order.table')} source="customer.table" sortable={false} />
      <FunctionField label={t('Order.number_products')} render={productsCount} sortable={false} />
      <ItemsField label={t('Order.products')} source="items" sortable={false} />
      <TotalPriceField label={t('Order.price')} source="total_price" sortable={false} />
      <TextField label='ID' source="ref" sortable={false} />
    </RestaurantOrderDataGrid>
  </List>

export default RestaurantOrderList
