import realtimeSaga from 'ra-realtime';

import AudioNotifications from '../audio/audio-notifications';
import IO from './io/io.js'

const observedResources = ['order', 'delivery'];

const io = new IO(window._env_.REACT_APP_RT_URL, {secure : !!window._env_.REACT_APP_RT_SECURE});

const audioNotifications = new AudioNotifications();

io.on('order:update', (data) => {
  if (data.status === 'PENDING') {
    audioNotifications.update();
  }
});

io.on('order:cancel', () => {
  audioNotifications.cancel();
});


const observeRequest = (dataProvider) => (type, resource, params) => {
  // Filtering so that only posts are updated in real time
  if (!observedResources.includes(resource)) return;

  return {
    subscribe(observer) {
      const unsubscribeUpdate = io.on('order:update', (data) => {
        if (type === 'GET_ONE' && data.id === params.id) {
          observer.next({data});
        }
        if (type === 'GET_LIST') {
          dataProvider(type, resource, params)
            .then(results => observer.next(results))
            .catch(error => observer.error(error));
        }
      });

      const unsubscribeCancel = io.on('order:cancel', (data) => {
        dataProvider(type, resource, params)
          .then(results => observer.next(results))
          .catch(error => observer.error(error));
      });

      var unsubscribed = false;

      const subscription = {
        unsubscribe() {
          if (!unsubscribed) {
            unsubscribed = true;
            unsubscribeUpdate();
            unsubscribeCancel();

            observer.complete();
          }
        }
      };

      return subscription;
    }
  };
};

export default dataProvider => realtimeSaga(observeRequest(dataProvider));
