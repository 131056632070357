import React from 'react'
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  BooleanInput,
  required,
} from 'react-admin'
import currencyToSymbolMap from 'currency-symbol-map/map'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'

const currencies = Object.entries(currencyToSymbolMap).map(c => ({id: c[0], name: `${c[0]} (${c[1]})`}))

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

const countries = [
  { id: 'AU', name: t('Countries.Australia') },
  { id: 'AT', name: t('Countries.Austria') },
  { id: 'BE', name: t('Countries.Belgium') },
  { id: 'BR', name: t('Countries.Brazil') },
  { id: 'BG', name: t('Countries.Bulgaria') },
  { id: 'CA', name: t('Countries.Canada') },
  { id: 'CY', name: t('Countries.Cyprus') },
  { id: 'CZ', name: t('Countries.Czech Republic') },
  { id: 'DK', name: t('Countries.Denmark') },
  { id: 'EE', name: t('Countries.Estonia') },
  { id: 'FI', name: t('Countries.Finland') },
  { id: 'FR', name: t('Countries.France') },
  { id: 'DE', name: t('Countries.Germany') },
  { id: 'GI', name: t('Countries.Gibraltar') },
  { id: 'GR', name: t('Countries.Greece') },
  { id: 'HK', name: t('Countries.Hong Kong') },
  { id: 'IN', name: t('Countries.India') },
  { id: 'IE', name: t('Countries.Ireland') },
  { id: 'IT', name: t('Countries.Italy') },
  { id: 'JP', name: t('Countries.Japan') },
  { id: 'LT', name: t('Countries.Lithuania') },
  { id: 'LU', name: t('Countries.Luxembourg') },
  { id: 'LV', name: t('Countries.Latvia') },
  { id: 'MT', name: t('Countries.Malta') },
  { id: 'MY', name: t('Countries.Malaysia') },
  { id: 'MX', name: t('Countries.Mexico') },
  { id: 'NL', name: t('Countries.Netherlands') },
  { id: 'NZ', name: t('Countries.New Zealand') },
  { id: 'NO', name: t('Countries.Norway') },
  { id: 'PL', name: t('Countries.Poland') },
  { id: 'PT', name: t('Countries.Portugal') },
  { id: 'RO', name: t('Countries.Romania') },
  { id: 'SG', name: t('Countries.Singapore') },
  { id: 'SK', name: t('Countries.Slovakia') },
  { id: 'SI', name: t('Countries.Slovenia') },
  { id: 'ES', name: t('Countries.Spain') },
  { id: 'SE', name: t('Countries.Sweden') },
  { id: 'CH', name: t('Countries.Switzerland') },
  { id: 'GB', name: t('Countries.United Kingdom') },
  { id: 'US', name: t('Countries.United States') }
]

export const VenueCreate = (props) =>
  <Create title={t('Venue.create')} {...props}>
    <TabbedForm redirect="list">
      <FormTab label={t('Venue.form_general')}>
        <ImageInput source="pictures" label={t('Venue.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Venue.error_image')) }} placeholder={<p>{t('Venue.tip_image')}</p>} multiple={false}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInputField source='name' label={t("Venue.name")} validate={required()}  />
        <BooleanInput source="display_name" label={t('Venue.display_name')} />
        <TextInput source="business_name" label={t('Venue.business_name')} validate={required()} style={{ width: '100%' }} />
        <LongTextInput source="address" label={t('Venue.address')} validate={required()} />
        <LongTextInput source="billing_address" label={t('Venue.billing_address')} validate={required()} />
        <TextInputField source="legal" label={t('Venue.legal')} />
        <LongTextInput source="instagram" />
        <LongTextInput source="facebook" />
        <LongTextInput source="tripadvisor" />
        <LongTextInput source="gmaps" label="Google Maps" />
        <LongTextInput source="website" />
        <SelectInput label={t('Venue.country')} defaultValue="FR" source="country" validate={required()} choices={countries} style={{ width: '100%' }} />
        <SelectInput label={t('Venue.currency')} defaultValue="EUR" source="currency" choices={currencies} style={{ width: '100%' }} />
        <SelectInput label={t('Venue.language_source')} defaultValue="fr-FR" source="source_locale" validate={required()} choices={locales} style={{ width: '100%' }} />
        <SelectInput label={t('Venue.language')} defaultValue="fr-FR" source="default_locale" validate={required()} choices={locales} style={{ width: '100%' }} />
        <BooleanInput label={t('Venue.wishlist')} source="wishlist" style={{ width: '100%' }} />
        <BooleanInput label={t('Venue.welcome_box')} source="welcome_box" />
      </FormTab>
      <FormTab label={t('Venue.form_users')}>
        <ReferenceArrayInput label={t('Venue.bartenders')} source="bartenders" reference="registered-user" style={{ width: '100%' }}>
          <AutocompleteArrayInput source="id" optionText="email" style={{ width: '100%' }} />
        </ReferenceArrayInput>
        <ReferenceArrayInput label={t('Venue.managers')} source="managers" reference="registered-user" style={{ width: '100%' }}>
          <AutocompleteArrayInput source="id" optionText="email" style={{ width: '100%' }} />
        </ReferenceArrayInput>
        <TextInput label={t('Venue.billing_email')} source="billing_email" type="email" validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>

export default VenueCreate
