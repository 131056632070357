import React from 'react'
import { Toolbar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import OrderForm from './OrderForm'
import PasswordField from './PasswordField'
import OrderReadyButton  from '../../action-buttons/OrderReadyButton'
import { t } from '../../../i18n'

const InProgressEditToolbar = (props) =>
  <Toolbar {...props} >
    <OrderReadyButton />
  </Toolbar>;

class InProgressOrderForm extends React.Component {
  render() {
    return (
      <OrderForm
        {...this.props}
        subheading={
          () =>
            <div>
              <Typography component="h3" variant="subheading" color="error">
                {t('Order.in_progress')}
              </Typography>
            </div>
        }
        rightHeader={
          ({record}) =>
            <div>
              <Typography component="div" variant="subheading">
                {t('Order.prepared_by', { name: (record.bartender || {name:'-'}).name})}
              </Typography>
            </div>
        }
        toolbar={InProgressEditToolbar}
        extra={PasswordField} />
    )
  }
}

export default InProgressOrderForm
