export const CHANGE_MODE_REQUEST = "change-mode-request";
export const CHANGE_SELECTOR_REQUEST = "change-selector-request";
export const CHANGE_STYLES_REQUEST = "change-styles-request";
export const SAVE_CURRENT_STYLES_REQUEST = "save-current-styles-request";
export const CANCEL_SAVE_CURRENT_STYLE_REQUEST = "cancel-save-current-style-request";
export const CHANGE_STYLE_PERIMETER_REQUEST = "change-style-perimeter-request";
export const REQUEST_CUSTOM_STYLES = "request-custom-styles";
export const RECEIVED_CUSTOM_STYLES = "received-custom-styles";
export const RESTORE_STYLES_REQUEST = "restore-styles-request";
export const CHANGE_PREVIEW_FORMAT = "change-preview-format";
export const UPLOAD_FONT_REQUEST = "upload-font-request";
export const SHOW_HIDE_HIDDEN_ELEMENTS_REQUEST = "show-hide-hidden-elements-request";
export const RESET_ALL_STYLES_REQUEST = "reset-all-styles-request";

export const changeMode = (mode) => ({ type: CHANGE_MODE_REQUEST, payload: { mode } });
export const changeSelector = (selector) => ({ type: CHANGE_SELECTOR_REQUEST, payload: { selector } });
export const changeStyle = (selector, property, value) => ({ type: CHANGE_STYLES_REQUEST, payload: { selector, property, value } });
export const saveCurrentStyles = (venueId, styles) => ({ type: SAVE_CURRENT_STYLES_REQUEST, payload: { venueId, styles } });
export const cancelSaveCurrentStyle = () => ({ type: CANCEL_SAVE_CURRENT_STYLE_REQUEST });
export const changeStylePerimeter = (selector) => ({ type: CHANGE_STYLE_PERIMETER_REQUEST, payload: { selector } });
export const getCustomStyles = (venueId) => ({ type: REQUEST_CUSTOM_STYLES, payload: { venueId } });
export const restoreStyles = (selector) => ({ type: RESTORE_STYLES_REQUEST, payload: { selector } });
export const changePreviewFormat = (format) => ({ type: CHANGE_PREVIEW_FORMAT, payload: { format } });
export const uploadFont = (fontFile, venueId) => ({ type: UPLOAD_FONT_REQUEST, payload: { fontFile, venueId } });
export const showHiddenElements = (elements, show) => ({type: SHOW_HIDE_HIDDEN_ELEMENTS_REQUEST, payload: { elements, show }})
export const resetAllStyles = (venueId) => ({type: RESET_ALL_STYLES_REQUEST, payload: { venueId } })
