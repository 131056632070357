import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'

const PassphrasesList = (props) =>
  <List {...props} bulkActionButtons={false} pagination={null}>
    <Datagrid rowClick="edit">
      <TextField source="passphrase" sortable={false}/>
    </Datagrid>
  </List>

export default PassphrasesList
