import React, { useState } from 'react'
import {
  Edit,
  FormTab,
  TabbedForm,
  DisabledInput,
  TextInput,
  LongTextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ImageInput,
  ImageField,
  Labeled,
  FormDataConsumer,
  BooleanInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  required,
  DELETE
} from 'react-admin'
import { SortableArrayInput } from '../SortableArrayInput'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'
import Button from "@material-ui/core/Button"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Typography from '@material-ui/core/Typography'
import { dataProvider } from "../../clients/providers"

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

const SectionEditToolbar = props => {
  const [open, setOpen] = useState(false)

  const deleteMenu = () => {
    dataProvider(DELETE, "section", { id: props.record.id }).then(() => {
      setOpen(false)
      window.location.replace("/#/section")
    })
  }

  const deleteFullMenu = () => {
    dataProvider(DELETE, "section", { id: props.record.id, data: { recursive: true } }).then(() => {
      setOpen(false)
      window.location.replace("/#/section")
    })
  }

  return (
    <Toolbar {...props} style={{ displey: 'flex', justifyContent: 'space-between', width: 'auto' }} >
      <SaveButton />
      <Button variant="outlined" style={{ color: 'red', borderColor: 'red' }} onClick={() => setOpen(true)}>{t('Venue.delete_image')}</Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{"Suppression du menu"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{"Souhaitez vous supprimer le menu et son contenu, ou seulement le menu ?"}</Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'blue', borderColor: 'blue' }} onClick={() => setOpen(false)}>{t('Generic.cancel')}</Button>
          <Button style={{ color: 'red', borderColor: 'red' }} onClick={deleteFullMenu}>{t('Menu.delete_all')}</Button>
          <Button style={{ color: 'red', borderColor: 'red' }} onClick={deleteMenu}>{t('Menu.delete_entry')}</Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  )
}

export const SectionEdit = ({ permissions, ...props }) =>
  <Edit title={t('Menu.edit')} undoable={false} {...props}>
    <TabbedForm redirect="list" toolbar={<SectionEditToolbar />}>
      <FormTab label={t('Product.form_general')}>
        <ImageInput source="pictures" label={t('Menu.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Menu.error_image')) }} placeholder={<p>{t('Menu.tip_image')}</p>} multiple={false}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.pictures && rest.record.image != null) {
              return (
                <div>
                  <Labeled label={t('Menu.original_image')}>
                    <ImageField source="image" {...rest} />
                  </Labeled>
                </div>
              )
            }
          }}
        </FormDataConsumer>
        <TextInputField source='name' label={t('Menu.name')} validate={true} />
        <TextInputField source="description" label={t('Menu.description')} />
        <TextInput source="external_url" label={t('Menu.external_url')} style={{ width: '100%' }} />
        {permissions && permissions.premium && <BooleanInput label={t('Menu.payment')} source="payment" />}
        {permissions && permissions.premium && <span>{t('Menu.enable_payment')}<a href={"/#/venue/" + localStorage.getItem('venue') + "/3"}>{t('Menu.enable_payment_link')}</a>.</span>}
        {permissions && permissions.premium && <BooleanInput label={t('Menu.service')} source="table_service" />}
        <BooleanInput label={t('Menu.unavailable')} source="unavailable" />
      </FormTab>
      <FormTab label={t('Menu.exceptions')}>
        <ArrayInput source="exceptions" label={t('Product.exceptions')} fullWidth>
          <SimpleFormIterator>
            <FormDataConsumer>
              {
                ({ formData, getSource }) => {
                  return (
                    <>
                      <ReferenceInput label={t('Calendar.calendars')} source={getSource("calendar_id")} reference="calendar" validate={required()}>
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'baseline' }}>
                        <NumberInput label={t('Menu.position')} source={getSource("position")} defaultValue={formData.position} style={{ marginRight: 50 }} />
                        <BooleanInput source={getSource("unavailable")} label={t('Menu.unavailable')} defaultValue={formData.unavailable} />
                      </div>
                    </>
                  )
                }
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t('Product.translations')}>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.name) {
                return (<DisabledInput label={t('Menu.name')} source="name" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.description) {
                return (<DisabledInput label={t('Menu.description')} source="descritpion" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <ArrayInput label={t('Menu.translations')} source="languages" style={{ width: '100%' }}>
          <SimpleFormIterator>
            <TextInput source="name" label={t('Menu.name')} style={{ width: '100%' }} />
            <LongTextInput source="description" label={t('Menu.description')} />
            <SelectInput source="locale" label={t('Menu.language')} choices={locales} style={{ width: '100%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t('Product.categories')}>
        <SortableArrayInput source="categories" reference="category" />
      </FormTab>
    </TabbedForm>
  </Edit>

export default SectionEdit
