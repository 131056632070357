import React, { useState, useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { t } from "../../../i18n";

const FontFamilyInput = ({ handleChangeStyle, property, perimeter, value, hideLabel, ...props }) => {
  const { savedFonts } = props;
  const [fontFamily, setFontFamily] = useState(value["font-family"] || "");

  useEffect(
    () => {
      setFontFamily(value["font-family"] || "");
    },
    [value]
  );

  const handleChange = (event) => {
    setFontFamily(event.target.value);
    handleChangeStyle(event.target.value, property, perimeter);
  };

  return (
    <FormControl style={{ minWidth: 75, marginRight: 10 }}>
      {hideLabel ? null : <InputLabel style={{ fontSize: 14 }}>{t("Customization.font")}</InputLabel>}
      <Select value={fontFamily} style={{ fontFamily: fontFamily, fontSize: 14 }} onChange={handleChange}>
        <MenuItem key={"georgia"} value={"georgia"} style={{ fontFamily: "georgia" }}>
          Georgia
        </MenuItem>
        <MenuItem key={"verdana"} value={"verdana"} style={{ fontFamily: "verdana" }}>
          Verdana
        </MenuItem>
        <MenuItem key={"arial"} value={"arial"} style={{ fontFamily: "arial" }}>
          Arial
        </MenuItem>
        <MenuItem key={"muli"} value={"muli"} style={{ fontFamily: "muli" }}>
          Muli
        </MenuItem>
        {savedFonts.length
          ? savedFonts.map((font) => (
              <MenuItem key={font.name.split(".")[0]} value={font.name.split(".")[0] + `:${font.id}`} style={{ fontFamily: font.name.split(".")[0] }}>
                {font.name.split(".")[0]}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

function mapStateToProps(state) {
  return {
    savedFonts: state.styles.savedFonts,
  };
}

export default connect(mapStateToProps)(FontFamilyInput);
