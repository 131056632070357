import React, { useState } from "react"
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  TextField,
  LongTextInput,
  DisabledInput,
  Datagrid,
  Toolbar,
  SaveButton,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
  BooleanInput,
  BooleanField,
  required,
  maxLength,
  WithPermissions,
  MenuItemLink,
  SingleFieldList,
  ChipField
} from "react-admin"
import { ColorField } from "react-admin-color-input"
import currencyToSymbolMap from "currency-symbol-map/map"
import { Route } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import { withStyles } from "@material-ui/core/styles"
import Offers from "./Offers"
import classNames from "classnames"
import { t } from "../../i18n"
import BillList from "./BillList"
import GetApp from "@material-ui/icons/GetApp"
import BrushIcon from "@material-ui/icons/Brush"
import QRious from "qrious"

import VenuePopOver from "../calendar/VenuePopOver"
import EditCalendarButton from "../calendar/EditCalendarButton"
import TextInputField from "../textInputField/TextInputField"

import ContentCopy from '@material-ui/icons/ContentCopy'
import Tooltip from '@material-ui/core/Tooltip'

const qr = new QRious({
  size: 700,
  backgroundAlpha: 0,
  padding: 50,
  value: window._env_.REACT_APP_WEB_URL + "#" + localStorage.getItem("venue"),
})

const data = qr.toDataURL()

const QRButton = () =>
  <div style={{ margin: '20px 0px 0px 0px' }}>
    <a href={data} download={`QRC ${localStorage.getItem('business')}.png`}>
      <img src={data} alt="qrcode" height="200px" style={{ marginRight: "5px", verticalAlign: "middle" }} />
    </a>
  </div>

const SaveQRButton = () =>
  <Tooltip title={t('Venue.qr_download')}>
    <Button variant="fab" color="primary" href={data} download={`QRC ${localStorage.getItem('business')}.png`} style={{ height: "40px", width: "40px" }}>
      <GetApp />
    </Button>
  </Tooltip>

const SocialLinkButton = () =>
  <Tooltip title={t('Venue.copy_link_social')}>
    <Button variant="fab" color="primary" onClick={() => navigator.clipboard.writeText(`${window._env_.REACT_APP_WEB_URL}/v/${localStorage.getItem('venue')}`)} style={{ height: "40px", width: "40px" }}>
      <ContentCopy />
    </Button>
  </Tooltip>


const WebLinkButton = () =>
  <Tooltip title={t('Venue.copy_link')}>
    <Button variant="fab" color="primary" onClick={() => navigator.clipboard.writeText(`${window._env_.REACT_APP_WEB_URL}/#${localStorage.getItem('venue')}`)} style={{ height: "40px", width: "40px" }}>
      <ContentCopy />
    </Button>
  </Tooltip>

const MenuButtons = () =>
  <div style={{ display: "flex", flexDirection: "row", width: "170px", justifyContent: "space-between", margin: "0px 20px 20px 15px" }}>
    <SaveQRButton />
    <WebLinkButton />
    <SocialLinkButton />
  </div>

const CustomizationButton = () => (
  <div style={{ margin: "20px 0px" }}>
    <MenuItemLink
      style={{
        padding: "1px 0px",
        height: "auto",
        width: "auto",
        display: "inline-flex",
        bowShadow: "none",
      }}
      to={`/customization/${localStorage.getItem("venue")}`}
      primaryText={
        <Button variant='contained' color='secondary' size={"small"}>
          <BrushIcon />
          {t("Venue.customizeMenu")}
        </Button>
      }
    />
  </div>
)

const PremiumProfileToolbar = ({ permissions, ...props }) => {
  const { hasList, hasEdit, hasShow, hasCreate, redirect, ...rest } = props
  return (
    <Toolbar {...props}>
      <Route exact path={"/venue/:id"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/1"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/2"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/3"} render={(props) => ""} />
      <Route exact path={"/venue/:id/4"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/5"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/6"} render={(props) => ""} />
    </Toolbar>
  )
}

const SmartProfileToolbar = ({ permissions, ...props }) => {
  const { hasList, hasEdit, hasShow, hasCreate, redirect, ...rest } = props
  return (
    <Toolbar {...props}>
      <Route exact path={"/venue/:id"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/1"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/2"} render={(props) => <SaveButton {...rest} />} />
      <Route exact path={"/venue/:id/3"} render={(props) => ""} />
      <Route exact path={"/venue/:id/4"} render={(props) => ""} />
    </Toolbar>
  )
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
})

const validateSMS = (sms) => {
  //Test against GSM charset to determine max length
  var regex = /^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&amp;'()*+,\-./:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\[~\]|\u20AC]*$/
  if (regex.test(sms)) {
    return maxLength(155, t("Venue.sms_too_long_160"))
  }
  return maxLength(65, t("Venue.sms_too_long_70"))
}

const ProfileEdit = ({ permissions, ...props }) => {
  const { classes } = props
  const [paymentInfo, setPaymentInfo] = useState(false)

  const togglePaymentState = (optin, name) => {
    const jwt = localStorage.getItem("token")
    const id = localStorage.getItem("venue")
    const email = localStorage.getItem("email")
    fetch(
      new Request(`${window._env_.REACT_APP_API_URL}/payment/${id}/togglePartnerAccount`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }),
        body: JSON.stringify({ email: email, toggle: optin, name: name }),
      })
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.url) {
          window.location.replace(json.url)
        } else {
          window.location.reload()
        }
      })
  }

  const linkIKentoo = () => {
    const jwt = localStorage.getItem("token")
    const id = localStorage.getItem("venue")
    fetch(
      new Request(`${window._env_.REACT_APP_API_URL}/integrations/${id}/iKentoo`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }),
      })
    )
      .then((res) => res.json())
      .then((json) => window.location.replace(json.url))
  }

  const unlinkIKentoo = () => {
    const jwt = localStorage.getItem("token")
    const id = localStorage.getItem("venue")
    fetch(
      new Request(`${window._env_.REACT_APP_API_URL}/integrations/${id}/iKentoo/updatelink/0`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }),
      })
    )
  }

  const deleteVenueImage = () => {
    const jwt = localStorage.getItem("token")
    const id = localStorage.getItem("venue")

    fetch(
      new Request(`${window._env_.REACT_APP_API_URL}/venue/${id}/image`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }),
      })
    ).then(() => {
      window.location.reload()
    })
  }

  const submitStripeUpdate = (data) => {
    const jwt = localStorage.getItem("token")
    const id = localStorage.getItem("venue")
    fetch(
      new Request(`${window._env_.REACT_APP_API_URL}/payment/${id}/accountInformation`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }),
        body: JSON.stringify({ account_number: data.payment_account, country: data.payment_country, currency: data.payment_currency }),
      })
    )
  }

  const countries = [
    { id: "AU", name: t("Countries.Australia") },
    { id: "AT", name: t("Countries.Austria") },
    { id: "BE", name: t("Countries.Belgium") },
    { id: "BR", name: t("Countries.Brazil") },
    { id: "BG", name: t("Countries.Bulgaria") },
    { id: "CA", name: t("Countries.Canada") },
    { id: "CY", name: t("Countries.Cyprus") },
    { id: "CZ", name: t("Countries.Czech Republic") },
    { id: "DK", name: t("Countries.Denmark") },
    { id: "EE", name: t("Countries.Estonia") },
    { id: "FI", name: t("Countries.Finland") },
    { id: "FR", name: t("Countries.France") },
    { id: "DE", name: t("Countries.Germany") },
    { id: "GI", name: t("Countries.Gibraltar") },
    { id: "GR", name: t("Countries.Greece") },
    { id: "HK", name: t("Countries.Hong Kong") },
    { id: "IN", name: t("Countries.India") },
    { id: "IE", name: t("Countries.Ireland") },
    { id: "IT", name: t("Countries.Italy") },
    { id: "JP", name: t("Countries.Japan") },
    { id: "LT", name: t("Countries.Lithuania") },
    { id: "LU", name: t("Countries.Luxembourg") },
    { id: "LV", name: t("Countries.Latvia") },
    { id: "MT", name: t("Countries.Malta") },
    { id: "MY", name: t("Countries.Malaysia") },
    { id: "MX", name: t("Countries.Mexico") },
    { id: "NL", name: t("Countries.Netherlands") },
    { id: "NZ", name: t("Countries.New Zealand") },
    { id: "NO", name: t("Countries.Norway") },
    { id: "PL", name: t("Countries.Poland") },
    { id: "PT", name: t("Countries.Portugal") },
    { id: "RO", name: t("Countries.Romania") },
    { id: "SG", name: t("Countries.Singapore") },
    { id: "SK", name: t("Countries.Slovakia") },
    { id: "SI", name: t("Countries.Slovenia") },
    { id: "ES", name: t("Countries.Spain") },
    { id: "SE", name: t("Countries.Sweden") },
    { id: "CH", name: t("Countries.Switzerland") },
    { id: "GB", name: t("Countries.United Kingdom") },
    { id: "US", name: t("Countries.United States") },
  ]

  const locales = [
    { id: "fr-FR", name: "Français" },
    { id: "en-GB", name: "English" },
    { id: "es-ES", name: "Español" },
    { id: "pt-PT", name: "Português" },
    { id: "it-IT", name: "Italiano" },
    { id: "de-DE", name: "Deutsch" },
    { id: "ru-RU", name: "русский" },
    { id: "jp-JP", name: "日本語" },
    { id: "cn-CN", name: "中文（简体)" },
    { id: "ar-AR", name: "العربية" },
    { id: "he-IL", name: "עברית" },
  ]

  const stripeCurrencies = Object.entries(currencyToSymbolMap).map(c => ({ id: c[0].toLowerCase(), name: `${c[0]} (${c[1]})` }))

  const currencies = Object.entries(currencyToSymbolMap).map(c => ({ id: c[0], name: `${c[0]} (${c[1]})` }))

  return (
    <WithPermissions render={({ permissions }) => (
      <Edit undoable={false} id={localStorage.getItem("venue")} resource="profile" basePath={"/venue/profile/" + localStorage.getItem("venue")} title={t('Venue.profile')} {...props}>
        <TabbedForm toolbar={permissions && permissions.premium ? <PremiumProfileToolbar permissions={permissions} /> : <SmartProfileToolbar permissions={permissions} />} redirect={false}>
          <FormTab label={t('Venue.form_general')}>
            <QRButton />
            <MenuButtons />
            {/* <CustomizationButton /> */}
            <ImageInput source="pictures" label={t('Venue.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Venue.error_image')) }} placeholder={<p>{t('Venue.tip_image')}</p>} multiple={false}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (!formData.pictures && rest.record.image != null) {
                  return (
                    <>
                      <div>
                        <Labeled label={t('Venue.original_image')}>
                          <ImageField source="image" {...rest} />
                        </Labeled>
                      </div>
                      <div>
                        <Button variant="outlined" style={{ color: 'red', borderColor: 'red' }} onClick={() => deleteVenueImage()}>{t('Venue.delete_image')}</Button>
                      </div>
                    </>
                  )
                }
              }}
            </FormDataConsumer>
            <TextInput source="name" label={t('Venue.name')} validate={required()} style={{ width: '100%' }} />
            <BooleanInput source="display_name" label={t('Venue.display_name')} />
            <TextInput source="business_name" label={t('Venue.business_name')} validate={required()} style={{ width: '100%' }} />
            <LongTextInput source="instagram" />
            <LongTextInput source="facebook" />
            <LongTextInput source="tripadvisor" />
            <LongTextInput source="gmaps" label="Google Maps" />
            <LongTextInput source="website" />
            <LongTextInput source="address" label={t('Venue.address')} validate={required()} />
            <LongTextInput source="billing_address" label={t('Venue.billing_address')} validate={required()} />
            <LongTextInput source="legal" label={t('Venue.legal')} />
            <SelectInput label={t('Venue.country')} defaultValue="FR" source="country" choices={countries} validate={required()} style={{ width: '100%' }} />
            <SelectInput label={t('Venue.currency')} defaultValue="EUR" source="currency" choices={currencies} style={{ width: '100%' }} />
            <SelectInput label={t('Venue.language_source')} defaultValue="fr-FR" source="source_locale" validate={required()} choices={locales} style={{ width: '100%' }} />
            <SelectInput label={t('Venue.language')} defaultValue="fr-FR" source="default_locale" validate={required()} choices={locales} style={{ width: '100%' }} />
            <BooleanInput label={t('Venue.wishlist')} source="wishlist" style={{ width: '100%' }} />
            <BooleanInput label={t('Venue.welcome_box')} source="welcome_box" />
          </FormTab>
          <FormTab label={t('Venue.form_hours')}>
            <ReferenceManyField reference='calendar' target='venue_uuid' fullWidth label=''>
              <Datagrid>
                <TextField source="name" label={t('Calendar.name')} sortable={false} />
                <BooleanField source="highlight" label={t('Calendar.highlight')} sortable={false} />
                <TextField source="highlight_text" label={t('Calendar.highlight_text')} sortable={false} />
                <ColorField source="highlight_color" label={t('Calendar.highlight_color')} sortable={false} />
                <EditCalendarButton type='Edit' />
              </Datagrid>
            </ReferenceManyField>
            <VenuePopOver callToAction={t('Calendar.new_calendar')} type='Create' />
          </FormTab>
          <FormTab label={t('Venue.form_users')}>
            {permissions && permissions.admin &&
              <ReferenceArrayInput label={t('Venue.bartenders')} source="bartenders" reference="registered-user" style={{ width: '100%' }}>
                <AutocompleteArrayInput source="id" optionText="email" style={{ width: '100%' }} />
              </ReferenceArrayInput>
            }
            {permissions && permissions.admin &&
              <ReferenceArrayInput label={t('Venue.managers')} source="managers" reference="registered-user" style={{ width: '100%' }}>
                <AutocompleteArrayInput source="id" optionText="email" style={{ width: '100%' }} />
              </ReferenceArrayInput>
            }
            {permissions && !permissions.admin && permissions.manager && permissions.premium &&
              <ReferenceArrayField label={t('Venue.bartenders')} source="bartenders" reference="registered-user" style={{ width: '100%' }}>
                <SingleFieldList linkType={false}>
                  <ChipField source="email" clickable={false} />
                </SingleFieldList>
              </ReferenceArrayField>
            }
            {permissions && !permissions.admin && permissions.manager &&
              <ReferenceArrayField label={t('Venue.managers')} source="managers" reference="registered-user" style={{ width: '100%' }}>
                <SingleFieldList linkType={false} >
                  <ChipField source="email" />
                </SingleFieldList>
              </ReferenceArrayField>
            }
            {permissions && (permissions.manager || permissions.admin) &&
              <ReferenceArrayInput label={t('User.readonly')} source="readers" reference="registered-user" style={{ width: '100%' }}>
                <AutocompleteArrayInput source="id" optionText="email" style={{ width: '100%' }} />
              </ReferenceArrayInput>
            }
            <TextInput label={t('Venue.billing_email')} source="billing_email" type="email" validate={required()} />
          </FormTab>
          <FormTab label={t('Venue.offers')}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return <Offers info={rest.record.subscription} />
              }}
            </FormDataConsumer>
          </FormTab>
          {permissions && permissions.premium && (
            <FormTab label={t("Venue.form_payment")}>
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  return (
                    <>
                      <br />
                      <Typography variant='body1'>{t("Venue.account_link_tip")}</Typography>
                      <br />
                      <br />
                      <Typography variant='body1'>
                        {t("Venue.tos_shown_and_accepted_1")}
                        <a href='/legal/TermsAndConditions.pdf' target='_blank'>
                          {t("Venue.tos_shown_and_accepted_2")}
                        </a>
                        {t("Venue.tos_shown_and_accepted_3")}
                        <a href='https://stripe.com/connect-account/legal' target='_blank' rel='noopener noreferrer'>
                          {t("Venue.tos_shown_and_accepted_4")}
                        </a>
                        .
                      </Typography>
                      <BooleanInput
                        source='payment.opt_in'
                        label={t("Venue.enable_payments")}
                        options={{
                          onChange: (event) => togglePaymentState(event.target.checked, formData.name),
                        }}
                      />
                    </>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  if (formData.payment && formData.payment.opt_in) {
                    if (!rest.record.payment.account_validated) {
                      return (
                        <span style={{ color: "red", fontStyle: "italic" }}>
                          {t("Venue.form_payment_verify_1")}
                          <span
                            style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => togglePaymentState(true, formData.name)}
                          >
                            {t("Venue.form_payment_verify_2")}
                          </span>
                          .
                        </span>
                      )
                    }
                    return <DisabledInput source='payment.account_validated' label={t("Venue.account_validated")} />
                  }
                }}
              </FormDataConsumer>
              <br />
              {paymentInfo ? (
                <div>
                  <LongTextInput source='payment_account' label={t("Venue.form_payment_account_number")} />
                  <SelectInput label={t("Venue.form_payment_account_country")} defaultValue='FR' source='payment_country' choices={countries} />
                  <SelectInput
                    label={t("Venue.form_payment_account_currency")}
                    defaultValue='eur'
                    source='payment_currency'
                    choices={stripeCurrencies}
                  />
                  <br />
                  <br />
                  <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                      return (
                        <Button variant='contained' startIcon={<AccountBalanceIcon />} color='default' onClick={() => submitStripeUpdate(formData)}>
                          {t("Venue.form_payment_submit_info")}
                        </Button>
                      )
                    }}
                  </FormDataConsumer>
                </div>
              ) : (
                <Button variant='contained' color='default' onClick={() => setPaymentInfo(true)}>
                  <AccountBalanceIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                  {t("Menu.enable_payment_link")}
                </Button>
              )}
              <br />
              <br />
              <BooleanInput source='payment.test' label={t("Venue.enable_payments_test_env")} />
              <BooleanInput source='payment.sms' label={t("Venue.sms")} />
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  if (formData.payment && formData.payment.sms) {
                    return (
                      <>
                        <LongTextInput
                          source='payment.message_ok'
                          label={t("Venue.sms_message_ok")}
                          validate={[required(), validateSMS(formData.payment.message_ok)]}
                        />
                        <Typography variant='caption' style={{ color: "gray", whiteSpace: "pre-wrap", textDecoration: "underline" }}>
                          {t("Profile.examples")}
                        </Typography>
                        <Typography variant='caption' style={{ color: "gray", whiteSpace: "pre-wrap" }}>
                          {t("Profile.sms")}
                        </Typography>
                        <LongTextInput
                          source='payment.message_ko'
                          label={t("Venue.sms_message_ko")}
                          validate={[required(), validateSMS(formData.payment.message_ko)]}
                        />
                      </>
                    )
                  }
                }}
              </FormDataConsumer>
            </FormTab>
          )}
          {permissions && permissions.premium && (
            <FormTab label={t("Venue.form_integrations")}>
              <br />
              <Typography variant='body1' style={{ width: "100%" }}>
                {t("Venue.form_integrations_info")}
              </Typography>
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  if (rest.record.integration === "iKentoo") {
                    return (
                      <Labeled label='Lightspeed'>
                        <div style={{ display: "flex", flexDirection: "column", width: "100px" }}>
                          <img
                            src='https://15f76u3xxy662wdat72j3l53-wpengine.netdna-ssl.com/wp-content/uploads/2020/06/Lightspeed-integration-connector_logo-300x300.png'
                            alt=''
                            height='100'
                            width='100'
                          />
                          <Button color='primary' onClick={unlinkIKentoo}>
                            {t("Integrations.unlink")}
                          </Button>
                        </div>
                      </Labeled>
                    )
                  } else {
                    return (
                      <Labeled label='Lightspeed'>
                        <div style={{ display: "flex", flexDirection: "column", width: "100px" }}>
                          <img
                            src='https://15f76u3xxy662wdat72j3l53-wpengine.netdna-ssl.com/wp-content/uploads/2020/06/Lightspeed-integration-connector_logo-300x300.png'
                            alt=''
                            height='100'
                            width='100'
                          />
                          <Button color='primary' onClick={linkIKentoo}>
                            {t("Integrations.link")}
                          </Button>
                        </div>
                      </Labeled>
                    )
                  }
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  if (rest.record.integration === "iKentoo") {
                    return (
                      <>
                        <ReferenceInput label='Businesses' source='business_id' reference='businesses' defaultValue=''>
                          <SelectInput optionText='name' />
                        </ReferenceInput>
                        <BooleanInput label={t("Profile.import")} source='import' />
                      </>
                    )
                  }
                }}
              </FormDataConsumer>
            </FormTab>
          )}
          <FormTab label={t("Bills.title")} path='bills'>
            <BillList />
          </FormTab>
        </TabbedForm>
      </Edit>
    )}
    />
  )
}

export default withStyles(styles)(ProfileEdit)
