import React from 'react'
import { List, Datagrid, TextField, DateField, BooleanField, Pagination, Filter, TextInput } from 'react-admin'
import { t } from '../../i18n'

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t('User.search')} source="name" alwaysOn style={{width: '300px'}} />
  </Filter>
)

const UserList = (props) =>
  <List {...props} bulkActionButtons={false} perPage={25} pagination={<UserPagination />} filters={<UserFilter />} title={t('Menu.title')}>
    <Datagrid rowClick="edit">
      <TextField source="firstname" label={t('User.firstname')} sortable={false}/>
      <TextField source="lastname" label={t('User.lastname')} sortable={false}/>
      <TextField source="email" label={t('User.email')} sortable={false}/>
      <BooleanField source="admin" label={t('User.admin')} sortable={false}/>
      <BooleanField source="newsletter" label={t('User.newsletter')} sortable={false}/>
      <DateField source="registration_date" label={t('User.registration')} sortable={false}/>
    </Datagrid>
  </List>

export default UserList
