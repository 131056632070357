import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import GetApp from '@material-ui/icons/GetApp'
import QRious from 'qrious'

const TableQRField = props => {
  const { record } = props
  const { id, number } = record

  const qr = new QRious({
    size: 700,
    backgroundAlpha: 0,
    padding: 50,
    value: window._env_.REACT_APP_WEB_URL + '#' + localStorage.getItem('venue') + '/table/' + number
  })

  const data = qr.toDataURL()
  const onClick = (e) => { e.stopPropagation() }

  return (
    <IconButton component="a" download={'Table ' + number + '.png'} href={data} onClick={onClick}>
      <GetApp />
    </IconButton>
  );
}


export default TableQRField