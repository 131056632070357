import React from 'react'
import {
  Datagrid,
  List,
  downloadCSV,
  DateField,
  TextField,
  NumberField,
  FunctionField,
  Filter,
  DateInput,
  ReferenceInput,
  SelectInput
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import { t } from '../../i18n'


const styles = {
  itemContainer : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'flex-begin'
  }
}

const productsCount = ({items}) =>
  (items || []).reduce((a,n) => a + n.qty, 0)

const preparationTimeAsSeconds = ({preparation_date, completion_date, delivery_date}) =>
  preparation_date && (completion_date || delivery_date) ?
    Math.ceil(moment.duration(
      new moment((completion_date || delivery_date)).diff(new moment(preparation_date))
    ).asSeconds()) : ''

const waitingTimeAsSeconds = ({submission_date, preparation_date}) =>
  submission_date && preparation_date ?
    Math.ceil(moment.duration(
      new moment(preparation_date).diff(new moment(submission_date))
    ).asSeconds()) : ''

const totalPrice = ({items}) =>
  (items || []).reduce((a,n) => a + n.net_price, 0)

const totalPriceHT = ({items}) =>
  (items || []).reduce((a,n) => a + n.net_price/(1+n.tax_rate), 0)

const TotalPriceField = ({record, source}) => {
  const price = totalPrice(record)
  const newRecord = {...record, [source] : price}

  return <NumberField source={source} options={{ style: 'currency', currency: 'EUR' }} record={newRecord} />
}

const dotToCommaDecimals = (number) => (number + '').replace('.', ',')

const TotalPriceFieldHT = ({record, source}) => {
  const price = totalPriceHT(record);
  const newRecord = {...record, [source] : price}

  return <NumberField source={source} options={{ style: 'currency', currency: 'EUR' }} record={newRecord} />
}

const ItemsField = withStyles(styles)(({record, source, classes}) => {
  const items = record[source];

  return (
    <div>
      {
        items.map(item =>
          <div key={item.uuid} className={classes.itemContainer}>
            <div>
              <Typography variant="body1">
                {item.qty} x
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                &nbsp;{item.name}
              </Typography>
            </div>
          </div>
        )
      }
    </div>
  )
})

const exporter = orders => {
  const explodedOrders = []

  orders.forEach(order => {
    const { items, ...rest } = order;
    items.forEach(item => {
      explodedOrders.push({
        ...rest,
        item_hrid : item.hrid,
        item_name : item.name,
        item_qty  : item.qty,
        item_unit_price : item.unit_price,
        item_net_price  : item.net_price,
        item_tax_rate   : item.tax_rate
      })
    })
  })

  const exportData = explodedOrders.map(order => ({
    'ID Bar'                 : order.venue.hrid,
    'Bar'                    : order.venue.name,
    'ID commande'            : order.hrid,
    'Date de Creation'       : order.creation_date || '',
    'Date d\'envoi'          : order.submission_date || '',
    'Date de Preparation'    : order.completion_date || '',
    'Date de Livraison'      : order.delivery_date || '',
    'Date d\'Annulation'     : order.cancellation_date || '',
    'Attente (s)'            : waitingTimeAsSeconds(order),
    'Preparation (s)'        : preparationTimeAsSeconds(order),
    'ID client'              : order.customer.hrid,
    'Client'                 : order.customer.name,
    'Email client'           : order.customer.email,
    'ID bartender'           : order.bartender.hrid,
    'Bartender'              : order.bartender.name,
    'Status'                 : order.status,
    'ID produit'             : order.item_hrid,
    'Produit'                : order.item_name,
    'Quantity'               : order.item_qty,
    'Prix unitaire (TTC)'    : dotToCommaDecimals(order.item_unit_price.toFixed(2)),
    'Prix (TTC)'             : dotToCommaDecimals(order.item_net_price.toFixed(2)),
    'Taux TVA'               : order.item_tax_rate*100 + '%'
  }))

  if (exportData.length === 0) return;

  const csv = convertToCSV({
    data   : exportData,
    fields : Object.keys(exportData[0])
  },{
  	delimiter: ";",
  	header: true,
  	skipEmptyLines: 'greedy'
  })

  downloadCSV(csv, 'dump')
};

const DumpFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="venue_id" reference="venue" label={t('History.venue')} alwaysOn>
      <SelectInput optionText="name" label={t('History.name')} />
    </ReferenceInput>
    <DateInput label={t('History.start')} source="begin_date" alwaysOn />
    <DateInput label={t('History.end')} source="end_date"   alwaysOn />
  </Filter>
)

const HistoryDump = (props) =>
  <List {...props} title="Dump" bulkActionButtons={false} pagination={null} exporter={exporter} filters={<DumpFilter />} >
    <Datagrid>
      <DateField source="creation_date" label={t('History.creation')} sortable={false}/>
      <TextField label={t('History.bar')} source="venue.name" sortable={false}/>
      <TextField label={t('History.client')} source="customer.name" sortable={false}/>
      <TextField label={t('History.table')} source="customer.table" sortable={false}/>
      <TextField label={t('History.bartender')} source="bartender.name" sortable={false}/>
      <TextField label={t('History.status')} source="status" sortable={false}/>
      <FunctionField label={t('History.number_products')} render={productsCount} sortable={false}/>
      <ItemsField label={t('History.products')} source="items" sortable={false}/>
      <TotalPriceField label={t('History.incl_tax_price')} source="total_price" sortable={false}/>
      <TotalPriceFieldHT label={t('History.excl_tax_price')} source="total_price_ht" sortable={false}/>
      <TextField label='ID' source="ref" sortable={false} />
    </Datagrid>
  </List>

export default HistoryDump
