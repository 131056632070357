import React, { Fragment } from 'react'
import './SegmentedControl.css'

export default props => {
	const { options, style, className, selected } = props

	return (
		<div style={style}>
			<div id={`scSegmentedControl`}>
				{
					options.map((option, i) =>
						<Fragment key={`scsc${i}`}>
							<input style={{display: 'none'}} id={`${className}${i}`} type="radio" name={`${className}Button`} onClick={option.action} checked={selected === i} />
							<label className="labels" htmlFor={`${className}${i}`}>{option.label}</label>
						</Fragment>
					)
				}
				<div id={`scBackground`}></div>
			</div>
		</div>
	)
}