import React, { cloneElement, useEffect, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	row: {
		backgroundColor: "white",
		padding: "0px 20px",
		display: "flex",
		alignItems: "center",
		minHeight: "20px",
		lineHeight: "20px",
		width: "auto",
		justifyContent: "space-between",
		borderBottom: "1px solid lightgray",
		cursor: "pointer",
	},
	content: {
		display: "flex",
		alignItems: "center",
		minHeight: "20px",
		lineHeight: "20px",
		width: "100%",
		justifyContent: "space-between"
	},
}

const DragHandle = SortableHandle(() => (<DragHandleIcon color="disabled" style={{ cursor: "move" }} />))

const SortableItem = withStyles(styles)(SortableElement(({ record, resource, label, fields, rowClick, basePath }) => {
	const children = [].concat(fields)
	const onClick = () => {
		switch (rowClick) {
			case "edit":
				window.location.href = `/#${basePath}/${record.id}`
				break
			default:
				break
		}
	}

	const itemWidth = document.getElementById(record.id) ? document.getElementById(record.id).offsetWidth / children.length - 60 : 0
	return (
		<div id={record.id} style={styles.row} onClick={onClick} onMouseOver={() => document.getElementById(record.id).style.backgroundColor = '#F0F0F0'} onMouseOut={() => document.getElementById(record.id).style.backgroundColor = 'white'}>
			<DragHandle />
			<div style={styles.content}>
				{children.map((field, index) => (
					<div key={label + '-' + index} style={{ padding: 5, width: `${itemWidth}px` }}>
						{cloneElement(field, { ...field.props, record: field.props.record || record, resource: resource, basePath: basePath })}
					</div>
				))}
			</div>
		</div>
	)
}))

const SortableList = SortableContainer(({ items, data, ids, fields, rowClick, resource, basePath }) => {
	return (
		<div>
			<style dangerouslySetInnerHTML={{ __html: `.sortableHelper{ z-index:10; }` }} />
			{items && items.map((value, index) => (
				<SortableItem
					key={`item-${index}`}
					basePath={basePath}
					resource={resource}
					fields={fields}
					index={index}
					label={data[value].name}
					record={data[value]}
					rowClick={rowClick}
				/>
			))}
		</div>
	)
})

export const SortableDataGrid = props => {
	const { ids, data, basePath, filterValues, rowClick, onSort, cache } = props
	const { reference, resource, source, children: fields } = props
	const [items, setItems] = useState(Object.values(data).map(v => v.id))

	const onSortEnd = ({ oldIndex, newIndex }) => {
		let positions = arrayMove(items, oldIndex, newIndex)
		setItems(positions)
		if (onSort) onSort(positions)
		if (cache) localStorage.setItem(basePath, JSON.stringify(positions))
	}

	useEffect(() => {
		const values = Object.values(data)
		const storedPositions = cache && localStorage.getItem(basePath) ? JSON.parse(localStorage.getItem(basePath)) : []
		if (!values.length) return;
		if (storedPositions.length === values.length) {
			setItems(storedPositions.filter(id => ids.includes(id)))
		} else {
			localStorage.removeItem(basePath)
			setItems(values.map(v => v.id).filter(id => ids.includes(id)))
		}
	}, [data])

	const fieldWidth = 100 / fields.length + "%"

	return (
		<div style={{ marginTop: "30px" }}>
			<div style={{ display: "flex", flexDirection: "row", height: "40px", justifyContent: "space-between", borderBottom: "1px solid lightgray" }}>
				<div style={{ width: '50px' }} />
				{fields.map(field => <Typography key={field.props.label} variant="caption" style={{ color: "gray", width: fieldWidth }}>{field.props.label}</Typography>)}
			</div>
			<SortableList
				basePath={basePath}
				resource={resource}
				useDragHandle
				ids={ids}
				data={data}
				items={items}
				fields={fields}
				helperClass="sortableHelper"
				onSortEnd={onSortEnd}
				rowClick={rowClick}
			/>
		</div>
	)
}