import React from 'react'
import { Create, SimpleForm, TextInput, email } from 'react-admin'
import { t } from '../../i18n'

export const UserCreate = (props) =>
  <Create {...props}>
    <SimpleForm redirect="list">
        <TextInput source="firstname" label={t('User.firstname')} />
        <TextInput source="lastname" label={t('User.lastname')} />
        <TextInput source="email" label={t('User.email')} validate={email()}/>
    </SimpleForm>
  </Create>

export default UserCreate
