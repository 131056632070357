import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";

import { CENTER, DISPLAY, NONE, START, FLEX, END } from "../constants";

const ButtonStyled = withStyles({
  root: {
    minWidth: 20,
  },
})(Button);

const JustifyInput = ({ handleChangeStyle, property, perimeter, perimeterBis, value, imagePerimeter }) => {
  const [justify, setJustify] = useState(value[property] && value[property]);

  const handleChange = (e) => {
    e.preventDefault();
    setJustify(e.currentTarget.value);

    if (e.currentTarget.value === START) {
      handleChangeStyle(FLEX, DISPLAY, imagePerimeter);
    } else {
      handleChangeStyle(NONE, DISPLAY, imagePerimeter);
    }

    //Line ellisis component uses text-align instead of justify-content
    const TEXT_ALIGN = "text-align";
    handleChangeStyle(e.currentTarget.value, TEXT_ALIGN, perimeterBis);

    if (perimeter.length && typeof perimeter !== "string") {
      perimeter.forEach((element) => {
        handleChangeStyle(e.currentTarget.value, property, element);
      });
    } else {
      handleChangeStyle(e.currentTarget.value, property, perimeter);
    }
  };

  return (
    <div style={{ minWidth: 30, marginRight: 10 }}>
      <ButtonStyled size='small' value={START} onClick={(e) => handleChange(e)}>
        <FormatAlignLeftIcon color={justify === START ? "primary" : "disabled"} />
      </ButtonStyled>
      <ButtonStyled size='small' value={CENTER} onClick={(e) => handleChange(e)}>
        <FormatAlignCenterIcon color={justify === CENTER ? "primary" : "disabled"} />
      </ButtonStyled>
      <ButtonStyled size='small' value={END} onClick={(e) => handleChange(e)}>
        <FormatAlignRightIcon color={justify === END ? "primary" : "disabled"} />
      </ButtonStyled>
    </div>
  );
};
export default JustifyInput;
