import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, Notification } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";

import MenuPreview from "./MenuPreview";
import ToolPanel from "./ToolPanel";

import { t } from "../../i18n";
import CustomizationToolbar from "./CustomizationToolbar";
import VisibilityInput from "./styleInputs/VisibilityInput";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BackgroundColorInput from "./styleInputs/BackgroundColorInput";
import FontFamilyInput from "./styleInputs/FontFamilyInput";
import { BLOCK, BORDER_TOP, BORDER_TOP_COLOR, BORDER_TOP_WIDTH, DISPLAY, FLEX, FONT_COLOR, FONT_FAMILY, JUSTIFY_CONTENT } from "./constants";
import FontColorInput from "./styleInputs/FontColorInput";
import JustifyInput from "./styleInputs/JustifyInput";
import SeparatorsBorderInput from "./styleInputs/SeparatorsBorder";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
});

const GeneralCustomization = ({ handleChangeStyle, ...props }) => {
  const { classes, currentStyles } = props;

  const VISIBILITY = "visibility";
  const BACKGROUND_COLOR = "background-color";
  const panels = [VISIBILITY, BACKGROUND_COLOR];

  const [expanded, setExpanded] = useState(panels[0]);

  const handlePanel = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : null);
  };

  return (
    <div className={classNames(classes.root)}>
      <ExpansionPanel defaultExpanded expanded={expanded === VISIBILITY} onChange={handlePanel(VISIBILITY)}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{t("Customization.visibility")}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.restaurantName")}
            </Typography>

            <VisibilityInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-venue-name"] || ""}
              property={DISPLAY}
              perimeter={".cst-global-venue-name"}
              defaultValue={FLEX}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.menuNames")}
            </Typography>
            <VisibilityInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-_menu-gutter-title"] || ""}
              property={DISPLAY}
              perimeter={".cst-global-_menu-gutter-title"}
              perimeterBis={".cst-global-section-name"}
              perimeterTer={".cst-global-section-description"}
              defaultValue={BLOCK}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.categoriesNames")}
            </Typography>
            <VisibilityInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-categories-container"] || ""}
              property={DISPLAY}
              perimeter={".cst-global-categories-container"}
              defaultValue={FLEX}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.separators")}
            </Typography>
            <VisibilityInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-product-card"] || ""}
              property={BORDER_TOP}
              perimeter={".cst-global-product-card"}
              defaultValue={"1px solid lightgray"}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded expanded={expanded === BACKGROUND_COLOR} onChange={handlePanel(BACKGROUND_COLOR)}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{t("Customization.theme")}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.center")}
            </Typography>
            <JustifyInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-_category-name"] || "main-container"}
              property={JUSTIFY_CONTENT}
              perimeter={[".cst-global-_category-name", ".cst-global-product-quantity-name", ".cst-global-price", ".cst-global-tagbox"]}
              perimeterBis={".cst-global-recipe"}
              imagePerimeter={".cst-productList-image"}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.font")}
            </Typography>
            <FontFamilyInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-main-container"] || "main-container"}
              property={FONT_FAMILY}
              perimeter={".cst-global-main-container"}
              hideLabel={true}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.fontColor")}
            </Typography>
            <FontColorInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-main-container"] || "main-container"}
              property={FONT_COLOR}
              perimeter={[".cst-global-main-container", ".cst-global-product-fp-info", ".cst-global-_category-name"]}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.backgroundColor")}
            </Typography>
            <BackgroundColorInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-venue-header"] || ""}
              property={BACKGROUND_COLOR}
              perimeter={[
                ".cst-global-venue-header",
                ".cst-global-_menu-gutter",
                ".cst-global-categories-container",
                ".cst-global-products-container",
                ".cst-global-appero-footer",
                ".cst-global-product-fp",
              ]}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.separatorsBorderWidth")}
            </Typography>
            <SeparatorsBorderInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-product-card"] || ""}
              property={BORDER_TOP_WIDTH}
              perimeter={".cst-global-product-card"}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "50%", marginBottom: 10 }}>
            <Typography className={classes.heading} variant='caption'>
              {t("Customization.separatorsColor")}
            </Typography>
            <BackgroundColorInput
              handleChangeStyle={handleChangeStyle}
              value={currentStyles[".cst-global-product-card"] || ""}
              property={BORDER_TOP_COLOR}
              perimeter={".cst-global-product-card"}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentStyles: state.styles.currentStyles,
    savedFonts: state.styles.savedFonts,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(GeneralCustomization));
