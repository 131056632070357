import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import FormatSizeIcon from "@material-ui/icons/FormatSize";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { t } from "../../../i18n";
import { DISPLAY, NONE, VISIBILITY } from "../constants";

const SwitchStyled = withStyles({
  switchBase: {
    height: 0,
  },
  root: {
    display: "inline",
  },
})(Switch);

const VisibilityInput = ({ handleChangeStyle, property, perimeter, value, defaultValue, perimeterBis, perimeterTer }) => {
  const [visible, setVisible] = useState(value[property] && value[property] !== defaultValue ? false : true);

  const handleChange = (event) => {
    setVisible(!visible);
    const display = visible ? NONE : defaultValue;

    handleChangeStyle(display, property, perimeter);
    if (perimeterBis) {
      handleChangeStyle(display, property, perimeterBis);
    }
    if (perimeterTer) {
      handleChangeStyle(display, property, perimeterTer);
    }
  };

  return (
    <FormControl style={{ minWidth: 30, marginRight: 10 }}>
      <FormControlLabel
        control={<SwitchStyled checked={visible} onChange={handleChange} name='checkedA' color='primary' />}
        label={
          visible ? (
            <VisibilityIcon style={{ display: "flex", marginLeft: -5 }} color='primary' />
          ) : (
            <VisibilityOffIcon style={{ display: "flex", marginLeft: -5 }} color='disabled' />
          )
        }
      />
    </FormControl>
  );
};
export default VisibilityInput;
