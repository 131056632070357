import i18next from 'i18next'

import en from './translations/en.json'
import fr from './translations/fr.json'
import es from './translations/es.json'
import it from './translations/it.json'
import pt from './translations/pt.json'
import de from './translations/de.json'

i18next.init({
  lng         : localStorage.getItem('locale') || navigator.language,
  fallbackLng : 'fr',
  resources   : {
    en : {translation : en},
    fr : {translation : fr},
    es : {translation : es},
    it : {translation : it},
    pt : {translation : pt},
    de : {translation : de},
  }
})

const t = (...args) => i18next.t(...args)

export {i18next, t}