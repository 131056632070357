export const formatDate = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
	.toISOString()
	.split("T")[0]
	.replace(/-/g, '')

export const arrayChunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size))

export const sumArrays = (...arrays) => {
	const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0)
	const result = Array.from({ length: n })
	return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0))
}

export const graphTitle = (days, range) => {
	if (range) {
		return range[0].toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' }) + ' - ' + range[1].toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })
	}
	let yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	switch (days) {
		case 1: return yesterday.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })
		default:
			let yesterweek = new Date()
			yesterweek.setDate(yesterweek.getDate() - days)
			return yesterweek.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' }) + ' - ' + yesterday.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })

	}
}

export const sheetName = (days, range) => {
	if (range) {
		return range[0].toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' }) + ' - ' + range[1].toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })
	}
	let yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	switch (days) {
		case 1: return yesterday.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })
		default:
			let yesterweek = new Date()
			yesterweek.setDate(yesterweek.getDate() - days)
			return yesterweek.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' }) + ' - ' + yesterday.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' })

	}
}