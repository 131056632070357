const venueReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case 'VENUE_LOADED':
      return payload.venue;

    case 'VENUE_STATE_UPDATED':
      return {
        ...state,
        open : payload.state
      };

    default:
      return state;
  }
}

export default venueReducer;
