import React, { useState, useEffect, useCallback } from "react";

import ColorPicker from "material-ui-color-picker";

import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import "./chromePicker.css";

import debounce from "lodash.debounce";
import { CHANGE_COLOR_DELAY } from "../constants";
import { t } from "../../../i18n";

const BackgroundColorInput = ({ handleChangeStyle, property, perimeter, value }) => {
  const [backgroundColor, setBackgroundColor] = useState(value[property] || "");

  useEffect(
    () => {
      setBackgroundColor(value[property] || "");
    },
    [value]
  );
  const debouncedColorChange = useCallback(
    debounce((color, perimeter) => {
      if (perimeter.length && typeof perimeter !== "string") {
        perimeter.forEach((element) => {
          handleChangeStyle(color, property, element);
        });
      } else {
        handleChangeStyle(color, property, perimeter);
      }
    }, CHANGE_COLOR_DELAY),
    []
  );

  const handleChange = (color) => {
    setBackgroundColor(color);

    debouncedColorChange(color, perimeter);
  };

  return (
    <ColorPicker
      label={
        <div style={{ display: "flex", justifyContent: "space-around", width: 50 }}>
          <FormatColorFillIcon style={{ fontSize: 16, fill: "rgba(0, 0, 0, 0.54)" }} />
          <div style={{ width: 14, height: 14, backgroundColor: backgroundColor || "rgba(0, 0, 0, 0.54)", borderRadius: "50%" }} />
        </div>
      }
      InputLabelProps={{
        style: { color: backgroundColor || "rgba(0, 0, 0, 0.54)", fontSize: 14 },
      }}
      style={{ color: backgroundColor || "rgba(0, 0, 0, 0.54)", overflow: "visible", width: 50, marginBottom: 1, marginRight: 10, cursor: "default" }}
      value={backgroundColor}
      onChange={(color) => handleChange(color)}
    />
  );
};

export default BackgroundColorInput;
