import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'react-router-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { t } from '../../i18n'

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

class OutOfStockButton extends Component {
  state = {
    showDialog : false
  }

  render() {
    const { showDialog } = this.state
    const { classes, record } = this.props

    const handleClick = () => {
      this.setState({ showDialog: true })
    }

    const handleCloseClick = () => {
      this.setState({ showDialog: false })
    }

    const handleOutOfStock = () => {
      const { record, destination = '/', showNotification, push } = this.props

      const token = localStorage.getItem('token')

      fetch(`${window._env_.REACT_APP_API_URL}/menu/${record.uuid}/unavailable`, {
        method : 'PUT',
        headers : new Headers({ 'Authorization' : `Bearer ${token}`})
      })
      .then(
        () => {
          showNotification(t('Order.modification_saved'));
          push(destination);
          this.setState({ showDialog: false });
        },
        e => {
          console.error(e);
          showNotification(t('Order.connexion_error'), 'warning');
        }
      )
    }

    return (
      <React.Fragment>
        <Button
          aria-label={t('Order.out_of_stock')}
          color="default"
          variant="outlined"
          style={{marginRight : '1em'}}
          onClick={handleClick}
          >
          {t('Order.out_of_stock')}
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label={t("Order.sure")}>
          <DialogTitle>
          {t('Order.mark_out_of_stock')}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
            {t('Order.confirmation_out_of_stock', { name: record.name })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              color="primary"
              variant="contained"
            >
              {t('Order.cancel')}
            </Button>
            <Button
              className={classnames(
                'ra-delete-button',
                classes.deleteButton
              )}
              style={{marginRight : '1em'}}
              onClick={handleOutOfStock}
            >
              {t('Order.continue')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(
    null,
    {showNotification, push}
  )
)(OutOfStockButton)
