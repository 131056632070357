const addIntegrations = requestHandler => (type, resource, params) => {
    if (type === 'UPDATE' && resource === 'profile' && params.data.business_id && params.data.import) {
        const jwt = localStorage.getItem('token')
        const id = localStorage.getItem('venue')
        fetch(`${window._env_.REACT_APP_API_URL}/integrations/${id}/iKentoo/menus/${params.data.business_id}`, {
            method: 'PUT',
            headers: new Headers({ 'Authorization': `Bearer ${jwt}` })
        }).then(response =>
            console.log(response)
        )
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params)
};

export default addIntegrations