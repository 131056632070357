import React, { useState } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import classNames from "classnames";

import { modes } from "../../reducers/styles";
import {
  cancelSaveCurrentStyle,
  changeMode,
  changeSelector,
  changeStyle,
  saveCurrentStyles,
  getCustomStyles,
  changePreviewFormat,
  showHiddenElements,
  resetAllStyles,
} from "../../actions/styles";

import { t } from "../../i18n";
import { useEffect } from "react";
import { NONE } from "./constants";
import FontUpload from "./FontUpload";

const styles = (theme) => ({
  toolbar: {
    boxShadow: "0px 1px 1px rgba(0,0,0,0.1)",
    zIndex: 1000,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
  },
});

const CustomizationToolbar = ({ venueId, ...props }) => {
  const { classes } = props;
  const {
    mode,
    currentStyles,
    changeMade,
    changeMode,
    saveCurrentStyles,
    cancelSaveCurrentStyle,
    getCustomStyles,
    previewFormat,
    changePreviewFormat,
    showHiddenElements,
    resetAllStyles,
  } = props;

  const [value, setValue] = useState();
  const [property, setProperty] = useState();
  const [hiddenElementsVisible, setHiddenElementsVisible] = useState(true);

  const [openResetDialog, setOpenResetDialog] = useState(false);

  const handleSaveCurrentStyles = () => {
    saveCurrentStyles(venueId, currentStyles);
  };

  const handleOpenResetDialog = () => {
    setOpenResetDialog(true);
  };

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  const handleResetAllStyles = () => {
    setOpenResetDialog(false);
    resetAllStyles(venueId);
  };

  const handleShowOrHideHiddenElements = (e) => {
    e.preventDefault();
    const hidden = Object.entries(currentStyles)
      .map(([style, value]) => ({ style, value }))
      .filter((element) => Object.values(element.value).includes(NONE));

    if (hidden) {
      showHiddenElements(hidden, hiddenElementsVisible);
    }
    setHiddenElementsVisible(!hiddenElementsVisible);
  };

  // const [day, setDay] = useState("");
  // const [hour, setHour] = useState("");

  useEffect(() => {
    changeMode(modes.NO_MODE);
  }, []);

  const handleCancelSaveCurrentStyles = () => {
    setProperty("");
    setValue("");
    cancelSaveCurrentStyle();
    getCustomStyles(venueId);
  };

  return (
    <Toolbar className={classNames(classes.toolbar)} disableGutters>
      <div style={{ display: "flex", padding: "0 15px" }}>
        <Button
          aria-label='toggle select mode'
          color='primary'
          variant={mode === modes.SELECT ? "contained" : "outlined"}
          onClick={mode === modes.SELECT ? () => changeMode(modes.NO_MODE) : () => changeMode(modes.SELECT)}
          style={{ marginRight: 15 }}
        >
          <EditIcon fontSize='inherit' />
        </Button>
        <Select variant='outlined' value={previewFormat} onChange={(e) => changePreviewFormat(e.target.value)} style={{ marginRight: 15 }}>
          <MenuItem key={`format-Iphone-8`} value={`Iphone 8`}>
            iPhone 8
          </MenuItem>
          <MenuItem key={`format-Iphone-X`} value={`Iphone X`}>
            iPhone X
          </MenuItem>
        </Select>

        {/* Select date and time of visualization */}
        {/* <Select value={day} onChange={(e) => setDay(e.target.value)} style={{ marginRight: 15 }}>
          {DAYS.map((day, index) => (
            <MenuItem key={`day-${day}-${index}`} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
        <Select value={hour} onChange={(e) => setHour(e.target.value)} style={{ marginRight: 15 }}>
          {HOURS.map((hour, index) => (
            <MenuItem key={`hour-${hour}-${index}`} value={`${hour}`}>
              {typeof hour === "number" ? `${hour} h` : `${hour}`}
            </MenuItem>
          ))}
        </Select> */}
        <FontUpload venueId={venueId} />
        <Button
          variant={hiddenElementsVisible ? "outlined" : "contained"}
          color='primary'
          style={{ marginRight: 15 }}
          onClick={(e) => handleShowOrHideHiddenElements(e)}
        >
          {t("Customization.hiddenElements")}
        </Button>
        <Button
          variant='outlined'
          style={{ color: !currentStyles ? "" : "red", border: !currentStyles ? "" : "1px solid red" }}
          onClick={() => handleOpenResetDialog()}
          disabled={!currentStyles}
        >
          <RotateLeftIcon color='inherit' />
        </Button>
        <Dialog
          open={openResetDialog}
          onClose={handleCloseResetDialog}
          
        >
          <DialogTitle id='reset-dialog-title'>{t("Customization.resetAllStyles")}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {t("Customization.areYouSure")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseResetDialog} color='primary'>
            {t("Customization.cancel")}
            </Button>
            <Button onClick={handleResetAllStyles} color='primary' autoFocus>
            {t("Customization.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ display: "flex", padding: "0 15px" }}>
        <Button variant='contained' color='primary' onClick={() => handleSaveCurrentStyles()} style={{ marginRight: 15 }} disabled={!changeMade}>
          {t("Customization.save")}
        </Button>

        <Button
          variant='outlined'
          style={{ color: !changeMade ? "" : "red", border: !changeMade ? "" : "1px solid red" }}
          onClick={() => handleCancelSaveCurrentStyles()}
          disabled={!changeMade}
        >
          {t("Customization.cancel")}
        </Button>
      </div>
    </Toolbar>
  );
};

function mapStateToProps(state) {
  return {
    mode: state.styles.mode,
    selector: state.styles.selector,
    currentSelector: state.styles.currentSelector,
    currentStyles: state.styles.currentStyles,
    changeMade: state.styles.changeMade,
    previewFormat: state.styles.previewFormat,
  };
}

export default connect(
  mapStateToProps,
  {
    changeMode,
    changeSelector,
    changeStyle,
    saveCurrentStyles,
    cancelSaveCurrentStyle,
    getCustomStyles,
    changePreviewFormat,
    showHiddenElements,
    resetAllStyles,
  }
)(withStyles(styles)(CustomizationToolbar));
