import {Howl, Howler} from 'howler';

import bellMp3 from './media/bell.mp3';
import errorMp3 from './media/error.mp3';

const sounds = {
  'update' : bellMp3,
  'cancel' : errorMp3
};


class AudioNotifications {
  constructor() {
    this.queue   = [];
    this.playing = false;
    this.id = Math.floor(Math.random()*100);
  }

  update() {
    if (this.queue[0] === 'update') {
      // Avoid playing same signal more than one time in a row
      return;
    }

    this.queue.unshift('update');

    this.play();
  }

  cancel() {
    if (this.queue[0] === 'cancel') {
      // Avoid playing same signal more than one time in a row
      return;
    }

    this.queue.unshift('cancel');

    this.play();
  }

  play() {
    if (this.playing) {return;}

    this.playing = true;

    const player = () => {
      const next = this.queue[this.queue.length - 1];

      if (!next) {
        this.playing = false;
        return;
      }

      const sound = new Howl({src: [sounds[next]]});
      const onEnd = () => {
        /*
          Remove element from queue only when the sound finished playing.
          Sound is async, if queue is popped at the beginning of play,
          another sound of the same type could be queued since queue is empty.
         */
        this.queue.pop();
        player();
      };

      sound.on('end', onEnd);
      sound.play();
    };

    player();
  }
}

export default AudioNotifications;
