import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MUITextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import Collapse from '@material-ui/core/Collapse'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { TextField } from "react-admin"
import decodeJwt from 'jwt-decode'
import { t } from '../../i18n'

const cardStyle = {
    width: 350,
    minHeight: 300,
    margin: '2.0em',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'pre-line',
    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        fontSize: 40,
        fontWeight: 'bold',
        color: 'white'
    },
    header1: {
        height: 200,
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgb(2, 0, 36)',
        background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)'
    },
    header2: {
        height: 200,
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgb(2, 0, 36)',
        background: 'linear-gradient(to right top, #ff0000, #ff6300, #ff9600, #f7c100, #ebe812)'
    },
    button: {
        fontWeight: 'bold',
        width: 150,
        margin: 10
    }
}

const OffersGrid = ({ ids, data, basePath, info }) => {
    const [currentOffer, setCurrentOffer] = useState(9999)
    const [nextDebit, setNextDebit] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [discount, setDiscount] = useState({})
    const [checked, setChecked] = useState(false)
    const [showPaymentMethodPicker, setShowPaymentMethodPicker] = useState(false)
    const [pickedOffer, setPickedOffer] = useState(null)
    const [useDiscount, setUseDiscount] = useState(false)
    const [preferredMethod, setPreferredMethod] = useState(null)
    const [end, setEnd] = useState(null)
    const [paymentMethods, setPaymentMethods] = useState([])

    const jwt = localStorage.getItem('token')
    const venue = localStorage.getItem('venue')

    const relog = offerId => {
        const jwt = localStorage.getItem('token')
        const decoded = decodeJwt(jwt)
        const uuid = decoded.id
        fetch(new Request(`${window._env_.REACT_APP_API_URL}/auth/token`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }),
            body: JSON.stringify({ uuid: uuid })
        }))
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(({ jwt }) => {
                localStorage.setItem('token', jwt)
                localStorage.setItem('offer', offerId)
                window.location.reload()
            })
    }

    const subscribe = (offerId, method, IBAN, BIC) => {
        if (!checked) {
            alert('Merci de lire et accepter les condtions générales d\'utilisation ainsi que la politique de confidentialité.')
            return
        }
        setPickedOffer(offerId)

        if (!showPaymentMethodPicker && !preferredMethod) {
            setShowPaymentMethodPicker(true)
            return
        }

        const jwt = localStorage.getItem('token')
        const venue = localStorage.getItem('venue')

        const decoded = decodeJwt(jwt)
        const uuid = decoded.id
        const paymentMethod = preferredMethod || method

        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/subscribe/${paymentMethod}`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }),
            body: JSON.stringify({ uuid: uuid, offerId: offerId, IBAN: IBAN, BIC: BIC })
        }))
            .then(response => response.json())
            .then(json => {
                // Check if we have an error with required information
                if (json.reason && json.reason === "missing billing information") {
                    alert('Informations de facturation manquantes (adresse et/ou raison sociale).')
                }

                // If we receive a canceled status, it means we just canceled an already active subscription.
                // Call this function again to proceed with a new subscription
                // Since we necessarily have a valid mandate for payments, skip the payment method choice
                if (json.status && json.status === 'canceled') {
                    subscribe(offerId, IBAN, BIC)
                }
                // Redirect to payment url if appropriate
                if (json.redirectUrl) {
                    window.location.replace(json.redirectUrl)
                }

                if (json.refresh && json.refresh === true) {
                    relog(offerId)
                }
            })
    }

    const subscribeWithDiscount = (offerId, method, IBAN, BIC) => {
        if (!checked) {
            alert('Merci de lire et accepter les condtions générales d\'utilisation ainsi que la politique de confidentialité.')
            return
        }
        setUseDiscount(true)
        setPickedOffer(offerId)
        const defaultMethod = !!discount.free_test ? 'free' : null

        if (!showPaymentMethodPicker && !preferredMethod && !defaultMethod) {
            setShowPaymentMethodPicker(true)
            return
        }

        const jwt = localStorage.getItem('token')
        const venue = localStorage.getItem('venue')

        const decoded = decodeJwt(jwt)
        const uuid = decoded.id
        const paymentMethod = defaultMethod || preferredMethod || method

        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/subscribe/${paymentMethod}/discount`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }),
            body: JSON.stringify({ uuid: uuid, offerId: offerId, IBAN: IBAN, BIC: BIC })
        })).then(response => response.json())
            .then(json => {
                // Check if we have an error with required information
                if (json.reason && json.reason === "missing billing information") {
                    alert('Informations de facturation manquantes (adresse et/ou raison sociale).')
                }
                // If we receive a canceled status, it means we just canceled an already active subscription.
                // Call this function again to proceed with a new subscription
                // Since we necessarily have a valid mandate for payments, skip the payment method choice
                if (json.status && json.status === 'canceled') {
                    subscribeWithDiscount(offerId, IBAN, BIC)
                }
                // Redirect to payment url if appropriate
                if (json.redirectUrl) {
                    window.location.replace(json.redirectUrl)
                }

                if (json.refresh && json.refresh === true) {
                    relog(offerId)
                }
            })
    }

    const cancelSubscription = offerId => {
        const jwt = localStorage.getItem('token')
        const venue = localStorage.getItem('venue')

        const decoded = decodeJwt(jwt)
        const uuid = decoded.id

        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/cancel`, {
            method: 'PUT',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }),
            body: JSON.stringify(offerId)
        })).then(response => response.json())
            .then(json => {
                // Relog the user upon cancelation to refresh their token/permissions
                if (json.status && json.status === 'canceled') {
                    fetch(new Request(`${window._env_.REACT_APP_API_URL}/auth/token`, {
                        method: 'PUT',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }),
                        body: JSON.stringify({ uuid: uuid })
                    }))
                        .then(response => {
                            if (response.status < 200 || response.status >= 300) {
                                throw new Error(response.statusText)
                            }
                            return response.json()
                        })
                        .then(({ jwt }) => {
                            localStorage.setItem('token', jwt)
                            localStorage.removeItem('offer')
                            window.location.reload()
                        })
                }
            })
    }

    const getDiscount = venue => {
        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/discount`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            })
        }))
            .then(response => { return response.json() })
            .then(json => {
                // Check if there was an offer update and refresh user token accordingly
                if (json.offer) { setDiscount(json) }
            })
    }

    const pickedSepa = (offerId, IBAN, BIC) => {
        useDiscount ? subscribeWithDiscount(offerId, 'sepa', IBAN, BIC) : subscribe(offerId, 'sepa', IBAN, BIC)
        setShowPaymentMethodPicker(false)
    }

    const pickedCard = offerId => {
        useDiscount ? subscribeWithDiscount(offerId, 'creditcard', null, null) : subscribe(offerId, 'creditcard', null, null)
        setShowPaymentMethodPicker(false)
    }

    useEffect(() => {
        getDiscount(venue)
        if (info) {
            setCurrentOffer(info.id)
            if (info.end) {
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                let date = new Date(info.end)
                setEnd(date.toLocaleDateString('fr-FR', options).replace(/,/g, '').replace(/^\w/, c => c.toUpperCase()))
            }
            if (info.renewal) {
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                let date = new Date(info.renewal)
                setNextDebit(date.toLocaleDateString('fr-FR', options).replace(/,/g, '').replace(/^\w/, c => c.toUpperCase()))
            }
            if (info.mandates && info.mandates.length) {
                let mandate = info.mandates.find(m => m.default === true)
                if (mandate && mandate.method) {
                    let method = mandate.method === 'directdebit' ? 'sepa' : 'creditcard'
                    setPreferredMethod(method)
                }
                setPaymentMethods(info.mandates)
            }
        }
    }, [])

    const offerLabel = offer => {
        let name = ''
        switch (offer) {
            case 1:
                name = t('Offer.smart')
                break
            case 2:
                name = t('Offer.smart365')
                break
            case 3:
                name = t('Offer.premium')
                break
            case 4:
                name = t('Offer.premium365')
                break
            default:
                name = t('Offer.none')
                break
        }
        return t('Offer.current', { offer: name })
    }

    const CancelDialog = offerId =>
        <Dialog fullWidth open={showDialog} onClose={() => setShowDialog(false)} aria-label="Are you sure?">
            <DialogTitle>{t('Offer.cancel')}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{t('Offer.confirm')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialog(false)} color="primary">
                    {t('Offer.no')}
                </Button>
                <Button style={{ marginRight: '1em', color: 'red' }} onClick={() => cancelSubscription(offerId)}>
                    {t('Offer.yes')}
                </Button>
            </DialogActions>
        </Dialog>

    const PaymentMethodPicker = props => {
        const [expanded, setExpanded] = useState(false)
        const [IBAN, setIBAN] = useState(null)
        const [BIC, setBIC] = useState(null)

        return (
            <Dialog open={props.show} aria-label="Are you sure?">
                <DialogTitle>{t('Profile.payment_method')}</DialogTitle>
                <div>
                    <List>
                        <ListItem key="credicard" button onClick={() => { props.onCard() }}>
                            <ListItemAvatar>
                                <Avatar src="/creditcard.jpg" />
                            </ListItemAvatar>
                            <ListItemText primary={t('Profile.method_card')} />
                        </ListItem>
                        <ListItem key="sepa" button onClick={() => setExpanded(true)}>
                            <ListItemAvatar>
                                <Avatar src="/sepa.jpg" />
                            </ListItemAvatar>
                            <ListItemText primary={t('Profile.method_sepa')} />
                        </ListItem>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 30px 30px 30px' }}>
                                <MUITextField
                                    id="IBAN"
                                    label="IBAN"
                                    value={IBAN || ''}
                                    onChange={event => setIBAN(event.target.value)}
                                    margin="normal"
                                />
                                <MUITextField
                                    id="BIC"
                                    label="BIC"
                                    value={BIC || ''}
                                    onChange={event => setBIC(event.target.value)}
                                    margin="normal"
                                />
                                <Button color="primary" variant="extendedFab" style={{ marginRight: '1em', marginTop: '20px' }} onClick={() => { props.onSepa(IBAN, BIC) }}>
                                    {t('Profile.method_submit')}
                                </Button>
                            </div>
                        </Collapse>
                    </List>
                </div>
            </Dialog>
        )
    }

    const formatDate = date => {
        const dd = date.getDate()
        const mm = date.getMonth() + 1
        const YYYY = date.getFullYear()
        return dd + '/' + mm + '/' + YYYY
    }

    const PaymentMethodList = () => {
        const [open, setOpen] = useState(false)
        const [showNewPaymentMethodPicker, setShowNewPaymentMethodPicker] = useState(false)

        const addPaymentMethod = (IBAN, BIC) => {
            if (IBAN && BIC) {
                fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/addPaymentMethod`, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    }),
                    body: JSON.stringify({ IBAN: IBAN, BIC: BIC })
                }))
                    .then(response => window.location.reload())
            } else {
                fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/addPaymentMethod`, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    })
                }))
                    .then(response => response.json())
                    .then(json => {
                        // Redirect to payment url if appropriate
                        if (json.redirectUrl) {
                            window.location.replace(json.redirectUrl)
                        } else {
                            window.location.reload()
                        }
                    })

            }
        }

        const deletePaymentMethod = method => {
            if (paymentMethods.length === 1) {
                alert(t("Offer.payment_method_error_unique"))
                return
            }
            let paymentMethod = paymentMethods.find(p => p.id === method)
            if (paymentMethod.active) {
                alert(t("Offer.payment_method_error_default"))
                return
            }

            fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/deletePaymentMethod/${method}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                })
            }))
                .then(response => window.location.reload())
        }

        const updatePaymentMethod = method => {
            if (paymentMethods.filter(p => p.default === true).length === 0) {
                alert(t('Offer.payment_method_error_subscribe'))
                return
            }
            fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/selectPaymentMethod`, {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                }),
                body: JSON.stringify({ method: method })
            }))
                .then(response => window.location.reload())
        }

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'left', marginTop: '15px', width: '100%', cursor: 'pointer' }}>
                    <Typography variant="subheading" onClick={() => setOpen(!open)}>{t('Offer.payment_methods')}</Typography>
                    {open ? <ExpandLess onClick={() => setOpen(!open)} /> : <ExpandMore onClick={() => setOpen(!open)} />}
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div style={{ width: '100%', textAlign: 'right' }}><Button color="primary" onClick={() => setShowNewPaymentMethodPicker(true)}><AddIcon />{t('Offer.add_payment')}</Button></div>
                    <List>
                        {
                            paymentMethods.map(mandate =>
                            (<ListItem key={mandate.id}>
                                <ListItemAvatar>
                                    {mandate.method === "directdebit" ? <Avatar src="/sepa.jpg" /> : <Avatar src="/creditcard.jpg" />}
                                </ListItemAvatar>
                                {
                                    mandate.method === "directdebit" ?
                                        <ListItemText primary={t('Profile.method_sepa') + ' Nº ' + mandate.details.consumerAccount} />
                                        :
                                        <ListItemText primary={t('Profile.method_card') + ' Nº **** **** **** ' + mandate.details.cardNumber} />
                                }
                                {mandate.method === "creditcard" ? <ListItemText primary={t('Offer.payment_expires_on') + formatDate(new Date(mandate.details.cardExpiryDate))} /> : <div style={{ width: '130px' }} />}
                                <ListItem>
                                    <IconButton onClick={() => deletePaymentMethod(mandate.id)}><DeleteIcon /></IconButton>
                                </ListItem>
                                <ListItemSecondaryAction>
                                    <FormControlLabel control={<Switch onChange={() => updatePaymentMethod(mandate.id)} checked={mandate.default} />} label={t('Offer.payment_method_default')} />
                                </ListItemSecondaryAction>
                            </ListItem>))
                        }
                    </List>
                </Collapse>
                <PaymentMethodPicker show={showNewPaymentMethodPicker} onSepa={(IBAN, BIC) => addPaymentMethod(IBAN, BIC)} onCard={() => addPaymentMethod()} />
            </>
        )
    }

    return (
        <div>
            <Typography variant="title" style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}>{offerLabel(currentOffer)}</Typography>
            {
                nextDebit && currentOffer != 5 ?
                    <>
                        <Typography variant="subheading" style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}>{t('Offer.debit', { date: nextDebit })}</Typography>
                        <Typography variant="body2" style={{ width: '100%', textAlign: 'center' }}>{t('Offer.debit_cancel')}</Typography>
                    </>
                    :
                    null
            }
            {
                end ?
                    <Typography variant="subheading" style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}>{t('Offer.end', { date: end })}</Typography>
                    :
                    null
            }
            {paymentMethods.length > 0 ? <PaymentMethodList /> : null}
            <div style={{ margin: '1em' }}>
                {ids.map((id, i) =>
                    <Card key={id} style={cardStyle}>
                        <div style={i === 0 ? cardStyle.header1 : cardStyle.header2}>
                            <TextField style={cardStyle.title} record={data[id]} source="name" />
                        </div>
                        <CardContent>
                            <TextField style={{ fontSize: 16, lineHeight: 2 }} record={data[id]} source="body" />
                        </CardContent>
                        <CardActions style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {
                                data[id].offers.map((offer, i) =>
                                    <div key={offer.id}>
                                        {
                                            discount && discount.offer === offer.id ?
                                                <>
                                                    {
                                                        discount.free_test ?
                                                            <Button style={{ ...cardStyle.button, marginTop: "20px" }} variant="extendedFab" color="secondary" onClick={() => { subscribeWithDiscount(discount.offer) }}>{t('Discount.free')}</Button>
                                                            :
                                                            <Button style={{ ...cardStyle.button, marginTop: "20px" }} variant="extendedFab" color="secondary" onClick={() => { subscribeWithDiscount(discount.offer) }}>{t('Offer.discount')}</Button>
                                                    }
                                                    {
                                                        discount.free_test ?
                                                            <Typography variant="caption">{'soit ' + discount.duration + ' mois gratuits'}</Typography>
                                                            :
                                                            <Typography variant="caption">{'soit ' + discount.price + '€ / mois TTC pendant ' + discount.duration + ' mois puis ' + offer.fullPrice}</Typography>
                                                    }
                                                    <Typography variant="body1">ou</Typography>
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            i === 0 ?
                                                <div id={i}>
                                                    <Button style={cardStyle.button} variant="extendedFab" color="primary" onClick={() => { subscribe(offer.id) }} disabled={offer.id === currentOffer && !end}>{offer.price}</Button>
                                                    <Typography variant="caption">{'soit ' + offer.fullPrice}</Typography>
                                                    <Typography variant="body1">ou</Typography>
                                                </div>
                                                :
                                                <div id={i}>
                                                    <Button style={cardStyle.button} variant="extendedFab" color="primary" onClick={() => { subscribe(offer.id) }} disabled={offer.id === currentOffer && !end}>{offer.price}</Button>
                                                    <Typography variant="caption">{'soit ' + offer.fullPrice}</Typography>
                                                </div>
                                        }
                                        {
                                            offer.id === currentOffer && !end ?
                                                <>
                                                    <Button style={{ ...cardStyle.button, marginTop: "20px" }} variant="extendedFab" color="secondary" onClick={() => { setShowDialog(true) }}>{t('Offer.cancel')}</Button>
                                                    <CancelDialog offerId={offer.id} />
                                                    {currentOffer % 2 === 0 ? null : <Typography variant="body1">ou</Typography>}
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                )
                            }
                        </CardActions>
                    </Card>
                )}
                <div className="tandcs" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox checked={checked} onChange={() => setChecked(!checked)} value="checkedA" />
                    <Typography variant="body1">J'ai lu et j'accepte les <a href="/legal/TermsAndConditions.pdf" target="_blank">conditions générales d'utilisation</a> ainsi que la <a href="/legal/Confidentiality.pdf" target="_blank">politique de confidentialité</a>.</Typography>
                </div>
                <Typography variant="caption">*Des frais supplémentaires s'appliquent pour les options paiement et SMS. Merci de nous contacter pour plus de détails.</Typography>
            </div>
            <PaymentMethodPicker
                show={showPaymentMethodPicker}
                onSepa={(IBAN, BIC) => { pickedSepa(pickedOffer, IBAN, BIC) }}
                onCard={() => { pickedCard(pickedOffer) }} />
        </div>
    )
}

OffersGrid.defaultProps = {
    data: {
        1: {
            'name': 'Smart',
            'body': `• Menus, catégories et produits illimités
            • Scans illimités
            • Photos des produits + zoom
            • Intégration des réseaux sociaux
            • Traduction automatique
            • Disponibilité des produits paramétrable
            • Pricing dynamique
            • Option "Groupes"`,
            'offers': [
                {
                    'id': 1,
                    'price': '49€ / mois HT',
                    'fullPrice': '58.80€ / mois TTC'
                },
                {
                    'id': 2,
                    'price': '490€ / an HT',
                    'fullPrice': '588€ / an TTC'
                }
            ]
        },
        2: {
            'name': 'Premium',
            'body': `• Menus, catégories et produits illimités
            • Scans illimités
            • Photos des produits + zoom
            • Intégration des réseaux sociaux
            • Traduction automatique
            • Disponibilité des produits paramétrable
            • Pricing dynamique
            • Option "Groupes"
            • Commande au bar
            • Service à table
            • Frais paramétrables
            • Paiement en ligne*
            • Notification SMS*`,
            'offers': [
                {
                    'id': 3,
                    'price': '99€ / mois HT',
                    'fullPrice': '118.80€ / mois TTC'
                },
                {
                    'id': 4,
                    'price': '990€ / an HT',
                    'fullPrice': '1188€ / an TTC'
                }
            ]
        }
    },
    ids: [1, 2],
}

export default props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <OffersGrid info={props.info} />
        </div>
    )
}
