import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import { changeSelector, changeStyle, getCustomStyles, changeMode } from "../../actions/styles";

import { connect } from "react-redux";
import { APPERO_WEB_SELECTOR } from "./constants";

import Selectors from "./Selectors";
import { t } from "../../i18n";

import GeneralCustomization from "./GeneralCustomization";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { modes } from "../../reducers/styles";

const styles = (theme) => ({
  pannel: {
    display: "flex",
    flexDirection: "column",
    width: "40vw",
    alignItems: "center",
    justifyContent: "start",
    borderRadius: 20,
  },
});
const ToolPanel = ({ venueId, ...props }) => {
  const { classes } = props;
  const { selector, changeSelector, changeStyle, getCustomStyles, receivedCustomStyles, changeMode, mode, reset } = props;

  const [value, setValue] = useState();
  const [property, setProperty] = useState();
  const [waitCustomStyles, setWaitCustomStyles] = useState(true);

  useEffect(
    () => {
      window.addEventListener("message", handleMessage);
      getCustomStyles(venueId);

      // If not custom styles are defined stop waiting

      setTimeout(() => {
        setWaitCustomStyles(false);
      }, 2000);

      return () => {
        window.removeEventListener("message", handleMessage);
      };
    },
    []
  );

  const [tabValue, setTabValue] = useState(0);

  useEffect(
    () => {
      if (mode === modes.SELECT) {
        setTabValue(1);
      }
      if (mode === modes.NO_MODE) {
        setTabValue(0);
      }
    },
    [mode]
  );

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    if (newValue) {
      changeMode(modes.SELECT);
    } else {
      changeMode(modes.NO_MODE);
    }
  };

  const handleMessage = (event) => {
    if (event.data.type === APPERO_WEB_SELECTOR) {
      changeSelector(event.data.selector);
    }
  };
  const handleChangeStyle = (value, inputProperty, inputPerimeter) => {
    setValue(value);

    changeStyle(inputPerimeter, inputProperty, value);
  };

  return (
    <div className={classNames(classes.pannel)}>
      <Tabs value={tabValue} indicatorColor='primary' textColor='primary' onChange={handleChangeTab} aria-label='tabs'>
        <Tab label={t("Customization.generic")} />
        <Tab label={t("Customization.selected")} />
      </Tabs>
      {(receivedCustomStyles || !waitCustomStyles) && !reset && selector && tabValue ? (
        <Selectors selector={selector} handleChangeStyle={handleChangeStyle} value={value} setProperty={setProperty} />
      ) : (receivedCustomStyles || !waitCustomStyles) && !reset && !tabValue ? (
        <GeneralCustomization handleChangeStyle={handleChangeStyle} />
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    mode: state.styles.mode,
    selector: state.styles.selector,
    currentSelector: state.styles.currentSelector,
    currentStyles: state.styles.currentStyles,
    receivedCustomStyles: state.styles.receivedCustomStyles,
    reset: state.styles.reset,
  };
}

export default connect(
  mapStateToProps,
  { changeSelector, changeStyle, getCustomStyles, changeMode }
)(withStyles(styles)(ToolPanel));
