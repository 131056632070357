import React from 'react'
import { Toolbar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import OrderForm from './OrderForm'
import PasswordField from './PasswordField'
import OrderPaidButton from '../../action-buttons/OrderPaidButton'
import { t } from '../../../i18n'

const ReadyEditToolbar = (props) =>
  <Toolbar {...props} >
    <OrderPaidButton destination="/delivery"/>
  </Toolbar>

class RestaurantReadyOrderForm extends React.Component {
  render() {
    return (
      <OrderForm
        {...this.props}
        subheading={
          () =>
            <Typography component="h3" variant="subheading">
              {t('Order.prepared')}
            </Typography>
        }
        toolbar={ReadyEditToolbar}
        extra={PasswordField} />
    )
  }
}

export default RestaurantReadyOrderForm
