import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  DisabledInput,
  TextInput,
  LongTextInput,
  TextField,
  ArrayInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SimpleFormIterator,
  SelectInput,
  BooleanInput,
  NumberInput,
  required,
} from 'react-admin'
import { SortableArrayInput } from '../SortableArrayInput'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

export default (props) =>
  <Edit title={t('Category.edit')} undoable={false} {...props}>
    <TabbedForm redirect="list">
      <FormTab label={t('Product.form_general')}>
        <TextInputField source='name' label={t('Category.name')} validate={true} />
        <TextInputField source="tagline" label={t('Category.tagline')}  />
        <ReferenceArrayInput source="sections" label={t('Category.menus')} reference="section" style={{ width: '100%' }}>
          <SelectArrayInput source="name" optionText="name" style={{ width: '100%' }} />
        </ReferenceArrayInput>
        <BooleanInput source="unavailable" label={t('Category.unavailable')} />
      </FormTab>
      <FormTab label={t('Category.exceptions')}>
        <ArrayInput source="exceptions" label={t('Product.exceptions')} fullWidth>
          <SimpleFormIterator>
            <FormDataConsumer>
              {
                ({ formData, getSource }) => {
                  return (
                    <>
                      <ReferenceInput label={t('Calendar.calendars')} source={getSource("calendar_id")} reference="calendar" validate={required()}>
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                      <div style={{ display: 'flex', width: '100%', alignItems: 'baseline' }}>
                        <NumberInput label={t('Category.position')} source={getSource("position")} defaultValue={formData.position} style={{ marginRight: 50 }} />
                        <BooleanInput source={getSource("unavailable")} label={t('Category.unavailable')} defaultValue={formData.unavailable} />
                      </div>
                    </>
                  )
                }
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t('Product.translations')}>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.name) {
                return (<DisabledInput label={t('Category.name')} source="name" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.tagline) {
                return (<DisabledInput label={t('Category.tagline')} source="tagline" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <ArrayInput label={t('Category.translations')} source="languages" style={{ width: '100%' }}>
          <SimpleFormIterator>
            <TextInput source="name" label={t('Category.name')} style={{ width: '100%' }} />
            <LongTextInput source="tagline" label={t('Category.tagline')} />
            <SelectInput source="locale" label={t('Category.language')} choices={locales} style={{ width: '100%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t('Product.title')}>
        <SortableArrayInput source="products" reference="product" style={{ width: '100%' }} />
      </FormTab>
    </TabbedForm>
  </Edit>