import React from 'react'
import {
	Show,
	SimpleShowLayout,
	ImageField,
	TextField,
	NumberField,
	BooleanField,
	ArrayField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	SelectField,
	Datagrid,
	Labeled
} from 'react-admin'
import { t } from '../../i18n'

const BundleField = ({ record, ...rest }) => record && record.type_id === 2 &&
	<Labeled label={t('Category.subcategories')}>
		<ArrayField source="bundle_categories" record={record} {...rest}>
			<Datagrid>
				<TextField source='name' label={t('Category.name')} />
				<BooleanField source="multiple_choice" label={t('Product.multiple_choice')} />
				<ArrayField label={t('Product.title')} source="products">
					<Datagrid>
						<TextField source='name' label={t('Category.name')} />
						<BooleanField source="multiple_choice" label={t('Product.multiple_choice')} />
						<ArrayField source="id" reference="bundle-products" label=" ">
							<SingleFieldList>
								<ChipField source="name" />
							</SingleFieldList>
						</ArrayField>
						<BooleanField label={t('Product.custom_price')} source="custom_price" />
						<NumberField label={t('Product.incl_tax_price')} source="net_price" />
					</Datagrid>
				</ArrayField>
			</Datagrid>
		</ArrayField>
	</Labeled>

const GroupField = ({ record, ...rest }) => record && record.type_id === 3 &&
	<Labeled label={t('Product.title')}>
		<ReferenceArrayField source="products" reference="bundle-products" record={record} {...rest}>
			<SingleFieldList>
				<ChipField source="name" />
			</SingleFieldList>
		</ReferenceArrayField>
	</Labeled>

export default ({ record, permissions, ...props }) => {

	return (
		<Show title={t('Product.edit')} {...props}>
			<SimpleShowLayout>
				<SelectField source="type_id" choices={[
					{ id: 1, name: t('Product.type_single') },
					{ id: 2, name: t('Product.type_bundle') },
					{ id: 3, name: t('Product.type_set') }
				]} />
				<ImageField source="image" title="title" />
				<TextField source='name' label={t('Product.name')} />
				<TextField source="recipe" label={t('Product.recipe')} />
				<ReferenceArrayField source="categories" reference="category" label={t('Product.categories')}>
					<SingleFieldList>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
				<NumberField label={t('Product.incl_tax_price')} source="net_price" />
				<NumberField label={t('Product.tax_rate')} source="tax_rate" />
				<BundleField />
				<GroupField />
				<BooleanField source="unavailable" label={t('Product.unavailable')} />
				<BooleanField source="standalone" label={t('Product.standalone')} />
				<ReferenceArrayField source="recommendations" reference="recommended" label={t('Product.recommendations')}>
					<SingleFieldList>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
			</SimpleShowLayout>
		</Show >
	)
}
