import React from 'react'
import {
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    NumberInput,
    FormDataConsumer,
    BooleanInput,
    required
} from 'react-admin'
import { t } from '../../i18n'

export default (props) =>
    <Create title={t('Category.create')} {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput source="venue" reference="venue" perPage={500} label={t('Discount.venue')} validate={required()}>
                <SelectInput source="id" optionText="name" />
            </ReferenceInput>
            <SelectInput source="offer" label={t('Discount.apply')} choices={[
                { id: 1, name: 'Smart Mensuelle' },
                { id: 2, name: 'Smart Annuelle' },
                { id: 3, name: 'Premium Mensuelle' },
                { id: 4, name: 'Premium Annuelle' },
            ]} validate={required()} defaultValue={1} />
            <BooleanInput source="free_test" label={t('Discount.free')} />
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (!!formData.free_test === false) {
                  return (<NumberInput source="price" label={t('Discount.price')} validate={required()} />)
                }
              }}
            </FormDataConsumer>
            <NumberInput source="duration" label={t('Discount.duration_months')} validate={required()} />
        </SimpleForm>
    </Create>