import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { t } from "../../i18n";

const TimeslotPopup = ({
  open,
  onClose,
  onChange,
  initialValues,
  showDelete,
  onDelete,
}) => {
  const [values, setValues] = useState();
  const [formAction, setFormAction] = useState()



  useEffect(() => {
      setValues(initialValues);
    },
    [open, initialValues]
  );

  const beginTime =
    `${initialValues.beginHour}`.padStart(2, "0") +
    ":" +
    `${initialValues.beginMinute}`.padStart(2, "0");

  const endTime =
    `${initialValues.endHour}`.padStart(2, "0") +
    ":" +
    `${initialValues.endMinute}`.padStart(2, "0");


    const submit = (e) => {
      e.preventDefault()
  
      switch (formAction) {
        case 'create':
            handleCreate()
          break;
          case 'delete':
            handleDelete()
          break;
        default:
          break;
      }
    }


  const handleBeginChange = (e) => {
    const [hours, minutes] = e.target.value.split(":");
    setValues({
      ...values,
      beginHour: parseInt(hours),
      beginMinute: parseInt(minutes),
    });
  };

  const handleEndChange = (e) => {
    const [hours, minutes] = e.target.value.split(":");
    setValues({
      ...values,
      endHour: parseInt(hours),
      endMinute: parseInt(minutes),
    });
  };

  const handleCreate = (e) => {
    const begin = values.beginHour * 60 + values.beginMinute;
    const end = values.endHour * 60 + values.endMinute;
    if (begin >= end) {
      return;
    }
    onChange(values);
    onClose();
  };

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      id="popup"
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
        {t('Calendar.new_timeslot')}
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "flex", flexDirection: "column" }} onSubmit={submit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <TextField
              style={{ marginRight: 20 }}
              id="start-time"
              label={t('Calendar.start_time_label')}
              type="time"
              defaultValue={beginTime}
              onChange={(e) => handleBeginChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, // 1 min
              }}
              error={values? values.beginHour * 60 + values.beginMinute > values.endHour * 60 + values.endMinute :  false}
            />
            <TextField
              style={{}}
              id="end-time"
              label={t('Calendar.end_time_label')}
              type="time"
              defaultValue={endTime}
              onChange={(e) => handleEndChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, // 1 min
              }}
              error={values? values.beginHour * 60 + values.beginMinute > values.endHour * 60 + values.endMinute :  false}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            {showDelete ? (
              <Button style={{color:'red'}} type='submit' onClick={() => setFormAction('delete')}>
                {t("Calendar.delete")}
              </Button>
            ) : null}
            <Button color="primary" onClick={onClose} style={{marginRight:20}}>
              {t("Calendar.cancel")}
            </Button>
            <Button variant="contained" color="primary" type='submit' onClick={() => setFormAction('create')}>
              {t("Calendar.create")}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TimeslotPopup;
