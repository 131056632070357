import React from 'react'
import {
  Create,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  TextInput,
  LongTextInput,
  ImageInput,
  ImageField,
  ArrayInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  FormDataConsumer,
  DisabledInput,
  minValue,
  number,
  required,
  AutocompleteArrayInput,
  AutocompleteInput
} from 'react-admin'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

export const ProductCreate = ({ permissions, ...props }) =>
  <Create title={t('Product.create')} {...props}>
    <TabbedForm redirect="list">
      <FormTab label={t('Product.form_general')}>
        <SelectInput source="type_id" choices={[
          { id: 1, name: t('Product.type_single') },
          { id: 2, name: t('Product.type_bundle') },
          { id: 3, name: t('Product.type_set') }
        ]} validate={required()} style={{ width: '100%' }} />
        <ImageInput source="pictures" label={t('Product.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Product.error_image')) }} placeholder={<p>{t('Product.tip_image')}</p>} multiple={false}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInputField source='name' label={t('Product.name')} validate={true} />
        <TextInputField source="recipe" label={t('Product.recipe')} />
        {permissions && permissions.premium && <ReferenceArrayInput label={t('Product.instructions')} source="instructions" reference="instruction">
          <SelectArrayInput source="id" optionText="name" />
        </ReferenceArrayInput>}
        <ReferenceArrayInput source="categories" label={t('Product.categories')} reference="category" style={{ width: '100%' }}>
          <SelectArrayInput source="id" optionText="name" style={{ width: '100%' }} />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.type_id === 1 || formData.type_id === 2) {
              return (
                <>
                  <NumberInput label={t('Product.incl_tax_price')} source="net_price" defaultValue={1} validate={[required(), minValue(0)]} style={{ width: '100%' }} />
                  <NumberInput label={t('Product.tax_rate')} format={v => v && v * 100} parse={v => v && v / 100} source="tax_rate" style={{ width: '100%' }} />
                  <FormDataConsumer>
                    {
                      ({ formData, ...rest }) =>
                        <DisabledInput
                          label={t('Product.excl_tax_price')}
                          source="price_ht"
                          format={v => Math.round(v * 100) / 100}
                          defaultValue={formData.net_price / (formData.tax_rate + 1)}
                          style={{ width: '100%' }}
                        />
                    }
                  </FormDataConsumer>
                </>
              )
            }
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.type_id === 2) {
                return (
                  <ArrayInput label={t('Category.subcategories')} source="bundle_categories" style={{ width: '100%' }} validate={required()}>
                    <SimpleFormIterator>
                      <TextInput source="name" label={t('Category.name')} validate={required()} style={{ width: '100%' }} />
                      <BooleanInput source="multiple_choice" label={t('Product.multiple_choice')} style={{ width: '100%' }} />
                      <ArrayInput label={t('Product.title')} source="products" validate={required()}>
                        <SimpleFormIterator>
                          <ReferenceInput label=" " source="id" reference="bundle-products">
                            <AutocompleteInput optionText="name" style={{ width: '100%' }} />
                          </ReferenceInput>
                          <BooleanInput label={t('Product.custom_price')} source="custom_price" style={{ width: '100%' }} />
                          <FormDataConsumer>
                            {
                              ({ getSource, scopedFormData }) => {
                                if (scopedFormData && scopedFormData.custom_price) {
                                  return (
                                    <>
                                      <NumberInput label={t('Product.incl_tax_price')} source={getSource("net_price")} defaultValue={0} validate={number()} style={{ width: '100%' }} />
                                    </>
                                  )
                                }
                              }
                            }
                          </FormDataConsumer>
                        </SimpleFormIterator>
                      </ArrayInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              } else if (formData.type_id === 3) {
                return (
                  <ReferenceArrayInput label={t('Product.title')} source="products" reference="bundle-products" validate={required()} style={{ width: '100%' }}>
                    <AutocompleteArrayInput source="id" optionText="name" validate={required()} style={{ width: '100%' }} />
                  </ReferenceArrayInput>
                )
              } else {
                return null
              }
            }
          }
        </FormDataConsumer>
        <BooleanInput source="unavailable" label={t('Product.unavailable')} />
        <BooleanInput source="standalone" label={t('Product.standalone')} defaultValue={true} />
      </FormTab>
      <FormTab label={t('Product.recommendations')}>
          <ReferenceArrayInput label={t('Product.recommended')} source="recommendations" reference="recommended">
            <AutocompleteArrayInput source="id" optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label={t('Product.exceptions')}>
                  <ArrayInput source="exceptions" label={t('Product.exceptions')} fullWidth>
                    <SimpleFormIterator>
                    <FormDataConsumer>
            {
              ({formData, getSource}) => {
                return (
                    <>
                        <ReferenceInput label={t('Calendar.calendars')} source={getSource("calendar_id")} reference="calendar" validate={required()}>
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                        <div style={{display:'flex', width:'100%', alignItems:'baseline'}}>
                        {
                            (formData.type_id === 1 || formData.type_id === 2) && 
                             <NumberInput label={t('Product.incl_tax_price')} source={getSource("net_price")} defaultValue={formData.net_price} validate={number()} style={{marginRight:50}}  />
                        }
                          <NumberInput label={t('Product.position')} source={getSource("position")} defaultValue={formData.position} style={{marginRight:50}} />
                          <BooleanInput source={getSource("unavailable")} label={t('Product.unavailable')} defaultValue={formData.unavailable}/>
                        </div>
                      </>
                      )
              }
            }
          </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
        </FormTab>
      <FormTab label={t('Product.translations')}>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.name) {
                return (<DisabledInput label={t('Product.name')} source="name" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.recipe) {
                return (<DisabledInput label={t('Product.recipe')} source="recipe" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <ArrayInput label={t('Product.translations')} source="languages" style={{ width: '100%' }}>
          <SimpleFormIterator>
            <TextInput source="name" label={t('Product.name')} style={{ width: '100%' }} />
            <LongTextInput source="recipe" label={t('Product.recipe')} />
            <SelectInput source="locale" label={t('Product.language')} choices={locales} style={{ width: '100%' }} />
          </SimpleFormIterator>
        </ArrayInput>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.type_id === 2) {
                return (<ArrayInput label={t('Category.subcategories')} source="bundle_categories" style={{ width: '100%' }}>
                  <SimpleFormIterator>
                    <FormDataConsumer>
                      {
                        ({ getSource, scopedFormData }) => {
                          if (scopedFormData && scopedFormData.name) {
                            return (<DisabledInput label={t('Category.name')} source={getSource("name")} style={{ width: '100%' }} />)
                          }
                        }
                      }
                    </FormDataConsumer>
                    <ArrayInput label={t('Product.translations')} source="languages" style={{ width: '100%' }}>
                      <SimpleFormIterator>
                        <TextInput source="name" label={t('Product.name')} validate={required()} style={{ width: '100%' }} />
                        <SelectInput source="locale" label={t('Product.language')} choices={[
                          { id: 'en-GB', name: 'English' },
                          { id: 'es-ES', name: 'Español' },
                          { id: 'pt-PT', name: 'Português' },
                          { id: 'de-DE', name: 'Deutsch' },
                          { id: 'it-IT', name: 'Italiano' },
                          { id: 'ru-RU', name: 'русский' },
                          { id: 'jp-JP', name: '日本語' },
                          { id: 'cn-CN', name: '中文（简体)' },
                          { id: 'ar-AR', name: 'العربية' },
                          { id: 'he-IL', name: 'עברית' },
                        ]} style={{ width: '100%' }} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </SimpleFormIterator>
                </ArrayInput>)
              }
            }
          }
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>

export default ProductCreate
