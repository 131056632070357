import expiringStorage from '../../expiringStorage'

const formatDate = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
	.toISOString()
	.split("T")[0]
	.replace(/-/g, '')

export const getUniqueVisitors = (venue, interval) => {
	const id = localStorage.getItem('venue')
	let now = new Date()
	let end = formatDate(now)
	let remove = interval === 1 ? 365 : 7
	now.setDate(now.getDate() - remove)
	let start = formatDate(now)

	return getUniqueVisitorsInRange(venue, start, end, interval)
		.then(visitors => {
			if (visitors) {
				expiringStorage.set(`uv_${id}_${Math.abs(interval)}`, JSON.stringify(visitors), 21600000)
			}
			return Promise.resolve(visitors)
		})
}

export const getUniqueVisitorsInRange = (venue, start, end, interval) => {
	const id = localStorage.getItem('venue')
	const jwt = localStorage.getItem('token')

	let params = new URLSearchParams({
		start: start,
		end: end,
		interval: interval
	})

	let cached = JSON.parse(expiringStorage.get(`uv_${id}_${Math.abs(interval)}`))

	if (cached && cached.labels.includes(start) && cached.labels.includes(end)) {
		let from = cached.labels.indexOf(start)
		let to = cached.labels.indexOf(end)
		return { labels: cached.labels.slice(from, to), data: cached.data.slice(from, to) }
	}

	return fetch(new Request(`${window._env_.SWIFT_APP_ANALYTICS_URL}/user/session/${venue}?` + params, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jwt}`
		})
	}))
		.then(response => {
			switch (response.status) {
				case 200: return response.json()
				case 401: return Promise.reject()
				default: return null
			}
		})
		.then(data => {
			if (data && data.error) {
				return null
			}
			return data
		})
		.catch(error => {
			console.log(error)
			//return Promise.reject()
		})
}

export const getProductViews = (venue, interval) => {
	let now = new Date()
	now.setDate(now.getDate() - 1)
	let end = formatDate(now)
	let remove = interval === 1 ? 365 : 7
	now.setDate(now.getDate() - remove)
	let start = formatDate(now)

	return getProductViewsInRange(venue, start, end, interval)
		.then(data => {
			return Promise.resolve(data)
		})
}

export const getProductViewsInRange = (venue, start, end, interval) => {
	const jwt = localStorage.getItem('token')

	let params = new URLSearchParams({
		start: start,
		end: end
	})

	let url = `${window._env_.SWIFT_APP_ANALYTICS_URL}/product/view/daily/${venue}?` + params
	if (interval < 0) {
		url = `${window._env_.SWIFT_APP_ANALYTICS_URL}/product/view/hourly/${venue}?` + params
	}

	return fetch(new Request(url, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jwt}`
		})
	}))
		.then(response => {
			switch (response.status) {
				case 200: return response.json()
				case 401: return Promise.reject()
				default: return null
			}
		})
		.then(data => {
			if (data && data.error) {
				return null
			}
			return data
		})
		.catch(error => {
			console.log(error)
			//return Promise.reject()
		})
}

export const getUserLanguagesInRange = (venue, start, end) => {
	const jwt = localStorage.getItem('token')

	let params = new URLSearchParams({
		start: start,
		end: end,
		interval: 1
	})

	let url = `${window._env_.SWIFT_APP_ANALYTICS_URL}/user/language/${venue}?` + params

	return fetch(new Request(url, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jwt}`
		})
	}))
		.then(response => {
			switch (response.status) {
				case 200: return response.json()
				case 401: return Promise.reject()
				default: return null
			}
		})
		.then(data => {
			if (data && data.error) {
				return null
			}
			return data
		})
		.catch(error => {
			console.log(error)
			//return Promise.reject()
		})
}

export const getLinksInRange = (venue, start, end) => {
	const jwt = localStorage.getItem('token')

	let params = new URLSearchParams({
		start: start,
		end: end,
		interval: 1
	})

	let url = `${window._env_.SWIFT_APP_ANALYTICS_URL}/user/link/${venue}?` + params

	return fetch(new Request(url, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${jwt}`
		})
	}))
		.then(response => {
			switch (response.status) {
				case 200: return response.json()
				case 401: return Promise.reject()
				default: return null
			}
		})
		.then(data => {
			if (data && data.error) {
				return null
			}
			return data
		})
		.catch(error => {
			console.log(error)
			//return Promise.reject()
		})
}