import { call, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';

import { dataProvider } from '../clients/providers';

function* loadVenue({ payload : { venueId } }) {
  try {
    const { data } = yield call(dataProvider, 'GET_ONE', 'venue', {id : venueId});
    yield put({type : 'VENUE_LOADED', payload : {venue : data}});
  }
  catch(error) {
    yield put(showNotification(`Error: ${error.message}`, 'warning'));
  }
}

function* toggleVenueState({ payload : { state } }) {
  try {
    const { data } = yield call(dataProvider, 'UPDATE', 'open', {id:'', data : {open:state}});
    yield put({type : 'VENUE_STATE_UPDATED', payload : {state}});
  }
  catch(error) {
    yield put(showNotification(`Error: ${error.message}`, 'warning'));
  }
}

export default function* venueSaga() {
  yield takeEvery('LOAD_VENUE_REQUEST',         loadVenue);
  yield takeEvery('TOGGLE_VENUE_STATE_REQUEST', toggleVenueState);
}
