import createAuthProvider from './auth-provider';
import createDataProvider from './rest-provider';
import createRtProvider   from './rt-provider';
import addUploadFeature   from './addUploadFeature';
import addIntegrations    from './addIntegrations';

export const dataProvider = addIntegrations(addUploadFeature(createDataProvider(window._env_.REACT_APP_API_URL, window._env_.REACT_APP_STYLE_URL)));
export const authProvider = createAuthProvider(window._env_.REACT_APP_API_URL);
export const rtProvider   = createRtProvider(dataProvider);

