import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

import VenueCalendar from "./VenueCalendar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from '@material-ui/core/Typography';

import { t } from "../../i18n";

const EditCalendarButton = ({ record, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "calendarEdit-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        color="primary"
        onClick={handleClick}
      >
        <EditIcon />
        <Typography variant="body1" color="primary" style={{ paddingLeft: "10px" }}>{t("Calendar.edit")}</Typography>
      </Button>
      <Dialog id={id} open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <VenueCalendar close={handleClose} initialValue={record} type={type} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditCalendarButton;
