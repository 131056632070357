import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin'

export const PassphraseEdit = (props) =>
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="passphrase" />
    </SimpleForm>
  </Edit>

export default PassphraseEdit
