import React, { useState } from "react";
import TimeslotPopup from "./TimeslotPopup";
import { t } from '../../i18n'

const DAYS = [
  {
    key: "1",
    name: t('Calendar.monday'),
  },
  {
    key: "2",
    name: t('Calendar.tuesday'),
  },
  {
    key: "3",
    name: t('Calendar.wednesday'),
  },
  {
    key: "4",
    name: t('Calendar.thursday'),
  },
  {
    key: "5",
    name: t('Calendar.friday'),
  },
  {
    key: "6",
    name: t('Calendar.saturday'),
  },
  {
    key: "0",
    name: t('Calendar.sunday'),
  },
];

const HOURS = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

const Timetable = ({ timetable = {}, onChange, color }) => {
  const [openedEditPopup, setOpenedEditPopup] = useState(false);

  const handleSlotDelete = (day, index) => {
    onChange({
      ...timetable,
      [day]: (timetable[day] || []).filter((v, i) => i !== index),
    });
  };

  const handleSlotCreate = (day, values) => {

    // Push slots to delete into an array
    const beginNewSlot = values.beginHour * 60 + values.beginMinute;
    const endNewSlot = values.endHour * 60 + values.endMinute;
    let slotsToDelete = [];
    if (timetable[day] && timetable[day].forEach) {
      timetable[day].forEach((slot, index) => {
        const beginSlot = slot.beginHour * 60 + slot.beginMinute;
        const endSlot = slot.endHour * 60 + slot.endMinute;
        if (
          (beginSlot <= beginNewSlot && beginNewSlot <= endSlot) ||
          (beginSlot <= endNewSlot && endNewSlot <= endSlot) ||
          (beginSlot >= beginNewSlot && endNewSlot >= endSlot)
        ) {
          slotsToDelete.push(index);
        }
      });
    }
    
    onChange({
      ...timetable,
      [day]: [
        ...(timetable[day] || []).filter((v, i) => !slotsToDelete.includes(i)),
        values,
      ],
    });
  };

  const handleSlotChange = (day, index, values) => {

      // Push slots to delete into an array
    const beginNewSlot = values.beginHour * 60 + values.beginMinute;
    const endNewSlot = values.endHour * 60 + values.endMinute;
    let slotsToDelete = [];
    if (timetable[day] && timetable[day].forEach) {
      timetable[day].forEach((slot, i) => {
        const beginSlot = slot.beginHour * 60 + slot.beginMinute;
        const endSlot = slot.endHour * 60 + slot.endMinute;
        if (
          (beginSlot <= beginNewSlot && beginNewSlot <= endSlot) ||
          (beginSlot <= endNewSlot && endNewSlot <= endSlot) ||
          (beginSlot >= beginNewSlot && endNewSlot >= endSlot)
        ) {
          slotsToDelete.push(i);
        }
      });
    }
    
    const deleledSlots = slotsToDelete.filter(v => v!== index)
    
    onChange({
      ...timetable,
      [day]: (timetable[day] || []).map((v, i) => (i === index ? values : v)).filter((val, ind) => !deleledSlots.includes(ind)),
    });
  };

  const closeEditPopUp = () => {
    setOpenedEditPopup(false);
  };

  const timeSlot = (dayTable) => {
    const begin =
      ((dayTable.beginHour * 60 + dayTable.beginMinute) / (24 * 60)) * 100;
    const width =
      ((dayTable.endHour * 60 + dayTable.endMinute) / (24 * 60)) * 100 - begin;

    return { begin, width };
  };

  return (
    <div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" style={styles.tableHeadCol}>
              {HOURS.map((hour) => (
                <div
                  style={{
                    width: `${100 / HOURS.length}%`,
                    transform: "translateX(-50%)",
                  }}
                  key={hour}
                >
                  {`${hour}`}
                </div>
              ))}
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  transform: "translateX(50%)",
                }}
              >
                24
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {DAYS.map((day) => (
            <tr style={{ width: "100%", height: '35px' }} key={day.key}>
              <th style={styles.tableHeadRow}>{day.name}</th>

              <td
                className="calendarDinnerTable"
                onClick={() =>
                  setOpenedEditPopup({ type: "create", day: day.key })
                }
                style={{ width: "100%" }}
              >
                {timetable[day.key] ? (
                  timetable[day.key].map((slot, index) =>
                    slot ? (
                      <div key={index}>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: `${timeSlot(slot).begin}%`,
                            width: `${timeSlot(slot).width}%`,
                            backgroundColor: color,
                            height: "100%",
                            borderRadius: 5,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenedEditPopup({
                              type: "edit",
                              day: day.key,
                              index,
                              slot,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div />
                    )
                  )
                ) : (
                  <div />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TimeslotPopup
        open={openedEditPopup ? true : false}
        onClose={closeEditPopUp}
        initialValues={
          openedEditPopup && openedEditPopup.slot
            ? openedEditPopup.slot
            : {
                beginHour: 0,
                beginMinute: 0,
                endHour: 0,
                endMinute: 0,
              }
        }
        onChange={
          openedEditPopup && openedEditPopup.type === "edit"
            ? (values) => {
                handleSlotChange(
                  openedEditPopup.day,
                  openedEditPopup.index,
                  values
                );
                setOpenedEditPopup(false);
              }
            : (values) => {
                handleSlotCreate(openedEditPopup.day, values);
                setOpenedEditPopup(false);
              }
        }
        showDelete={
          openedEditPopup && openedEditPopup.type === "edit" ? true : false
        }
        onDelete={() =>
          handleSlotDelete(openedEditPopup.day, openedEditPopup.index)
        }
      />
    </div>
  );
};

const styles = {
  formContainer: {
    position: "relative",
    width: "90%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  closeBtn: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  input: {
    width: "100%",
    display: "flex",
  },
  table: {
    fontFamily: "Roboto, sans-serif",
  },
  tableHeadRow: {
    fontSize: 12,
    color: "#818181",
  },
  tableHeadCol: {
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-start",
    color: "#818181",
    position: "relative",
  },
};

export default Timetable;
