import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export const PassphraseCreate = (props) =>
  <Create {...props}>
    <SimpleForm redirect="list">
        <TextInput source="passphrase" />
    </SimpleForm>
  </Create>

export default PassphraseCreate
