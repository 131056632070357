import React from 'react'
import { Edit, SimpleForm, TextInput, BooleanInput, DisabledInput, Toolbar, SaveButton } from 'react-admin'
import { t } from '../../i18n'

const UserEditToolbar = props =>
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>;

export const UserEdit = (props) =>
  <Edit undoable={false} {...props}>
    <SimpleForm toolbar={<UserEditToolbar />} redirect="list">
        <DisabledInput source="id" />
        <TextInput source="firstname" label={t('User.firstname')} />
        <TextInput source="lastname" label={t('User.lastname')} />
        <DisabledInput source="email" label={t('User.email')} />
        <BooleanInput source="admin" label={t('User.admin')} />
        <DisabledInput source="newsletter" label={t('User.newsletter')} />
    </SimpleForm>
  </Edit>

export default UserEdit
