import {
  CHANGE_PREVIEW_FORMAT,
  RECEIVED_CUSTOM_STYLES,
  REQUEST_CUSTOM_STYLES,
  SAVE_CURRENT_STYLES_REQUEST,
  RESET_ALL_STYLES_REQUEST,
} from "../actions/styles";

export const STYLE_SAVED = "style-saved";
export const CHANGE_MODE = "change-mode";
export const CHANGE_SELECTOR = "change-selector";
export const CHANGE_STYLE = "change-style";
export const CHANGE_STYLE_PERIMETER = "change-style-perimeter";
export const CANCEL_SAVE_CURRENT_STYLE = "cancel-save-current-style";
export const RESTORE_STYLES = "restore-styles";
export const RESET_ALL_STYLES = "reset-all-styles";

export const modes = {
  NO_MODE: "no-mode",
  SELECT: "select",
  CHANGE: "change",
};

const stylesState = {
  mode: modes.NO_MODE,
  currentStyles: {},
  currentSelector: "",
  savedCustomStyles: {},
  changeMade: false,
  previewFormat: "Iphone 8",
  savedFonts: [],
  receivedCustomStyles: false,
  reset: false,
};

const stylesReducer = (state = stylesState, { type, payload }) => {
  switch (type) {
    case CHANGE_SELECTOR: {
      return {
        ...state,
        selector: payload.selector,
      };
    }
    case CHANGE_STYLE_PERIMETER: {
      return {
        ...state,
        currentSelector: payload.selector,
      };
    }
    case CHANGE_MODE: {
      return {
        ...state,
        mode: payload.mode,
        selector: "",
      };
    }
    case SAVE_CURRENT_STYLES_REQUEST: {
      return {
        ...state,
        changeMade: false,
        mode: modes.NO_MODE,
        selector: "",
        currentSelector: "",
      };
    }
    case RESET_ALL_STYLES_REQUEST:
      return {
        ...state,
        reset: true,
      };
    case RESET_ALL_STYLES: {
      return {
        ...state,
        currentSelector: "",
        selector: "",
        currentStyles: {},
        changeMade: false,
        reset: false,
      };
    }
    case CHANGE_STYLE: {
      return {
        ...state,
        changeMade: true,
        currentStyles: {
          ...state.currentStyles,
          [payload.selector]: {
            ...state.currentStyles[payload.selector],
            [payload.property]: payload.value,
          },
        },
      };
    }
    case CANCEL_SAVE_CURRENT_STYLE: {
      return {
        ...state,
        mode: modes.NO_MODE,
        changeMade: false,
        // currentStyles: {},
        selector: "",
        currentSelector: "",
      };
    }
    case RECEIVED_CUSTOM_STYLES: {
      return {
        ...state,
        currentStyles: payload.savedStyles,
        savedFonts: payload.savedFonts,
        receivedCustomStyles: true,
      };
    }
    case RESTORE_STYLES: {
      return {
        ...state,
        changeMade: true,
        currentStyles: {
          ...state.currentStyles,
          [payload.selector]: {},
        },
      };
    }
    case CHANGE_PREVIEW_FORMAT: {
      return {
        ...state,
        previewFormat: payload.format,
      };
    }
    default:
      return state;
  }
};

export default stylesReducer;
