import React from 'react'
import { Create, SimpleForm, ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin'
import { t } from '../../i18n'

const TableCreate = (props) =>
    <Create title={t('Table.create')} {...props}>
        <SimpleForm redirect="list">
            <ArrayInput source="tables" label={t('Venue.form_tables')}>
                <SimpleFormIterator>
                    <NumberInput source="number" label={t('Venue.table_number')} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>

export default TableCreate