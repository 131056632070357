import React from 'react'
import {
  Create,
  FormTab,
  TabbedForm,
  FormDataConsumer,
  DisabledInput,
  TextInput,
  LongTextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ImageInput,
  ImageField,
  BooleanInput,
  required,
  ReferenceInput
} from 'react-admin'
import { t } from '../../i18n'
import TextInputField from '../textInputField/TextInputField'

const locales = [
  { id: 'fr-FR', name: 'Français' },
  { id: 'en-GB', name: 'English' },
  { id: 'es-ES', name: 'Español' },
  { id: 'pt-PT', name: 'Português' },
  { id: 'it-IT', name: 'Italiano' },
  { id: 'de-DE', name: 'Deutsch' },
  { id: 'ru-RU', name: 'русский' },
  { id: 'jp-JP', name: '日本語' },
  { id: 'cn-CN', name: '中文（简体)' },
  { id: 'ar-AR', name: 'العربية' },
  { id: 'he-IL', name: 'עברית' },
]

export const SectionCreate = ({ permissions, ...props }) =>
  <Create title={t('Menu.create')} {...props}>
    <TabbedForm redirect="list">
      <FormTab label={t('Product.form_general')}>
        <ImageInput source="pictures" label={t('Menu.image')} accept="image/*" options={{ onDropRejected: () => alert(t('Menu.error_image')) }} placeholder={<p>{t('Menu.tip_image')}</p>} multiple={false} validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="name" label={t('Menu.name')} style={{ width: '100%' }} />
        <LongTextInput source="description" label={t('Menu.description')} />
        <TextInput source="external_url" label={t('Menu.external_url')} style={{ width: '100%' }} />
        {permissions && permissions.premium && <BooleanInput label={t('Menu.payment')} source="payment" />}
        {permissions && permissions.premium && <span>{t('Menu.enable_payment')}<a href={"/#/venue/" + localStorage.getItem('venue') + "/3"}>{t('Menu.enable_payment_link')}</a>.</span>}
        {permissions && permissions.premium && <BooleanInput label={t('Menu.service')} source="table_service" />}
      </FormTab>
      <FormTab label={t('Menu.exceptions')}>
                  <ArrayInput source="exceptions" label={t('Product.exceptions')} fullWidth>
                    <SimpleFormIterator>
                    <FormDataConsumer>
            {
              ({formData, getSource}) => {
                return (
                    <>
                        <ReferenceInput label={t('Calendar.calendars')} source={getSource("calendar_id")} reference="calendar" validate={required()}>
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                        <div style={{display:'flex', width:'100%', alignItems:'baseline'}}>
                          <NumberInput label={t('Menu.position')} source={getSource("position")} defaultValue={formData.position} style={{marginRight:50}} />
                          <BooleanInput source={getSource("unavailable")} label={t('Menu.unavailable')} defaultValue={formData.unavailable}/>
                        </div>
                      </>
                      )
              }
            }
          </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
        </FormTab>
      <FormTab label={t('Product.translations')}>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.name) {
                return (<DisabledInput label={t('Menu.name')} source="name" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => {
              if (formData.description) {
                return (<DisabledInput label={t('Menu.description')} source="descritpion" style={{ width: '100%' }} />)
              }
            }
          }
        </FormDataConsumer>
        <ArrayInput label={t('Menu.translations')} source="languages" style={{ width: '100%' }}>
          <SimpleFormIterator>
            <TextInputField source='name' label={t('Menu.name')} validate={required()}  />
            <TextInputField source="description" label={t('Menu.description')} />
            <SelectInput source="locale" label={t('Menu.language')} choices={locales} style={{ width: '100%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>

export default SectionCreate