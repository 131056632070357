import React, { cloneElement, useEffect, useState } from 'react'
import { dataProvider } from '../clients/providers'
import { t } from '../i18n'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { addField, GET_LIST } from 'react-admin'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const DragHandle = SortableHandle(() => (<DragHandleIcon color="disabled" style={{ cursor: "move" }} />))

const styles = theme => ({
	remove: {
		color: "red",
		'&:hover': {
			backgroundColor: "mistyrose"
		},
		marginRight: "50px"
	}
})

const SortableItem = withStyles(styles)(SortableElement(props => {
	const children = fields ? [].concat(fields) : null
	const { onRemove, label, baseSource, fields } = props
	const { classes } = props
	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%", backgroundColor: "white" }}>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0px 20px", height: "60px", width: "100%", justifyContent: "space-between" }}>
				<div style={{ display: "flex", alignItems: "center", height: "20px", lineHeight: "20px", width: "100%" }}>
					<DragHandle />
					<Typography variant="body1" style={{ paddingLeft: "20px", width: "100%" }}>{label}</Typography>
					<div style={{ width: "100%" }}>
						{children && children.length > 0 && children.map((field, index) => (
							<div key={label + '-' + index} style={{ padding: 5 }}>
								{cloneElement(field, {
									...field.props,
									source: baseSource + "." + label + "." + field.props.source,
								})}
							</div>
						))}
					</div>
				</div>
				<Button size="small" className={classes.remove} onClick={onRemove}>
					{t("Generic.remove")}
				</Button>
			</div>
			<div style={{ borderBottom: "1px solid lightgray" }} />
		</div>
	)
}))

SortableItem.propTypes = {
	classes: PropTypes.object.isRequired,
}

const SortableList = SortableContainer(({ items, onRemoveItem, baseSource, fields }) => {
	return (
		<div style={{ width: "100%" }}>
			<style dangerouslySetInnerHTML={{ __html: `.sortableHelper{ z-index:10; }` }} />
			<Typography variant="caption" style={{ marginLeft: "25px", padding: "40px 0px 20px 40px", width: "100%" }}>{t("Generic.name")}</Typography>
			<div style={{ borderBottom: "1px solid lightgray" }} />
			{items && items.map((value, index) => (
				<SortableItem
					key={`item-${index}`}
					baseSource={baseSource}
					fields={fields}
					index={index}
					label={value.name}
					onRemove={() => onRemoveItem(index)}
				/>
			))}
		</div>
	)
}
)

const SortableArray = props => {
	const {
		input: { name, onChange, value: items, ...rest },
		meta: { touched, error },
		isRequired
	} = props
	const { reference, source, children: fields } = props
	const { label } = props
	const { classes } = props

	const onSortEnd = ({ oldIndex, newIndex }) => {
		onChange(arrayMove(items, oldIndex, newIndex))
	}

	const confirmRemoveItem = (index) => {
		items.splice(index, 1)
		fetchItems()
		onChange([...items])
		setRemoveIndex(null)
		setShowDialog(false)
	}

	const addItem = item => {
		items.push(item)
		onChange([...items])
		setOpen(false)
	}

	const openSelection = event => {
		const { currentTarget } = event
		setAnchorEl(currentTarget)
		setOpen(!open)
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)
	const id = open ? 'simple-popper' : null
	const [fetchedItems, setFetchedItems] = useState([])
	const [showDialog, setShowDialog] = useState(false)
	const [removeIndex, setRemoveIndex] = useState(null)

	const fetchItems = () => {
		dataProvider(GET_LIST, reference, { pagination: { page: 1, perPage: 100 }, sort: { field: 'position', order: 'ASC' } })
			.then(({ data, total }) => {
				setFetchedItems(data.map(item => ({ id: item.id, name: item.name })).filter(item => !(items || []).some(i => i.id === item.id)))
			})
			.catch(e => {
				console.log(e)
			})
	}

	useEffect(() => {
		fetchItems()
	}, [])

	return (
		<div style={{ marginLeft: "-25px", width: "calc(100% + 50px)" }}>
			<Button aria-describedby={id} variant="text" size="small" style={{ float: "right", marginRight: "25px", padding: "10px", color: "#2a52be" }} onClick={openSelection}>
				<AddIcon style={{ paddingRight: "5px" }} />
				{t("Generic.add")}
			</Button>
			<Popper style={{ zIndex: 2, minWidth: "200px" }} id={id} open={open} anchorEl={anchorEl} transition>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={() => setOpen(false)}>
						<Fade {...TransitionProps} timeout={350}>
							<Paper>
								<List component="nav">
									{
										fetchedItems.map(item =>
											<ListItem key={item.id} button onClick={() => addItem(item)}>
												<ListItemText primary={item.name} />
											</ListItem>
										)
									}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
			<SortableList
				useDragHandle
				items={items}
				baseSource={source}
				fields={fields}
				helperClass="sortableHelper"
				onSortEnd={onSortEnd}
				onRemoveItem={index => { setRemoveIndex(index); setShowDialog(true) }}
			/>
			<Dialog fullWidth open={showDialog} onClose={() => setShowDialog(false)} aria-label="Are you sure?">
				<DialogTitle>{t('Generic.remove_from_list')}</DialogTitle>
				<DialogContent>
					<Typography variant="body1">
						{t('Generic.will_remove')}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowDialog(false)} color="primary" variant="contained">
						{t('Generic.cancel')}
					</Button>
					<Button
						className={classNames(
							'ra-delete-button',
							classes.deleteButton
						)}
						style={{ marginRight: '1em' }}
						onClick={() => confirmRemoveItem(removeIndex)}>
						{t('Generic.remove')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export const SortableArrayInput = addField(withStyles(styles)(SortableArray))