import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils
} from 'react-admin';

import { stringify } from 'query-string';

const resourcePath = (type, resource) => {
  const nop = () => resource;
  const venue = () => localStorage.getItem('venue');

  return ({
    'section': (type, resource) => `menu/${venue()}/section`,
    'category': (type, resource) => `menu/${venue()}/category`,
    'product': (type, resource) => `menu/${venue()}/product`,
    'menu': (type, resource) => `menu/${venue()}/import`,
    'bundle-products': (type, resource) => `menu/${venue()}/bundleproducts`,
    'cost': (type, resource) => `menu/${venue()}/cost`,
    'instruction': (type, resource) => `menu/${venue()}/instruction`,
    'table': (type, resource) => `venue/${venue()}/table`,
    'order': (type, resource) => `order/${venue()}`,
    'delivery': (type, resource) => `order/${venue()}`,
    'history': (type, resource) => `order/${venue()}/history`,
    'dump': (type, resource) => `order/dump`,
    'open': (type, resource) => `venue/${venue()}/open`,
    'businesses': (type, resource) => `integrations/${venue()}/businesses`,
    'discount': (type, resource) => `subscription/discount`,
    'guest': (type, resource) => `user/${venue()}/guest`,
    'recommended': (type, resource) => `menu/${venue()}/recommended`,
    'console': (type, resource) => `console`,
    'profile': (type, resource) => `venue/profile`,
    'section-availability': (type, resource) => `menu/${venue()}/section/available`,
    'category-availability': (type, resource) => `menu/${venue()}/category/available`,
    'product-availability': (type, resource) => `menu/${venue()}/product/available`,
    'calendar': (type, resource) => `venue/${venue()}/calendar`,
    'customer': (type, resource) => `user/${venue()}/customer`,
    
  }[resource] || nop)(type, resource);
};

const resourceMethod = (type, resource) => {
  return ({
    GET_LIST: 'GET',
    GET_ONE: 'GET',
    GET_MANY: 'GET',
    GET_MANY_REFERENCE: 'GET',
    UPDATE: 'PUT',
    CREATE: 'POST',
    DELETE: 'DELETE'
  })[type];
};

const filterList = (type, resource) => {
  return {
    'product': products => products.sort((a, b) => {
      if (a.category_id !== b.category_id) {
        if (a.category_position === b.category_position) {
          return a.category_name < b.category_name ? -1 : 1;
        }
        return a.category_position - b.category_position
      }
      return a.position - b.position;
    }),
    'section': sections => sections.sort((a, b) => a.position - b.position),
    'category': categories => categories.sort((a, b) => a.position - b.position)
  }[resource] || (data => data);
}

/*
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertRESTRequestToHTTP = (apiURL, type, resource, params) => {
  let url = '';
  const options = {};

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        sort: field,
        order: order === 'ASC' ? 'desc' : 'asc',
        page: page - 1,
        pageSize: perPage
      };

      url = `${apiURL}/${resourcePath(type, resource)}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiURL}/${resourcePath(type, resource)}/${params.id}`;
      break;
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };

      url = `${apiURL}/${resourcePath(type, resource)}?${stringify(query)}`;
      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id })
      };

      url = `${apiURL}/${resourcePath(type, resource)}?${stringify(query)}`;
      break;
    }
    case UPDATE:
      url = `${apiURL}/${resourcePath(type, resource)}/${params.id}`;
      options.method = resourceMethod(type, resource);
      options.body = JSON.stringify(params.data);
      break;
    case CREATE:
      url = `${apiURL}/${resourcePath(type, resource)}`;
      options.method = resourceMethod(type, resource);
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiURL}/${resourcePath(type, resource)}/${params.id}`;
      options.method = resourceMethod(type, resource);
      options.body = JSON.stringify(params.data);
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

/*
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (response, type, resource, params) => {
  const { headers, json } = response;

  switch (type) {
    case GET_MANY:
    case GET_MANY_REFERENCE:
    case GET_LIST:
      return {
        data: filterList(type, resource)(json.map(x => x)),
        total: parseInt(headers.get('X-Total-Count'), 10)
      };
    default:
      return { data: json };
  }
};

/*
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
const createRestProvider = (apiURL, styleApiURL) => {
  function restClient(type, resource, params) {
    let customUrl = apiURL;

    if(resource === "tag" ||  resource === "banner")  {
      customUrl = styleApiURL
    }
    
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id => restClient(DELETE, resource, { id }))
      ).then(
        responses => ({
          data: responses.map(response => (response.data || {}).id)
        })
      );
    }

    const { fetchJson } = fetchUtils;
    const { url, options } = convertRESTRequestToHTTP(customUrl, type, resource, params);

    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token');

    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchJson(url, options)
      .then(response => convertHTTPResponseToREST(response, type, resource, params));
  }

  return restClient;
};



export default createRestProvider;
