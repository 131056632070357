import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import React, { useState } from "react";
import { COVER } from "../constants";

const SwitchStyled = withStyles({
  switchBase: {
    height: 0,
  },
  root: {
    display: "inline",
  },
})(Switch);

const ImageFormatInput = ({ handleChangeStyle, property, perimeter, value, defaultValue }) => {
  const [contain, setContain] = useState(value[property] && value[property] !== defaultValue ? false : true);

  const handleChange = (event) => {
    const display = contain ? defaultValue : COVER;
    setContain(!contain);

    handleChangeStyle(display, property, perimeter);
  };

  return (
    <FormControl style={{ minWidth: 30, marginRight: 10 }}>
      <FormControlLabel
        control={<SwitchStyled checked={contain} onChange={handleChange} name='checkedA' color='primary' />}
        label={
          contain ? (
            <AspectRatioIcon style={{ display: "flex", marginLeft: -5 }} color='primary' />
          ) : (
            <AspectRatioIcon style={{ display: "flex", marginLeft: -5 }} color='disabled' />
          )
        }
      />
    </FormControl>
  );
};
export default ImageFormatInput;
