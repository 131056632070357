import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'react-router-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { t } from '../../i18n'

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

class CancelOrderButton extends Component {
  state = {
    showDialog : false
  }

  render() {
    const { showDialog } = this.state
    const { classes } = this.props

    const handleClick = () => {
      this.setState({ showDialog: true })
    };

    const handleCloseClick = () => {
      this.setState({ showDialog: false })
    }

    const handleDelete = () => {
      const { record, destination, showNotification, push } = this.props

      fetch(`${window._env_.REACT_APP_API_URL}/order/${record.id}?notification=1`, {
        method : 'DELETE'
      })
      .then(
        () => {
          showNotification(t('Order.deleted'));
          this.setState({ showDialog: false });
          destination && push(destination);
        },
        e => {
          console.error(e);
          showNotification(t('Order.deletion_error'), 'warning')
        }
      )
    }

    return (
      <React.Fragment>
        <Button
          color="primary"
          variant="outlined"
          style={{marginRight : '1em'}}
          onClick={handleClick}
          >
          {t('Order.delete')}
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Are you sure?">
          <DialogTitle>
            {t('Order.delete_ask')}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t('Order.will_delete')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseClick}
              color="primary"
              variant="contained"
            >
              {t('Order.cancel')}
            </Button>
            <Button
              className={classnames(
                'ra-delete-button',
                classes.deleteButton
              )}
              style={{marginRight : '1em'}}
              onClick={handleDelete}
            >
              {t('Order.delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(
    null,
    {showNotification, push}
  )
)(CancelOrderButton)
