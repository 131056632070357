export const IFRAME = "iframe";
export const APPERO_WEB_SELECTOR = "appero-web-selector";
export const APPERO_WEB_CHANGE_SUCCESSFUL = "appero-web-change-successful";
export const PREPARE_IFRAME = "prepare-iframe";
export const ENTER_SELECT_MODE = "enter-select-mode";
export const QUIT_SELECT_MODE = "quit-select-mode";
export const FONT_FAMILY = "font-family";
export const FONT_COLOR = "color";
export const FONT_SIZE = "font-size";
export const BACKGROUND_COLOR = "background-color";
export const BORDER_TOP_WIDTH = "border-top-width";
export const BORDER_TOP_COLOR = "border-top-color";
export const JUSTIFY_CONTENT = "justify-content";
export const CENTER = "center";
export const START = "start";
export const END = "end";
export const VISIBILITY = "opacity";
export const DISPLAY = "display";
export const FLEX = "flex";
export const BLOCK = "block";
export const BORDER_TOP = "border-top";
export const NONE = "none";
export const MARGIN = "margin";
export const OBJECT_FIT = "object-fit";
export const CONTAIN = "contain";
export const COVER = "cover";
export const RESTORE_STYLES = "restore-styles";
export const CHANGE_COLOR_DELAY = 500;
export const SINGLE_ELEMENT = "singleElement";
export const CATEGORY = "category";
export const MENU = "menu";
export const ALL_ELEMENTS = "allElements";
export const SHOW_HIDDEN_ELEMENTS = "show-hidden-elements";
export const HIDE_HIDDEN_ELEMENTS = "hide-hidden-elements";


export const DAYS = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const HOURS = ["", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
