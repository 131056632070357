import React from 'react'
import {
    Edit,
    TabbedForm,
    FormTab,
    SimpleFormIterator,
    BooleanInput,
    TextInput,
    LongTextInput,
    ArrayInput,
    ReferenceArrayInput,
    SelectArrayInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    DisabledInput,
    required
} from 'react-admin'
import { t } from '../../i18n'

const locales = [
    { id: 'fr-FR', name: 'Français' },
    { id: 'en-GB', name: 'English' },
    { id: 'es-ES', name: 'Español' },
    { id: 'pt-PT', name: 'Português' },
    { id: 'it-IT', name: 'Italiano' },
    { id: 'de-DE', name: 'Deutsch' },
    { id: 'ru-RU', name: 'русский' },
    { id: 'jp-JP', name: '日本語' },
    { id: 'cn-CN', name: '中文（简体)' },
    { id: 'ar-AR', name: 'العربية' },
    { id: 'he-IL', name: 'עברית' },
  ]

export const CostEdit = (props) =>
    <Edit title={t('Product.edit_cost')} undoable={false} {...props}>
        <TabbedForm redirect="list">
            <FormTab label={t('Product.form_general')}>
                <TextInput source="name" label={t('Product.name')} validate={required()} style={{ width: '100%' }} />
                <LongTextInput source="recipe" label={t('Product.recipe')} />
                <SelectInput source="application" label={t('Product.apply_to')} choices={[
                    { id: 'venue', name: t('Product.cost_apply_venue') },
                    { id: 'section', name: t('Product.cost_apply_section') },
                    { id: 'category', name: t('Product.cost_apply_category') },
                ]} style={{ width: '100%' }} />
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => {
                            switch (formData.application) {
                                case 'venue':
                                    break
                                case 'section':
                                    return (
                                        <>
                                            <ReferenceInput source="sections" reference="section" label={t('Product.menus')} style={{ width: '100%' }}>
                                                <SelectInput source="id" optionText="name" style={{ width: '100%' }} />
                                            </ReferenceInput>
                                            {
                                                formData.manual ? null : <BooleanInput source="multiple" label={t('Product.cost_apply_multiple')} />
                                            }
                                            {
                                                formData.multiple ? null :
                                                    <>
                                                        <BooleanInput source="manual" label={t('Product.cost_manual')} />
                                                        <NumberInput source="threshold" label={t('Product.cost_limit')} defaultValue={0} style={{ width: '100%' }} />
                                                    </>
                                            }
                                        </>
                                    )
                                case 'category':
                                    return (
                                        <>
                                            <ReferenceInput source="category_id" reference="category" label={t('Product.category')} style={{ width: '100%' }}>
                                                <SelectInput source="id" optionText="name" style={{ width: '100%' }} />
                                            </ReferenceInput>
                                            {
                                                formData.manual ? null : <BooleanInput source="multiple" label={t('Product.cost_apply_multiple')} />
                                            }
                                            {
                                                formData.multiple ? null :
                                                    <>
                                                        <BooleanInput source="manual" label={t('Product.cost_manual')} />
                                                        <NumberInput source="threshold" label={t('Product.cost_limit')} defaultValue={0} style={{ width: '100%' }} />
                                                    </>
                                            }
                                        </>
                                    )
                                default:
                                    break
                            }
                        }
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) =>
                            formData.manual ?
                                <LongTextInput source="message" label={t('Product.cost_message')} /> : null
                    }
                </FormDataConsumer>
                <ReferenceArrayInput label={t('Product.cost_apply_products')} perPage={500} source="products_id" reference="product" style={{ width: '100%' }}>
                    <SelectArrayInput source="id" optionText="name" style={{ width: '100%' }} />
                </ReferenceArrayInput>
                <BooleanInput source="bar" label={t('Product.cost_bar')} />
                <BooleanInput source="table" label={t('Product.cost_table')} />
                <NumberInput label={t('Product.incl_tax_price')} source="net_price" defaultValue={1} validate={required()} style={{ width: '100%' }} />
                <NumberInput label={t('Product.tax_rate')} format={v => v * 100} parse={v => v / 100} source="tax_rate" defaultValue={0.2} validate={required()} style={{ width: '100%' }} />
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) =>
                            <DisabledInput
                                label={t('Product.excl_tax_price')}
                                source="price_ht"
                                format={v => Math.round(v * 100) / 100}
                                defaultValue={formData.net_price / (formData.tax_rate + 1)}
                                style={{ width: '100%' }}
                            />
                    }
                </FormDataConsumer>
                <BooleanInput source="unavailable" label={t('Product.unavailable')} />
            </FormTab>
            <FormTab label={t('Product.translations')}>
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => {
                            if (formData.name) {
                                return (<DisabledInput label={t('Product.name')} source="name" style={{ width: '100%' }} />)
                            }
                        }
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => {
                            if (formData.recipe) {
                                return (<DisabledInput label={t('Product.recipe')} source="recipe" style={{ width: '100%' }} />)
                            }
                        }
                    }
                </FormDataConsumer>
                <ArrayInput label={t('Product.translations')} source="languages" style={{ width: '100%' }}>
                    <SimpleFormIterator>
                        <TextInput source="name" label={t('Product.name')} validate={required()} style={{ width: '100%' }} />
                        <TextInput source="recipe" label={t('Product.recipe')} style={{ width: '100%' }} />
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) =>
                                    formData.message ?
                                        <LongTextInput source="message" label={t('Product.cost_message')} /> : null
                            }
                        </FormDataConsumer>
                        <SelectInput source="locale" label={t('Product.language')} choices={locales} style={{ width: '100%' }} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>

export default CostEdit
