import React from 'react'
import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	ReferenceArrayField,
	ArrayField,
	SingleFieldList,
	ChipField
} from 'react-admin'
import { t } from '../../i18n'

export default props =>
	<Show title={t('Category.edit')} {...props}>
		<SimpleShowLayout redirect="list">
			<TextField source='name' label={t('Category.name')} />
			<TextField source="tagline" label={t('Category.tagline')} />
			<ReferenceArrayField source="sections" reference="section">
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>
			<BooleanField source="unavailable" label={t('Category.unavailable')} />
			<ArrayField source="products" reference="product">
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ArrayField>
		</SimpleShowLayout>
	</Show>