/**
 * Upload a `File` object returned by the upload input to Cloudinary.
 * We will then store the URL provided by cloudinary and use it to later fetch the image.
 */

const convertXLSXToBinary = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file.rawFile)
    reader.onload = () => {
        resolve(reader.result)
    }
})

const resizeImage = (file, width) => new Promise((resolve, reject) => {
    var jimp = require('jimp')
    const reader = new FileReader()
    reader.readAsArrayBuffer(file.rawFile)
    reader.onload = () => {
        jimp.read(reader.result)
            .then(image => {
                return image
                    .resize(width, jimp.AUTO)
                    .background(0xFFFFFFFF)
                    .quality(85)
                    .getBase64Async(jimp.MIME_JPEG)
            })
            .then(image => {
                resolve(image)
            })
            .catch(err => {
                reject(err)
            })
    }
})

/**
 * For venues creation/update only, upload the image and attach its URL to
 * the `picture` sent property, with `src` and `title` attributes.
 */


const addUploadFeature = requestHandler => (type, resource, params) => {
    if ((type === 'UPDATE' || type === 'CREATE') && (resource === 'venue' || resource === 'profile' || resource === 'product' || resource === 'section' || resource === 'bundle')) {
        if (params.data.pictures) {
            const image = params.data.pictures
            return resizeImage(image, 800).then(img =>
                requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: img
                    }
                })
            )
        }
    }

    if (type === 'CREATE' && resource === 'menu') {
        return Promise.resolve(convertXLSXToBinary(params.data.files))
            .then(result => requestHandler(type, resource, {
                ...params,
                data: {
                    xlsx: result
                }
            }))
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;