import React from 'react'
import { Edit, TabbedForm, FormTab, TextInput, ArrayInput, SimpleFormIterator, SelectInput, required } from 'react-admin'
import { t } from '../../i18n'

const locales = [
    { id: 'fr-FR', name: 'Français' },
    { id: 'en-GB', name: 'English' },
    { id: 'es-ES', name: 'Español' },
    { id: 'pt-PT', name: 'Português' },
    { id: 'it-IT', name: 'Italiano' },
    { id: 'de-DE', name: 'Deutsch' },
    { id: 'ru-RU', name: 'русский' },
    { id: 'jp-JP', name: '日本語' },
    { id: 'cn-CN', name: '中文（简体)' },
    { id: 'ar-AR', name: 'العربية' },
    { id: 'he-IL', name: 'עברית' },
  ]

const InstructionEdit = (props) =>
    <Edit title={t('Instruction.edit')} undoable={false} {...props}>
        <TabbedForm redirect="list">
            <FormTab label={t('Product.form_general')}>
                <TextInput source="name" label={t('Instruction.name')} style={{ width: '100%' }} />
                <ArrayInput source="instructions" label={t('Instruction.instructions')} style={{ width: '100%' }} >
                    <SimpleFormIterator>
                        <TextInput source="name" label={t('Instruction.name')} style={{ width: '100%' }} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={t('Instruction.translations')}>
                <ArrayInput label={t('Instruction.translations')} source="languages" style={{ width: '100%' }}>
                    <SimpleFormIterator>
                        <SelectInput source="locale" label={t('Product.language')} choices={locales} style={{ width: '100%' }} />
                        <TextInput source="name" label={t('Instruction.name')} validate={required()} style={{ width: '100%' }} />
                        <ArrayInput label={t('Instruction.instructions')} source="instructions" validate={required()} style={{ width: '100%' }} >
                            <SimpleFormIterator>
                                <TextInput source="name" label={t('Instruction.name')} validate={required()} style={{ width: '100%' }} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>

export default InstructionEdit