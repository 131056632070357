import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import GetApp from '@material-ui/icons/GetApp'
import writeXlsxFile from 'write-excel-file'

export default props => {
	const { data, fileName, schema, sheet } = props

	const save = async () => {
		await writeXlsxFile(data, {
			schema,
			fileName,
			sheet
		  })
	}

	return (
		<Button variant="outlined" style={{ color: "black", height: "23px", minHeight: "20px", width: "30px", minWidth: "30px", margin: "0px 5px", borderColor: "black" }} onClick={save}>
			<GetApp style={{ padding: "5px", height: "20px" }} />
		</Button>
	)
}