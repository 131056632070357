import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, Notification } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MenuPreview from "./MenuPreview";
import ToolPanel from "./ToolPanel";

import { t } from "../../i18n";
import CustomizationToolbar from "./CustomizationToolbar";

const styles = (theme) => ({
  container: {
    display: "flex",
    marginTop: 5,
    padding: 10,
  },
});

const MenuCustomization = (props) => {
  const { classes } = props;
  const venueId = localStorage.getItem("venue");
  

  return (
    <Card key={venueId}>
      <Title title={t("Menu.customization")} />
      <CardContent style={{padding:0}}>
        <CustomizationToolbar venueId={venueId} />
        <div className={classNames(classes.container)}>
          <MenuPreview venueId={venueId} />
          <ToolPanel venueId={venueId} />
        </div>
      </CardContent>
      <Notification />
    </Card>
  );
};

export default withStyles(styles)(MenuCustomization);
