import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showNotification } from 'react-admin'
import { push } from 'react-router-redux'
import compose from 'recompose/compose'
import { t } from '../../i18n'

class OrderPaidButton extends Component {
  render() {
    const handleClick = () => {
      const { record, destination, showNotification, push } = this.props;

      const token = localStorage.getItem('token')

      fetch(`${window._env_.REACT_APP_API_URL}/order/${record.id}/status/delivered`, {
        method : 'PUT',
        headers : new Headers({ 'Authorization' : `Bearer ${token}`})
      })
      .then(
        () => {
          showNotification('Commande payée');
          destination && push(destination);
          this.setState({ showDialog: false });
        },
        e => {
          console.error(e);
          showNotification(t('Order.connexion_error'), 'warning');
        }
      )
    }

    return (
      <Button color="primary" variant="contained" style={{marginRight : '1em'}} onClick={handleClick}>
        {t('Order.paid')}
      </Button>
    )
  }
}

export default compose(
  connect(
    null,
    {showNotification, push}
  )
)(OrderPaidButton)
