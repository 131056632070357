import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import sqlFormatter from '@sqltools/formatter'

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    bootstrapRoot: {
        paddingBottom: 20,
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        height: '250px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    bootstrapFormLabel: {
        fontSize: 18,
    },
})

const Console = props => {
    const { classes } = props
    const [request, setRequest] = useState('')
    const [response, setResponse] = useState('')
    const sendRequest = req => {
        const jwt = localStorage.getItem('token')
        const user = localStorage.getItem('email')
        fetch(`${window._env_.REACT_APP_API_URL}/console`, {
            method: 'POST',
            headers: new Headers({ 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' }),
            body: JSON.stringify({ rqst: req, user: user })
        }).then(response => response.json())
            .then(json => setResponse(JSON.stringify(json, null, 2)))
    }

    return (
        <div className={classes.container}>
            <TextField
                defaultValue=""
                label="SQL Request"
                id="bootstrap-input"
                rows={20}
                InputProps={{
                    disableUnderline: true,
                    multiline: true,
                    classes: {
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    className: classes.bootstrapFormLabel,
                }}
                value={request}
                onChange={event => {
                    if (event.target.value.charAt(event.target.value.length - 1) === ' ') {
                        setRequest(sqlFormatter.format(event.target.value, {
                            language: "sql",
                            indent: '\t',
                            reservedWordCase: 'upper'
                        }) + ' ')
                    } else {
                        setRequest(sqlFormatter.format(event.target.value, {
                            language: "sql",
                            indent: '\t',
                            reservedWordCase: 'upper'
                        }))
                    }
                }}
            />
            <Button color="primary" variant="contained" style={{ margin: '0px 0px 10px auto', width: '100px' }} onClick={() => sendRequest(request)}>{"Envoyer"}</Button>
            <TextField
                defaultValue={response}
                label="Response"
                id="bootstrap-input"
                InputProps={{
                    disableUnderline: true,
                    multiline: true,
                    classes: {
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    className: classes.bootstrapFormLabel,
                }}
                disabled
            />
        </div>
    )
}

Console.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Console)