import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import QRious from 'qrious';

class QRField extends React.Component {
  render() {
    const { record } = this.props;
    const { id, name } = record;

    const qr = new QRious({
      size    : 700,
      padding : 50,
      backgroundAlpha: 0,
      value   : window._env_.REACT_APP_WEB_URL + '#' + id
    });

    const data = qr.toDataURL();

    const onClick = (e) => {
      e.stopPropagation();
    };

    return (
      <IconButton component="a" download={name+'.png'} href={data} onClick={onClick}>
        <Typography variant="button">
          QR
        </Typography>
      </IconButton>
    );
  }
}

export default QRField;
