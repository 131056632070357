import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import GetApp from '@material-ui/icons/GetApp'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import BrushIcon from '@material-ui/icons/Brush'
import { WithPermissions } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MUITextField from '@material-ui/core/TextField'
import Button from "@material-ui/core/Button"
import { t } from "../../i18n"

const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
})

const BillList = props => {
    const { classes } = props
    const [bills, setBills] = useState([])
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [amount, setAmount] = useState(0)
    const [selectedBill, setSelectedBill] = useState(null)

    const createCredit = () => {
        const jwt = localStorage.getItem("token")
        fetch(
            new Request(`${window._env_.REACT_APP_API_URL}/subscription/storeCredit`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                }),
                body: JSON.stringify({ amount: amount, bill: selectedBill }),
            })
        )
            .then(() => window.location.reload())
        setShowCreateDialog(false)
    }

    const updateCredit = () => {
        const jwt = localStorage.getItem("token")
        fetch(
            new Request(`${window._env_.REACT_APP_API_URL}/subscription/storeCredit/${selectedBill}`, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                }),
                body: JSON.stringify({ amount: amount }),
            })
        )
            .then(() => window.location.reload())
        setShowCreateDialog(false)
    }

    useEffect(() => {
        const jwt = localStorage.getItem('token')
        const venue = localStorage.getItem('venue')

        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/history`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            })
        }))
            .then(response => response.json())
            .then(json => {
                console.log(json)
                setBills(json)
            })
    }, [])

    const download = id => {
        const jwt = localStorage.getItem('token')
        const venue = localStorage.getItem('venue')

        fetch(new Request(`${window._env_.REACT_APP_API_URL}/subscription/${venue}/history/${id}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            })
        })).then(response => {
            return response.json()
        }).then(json => {
            const downloadLink = document.createElement("a")
            const fileName = json.filename
            downloadLink.href = json.pdf
            downloadLink.download = fileName
            downloadLink.click()
        })
    }

    const billDate = date => {
        return (new Date(date)).toLocaleDateString('fr-FR')
    }

    const isCredit = bill => bill.parent_id != null

    const defaultCreditValue = () => {
        let bill = bills.find(b => b.id === selectedBill)
        return bill ? bill.price : 0
    }

    return (
        <WithPermissions render={({ permissions }) => (
            <div className={classes.demo}>
                <List dense={false}>
                    {(bills || []).map(bill =>
                        <ListItem key={bill.id}>
                            {!isCredit(bill) && <ListItemText primary={'Facture du ' + billDate(bill.date)} />}
                            {isCredit(bill) && <ListItemText primary={'Avoir du ' + billDate(bill.date)} />}
                            <ListItemSecondaryAction>
                                {permissions && permissions.admin && !bill.parent_id &&
                                    <IconButton aria-label="Credit" onClick={() => { setSelectedBill(bill.id); setShowCreateDialog(true) }}>
                                        <MonetizationOn />
                                    </IconButton>
                                }
                                {isCredit(bill) &&
                                    <IconButton aria-label="Edit" onClick={() => { setSelectedBill(bill.id); setShowEditDialog(true) }}>
                                        <BrushIcon />
                                    </IconButton>}
                                <IconButton aria-label="Download" onClick={() => download(bill.id)}>
                                    <GetApp />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                <Dialog fullWidth open={showCreateDialog} onClose={() => setShowCreateDialog(false)} aria-label="Create a credit">
                    <DialogTitle>{t('Bills.create_credit')}</DialogTitle>
                    <DialogContent>
                        <MUITextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            label={t('Bills.amount')}
                            type="number"
                            onChange={(e) => { setAmount(e.target.value) }}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={createCredit} color="primary">
                            {t('Generic.add')}
                        </Button>
                        <Button style={{ marginRight: '1em', color: 'red' }} onClick={() => setShowCreateDialog(false)}>
                            {t('Generic.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth open={showEditDialog} onClose={() => setShowEditDialog(false)} aria-label="Edit a credit">
                    <DialogTitle>{t('Bills.edit_credit')}</DialogTitle>
                    <DialogContent>
                        <MUITextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            label={t('Bills.amount')}
                            defaultValue={defaultCreditValue()}
                            type="number"
                            onChange={(e) => { setAmount(e.target.value) }}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={updateCredit} color="primary">
                            {t('Generic.edit')}
                        </Button>
                        <Button style={{ marginRight: '1em', color: 'red' }} onClick={() => setShowEditDialog(false)}>
                            {t('Generic.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )} />
    )
}

BillList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BillList);