import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import TableQRField from './TableQRField'
import { t } from '../../i18n'

const TableList = (props) =>
  <List {...props} bulkActionButtons={false} pagination={null} title={t('Venue.form_tables')}>
    <Datagrid rowClick="edit">
      <TextField source="number" label={t('Venue.table_number')} sortable={false}/>
      <TableQRField label="QR Code" sortable={false}/>
    </Datagrid>
  </List>

export default TableList