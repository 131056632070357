import io from 'socket.io-client'

export default class IO {
  constructor(url) {
    this.socket = null;
    this.url    = url;

    this.token = null;
    this.venue = null;

    this.callbackId = 0;
    this.callbacks  = {};
  }

  on(eventName, callback) {
    if (this.socket) {
      this.checkVenue();
    }
    else {
      this.connect();
    }

    if (!this.callbacks[eventName]) {
      this.callbacks[eventName] = {};
    }

    const callbackId = ++this.callbackId + '';
    this.callbacks[eventName][callbackId] = callback;

    // return unsubscribe method
    return () => {
      delete this.callbacks[eventName][callbackId];
    };
  }

  runCallbacks(eventName, data) {
    Object.values(this.callbacks[eventName] || {}).forEach(
      callback => callback && callback(data)
    );
  }

  checkVenue(venue) {
    if (this.venue !== venue) {
      this.socket.disconnect();
      this.connect();
    }
  }

  connect() {
    this.token = localStorage.getItem('token');
    this.venue = localStorage.getItem('venue');

    this.socket = io(this.url, {
      transports: ['websocket'],
      jsonp: false
    });

    /**
     * Authentication
     *
     * The following calls are used for authenticating with the underlying
     * server. It mostly deals with 'auth_' messages
     */

    // auth:request
    this.socket.on('auth:request', () => {
      this.socket.emit('auth:authenticate', {
        token : this.token,
        venue : this.venue
      });
    });

    // auth:authenticated
    this.socket.on('auth:authenticated', () => {
      // apiGetOrders()
    })

    // auth:expired
    this.socket.on('auth:expired', () => {
      // let p = new Promise().resolve()
      // p.then(() => {
      //   // renewToken()
      // })
      // p.then(() => {
      //   // this.socket.emit('auth:authenticate', getAccessToken())
      // })
    })

    /**
     * Orders management
     *
     * Here's how we deal with orders' updates
     */
    this.socket.on('order:update', order => this.runCallbacks('order:update', order));
    this.socket.on('order:cancel', order => this.runCallbacks('order:cancel', order));
  }
}
