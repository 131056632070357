import React, { useEffect, useState } from 'react'
import { Title } from 'react-admin'
import { connect } from 'react-redux'
import 'react-calendar/dist/Calendar.css'
import ProductsCard from './components/ProductsCard'
import UniquesCard from './components/UniquesCard'
import LanguagesCard from './components/LanguagesCard'
import LinksCard from './components/LinksCard'

const ApperoDashboard = props => {
	const [venue, setVenue] = useState(null)
	useEffect(() => {
		if (props.venue != null && props.venue.name != null) {
			setVenue(props.venue.name)
		} else {
			setVenue(null)
		}

		return () => {
			setVenue(null)
		}
	})

	return (
		venue != null ? <div style={{ display: 'flex', flexDirection: 'column' }}>
			<Title title="Dashboard" />
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<ProductsCard venue={venue} />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<UniquesCard venue={venue} />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<LanguagesCard venue={venue} />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<LinksCard venue={venue} />
			</div>
		</div>
		:
		null
	)
}

const Dashboard = connect(({ venue }) => ({ venue }))(ApperoDashboard)

export default Dashboard