import React from 'react'
import { CardContentInner, NumberField } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import grey from '@material-ui/core/colors/grey'
import OutOfStockButton from '../../action-buttons/OutOfStockButton'
import Paper from '@material-ui/core/Paper'
import { t } from '../../../i18n'

const styles = {
  list: {
    marginTop: '1em',
  },
  quantity: {
    display: 'inline-block',
    width: '40px',
    textAlign: 'right'
  },
  productName: {
    display: 'inline-block',
  },
  price: {
    display: 'inline-block'
  },
  total: {
    borderTop: '1px solid ' + grey[200]
  }
}

const RestaurantOrderForm = props => {
  const {
    record,
    subheading,
    toolbar,
    extra,
    rightHeader,
    inert
  } = props
  const { customer = {} } = record

  const SubheadingComponent = subheading
  const ToolbarComponent = toolbar
  const ExtraComponent = extra
  const RightHeaderComponent = rightHeader

  const formatDate = recordDate => {
    let date_ob = new Date(recordDate)
    let date = ("0" + date_ob.getDate()).slice(-2)
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2)
    let year = date_ob.getFullYear()
    let hours = ("0" + date_ob.getHours()).slice(-2)
    let minutes = ("0" + date_ob.getMinutes()).slice(-2)
    return "Le " + date + "/" + month + "/" + year + " à " + hours + ":" + minutes
  }

  return (
    <div>
      <CardContentInner>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography component="h2" variant="headline">
                  {t('Order.order_of', { name: customer.name })}
                </Typography>
                {
                  customer.table ?
                    <Typography component="h2" variant="headline">
                      {t('Order.table_number', { number: customer.table })}
                    </Typography>
                    :
                    null
                }
                {
                  customer.phone ?
                    <Typography variant="subheading">
                      {t('Order.order_of_phone', { phone: customer.phone })}
                    </Typography>
                    :
                    null
                }
                {
                  customer.email ?
                    <Typography variant="subheading">
                      {t('Order.order_of_email', { email: customer.email })}
                    </Typography>
                    :
                    null
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography component="h2" variant="headline">
                  {formatDate(record.creation_date)}
                </Typography>
                <SubheadingComponent record={record} />
              </div>
            </div>

          </div>
          <div>
            {
              rightHeader ? <RightHeaderComponent record={record} /> : null
            }
          </div>
        </div>
        {
          extra ?
            <div style={{ marginTop: '2em', marginBottom: '2em' }}>
              <ExtraComponent record={record} />
            </div> :
            null
        }
        {
          record.note ?
            <Paper style={{ padding: '10px' }}>
              <Typography variant="subheading" style={{ fontWeight: 'bold', paddingBottom: '10px' }}>{t('Order.customer_note')}</Typography>
              <Typography variant="body1">{record.note}</Typography>
            </Paper>
            :
            null
        }
        <Table style={{ marginTop: '2em', marginBottom: '2em' }}>
          <TableBody>
            {
              props.record.items.map(item => (
                <TableRow key={item.uuid} style={item.unavailable ? { backgroundColor: '#CCC' } : {}}>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="subheading">
                      {item.qty}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="subheading">
                      {item.name}
                    </Typography>
                    {(item.instructions || []).map(i => <Typography key={i} variant="body1">{i}</Typography>)}
                  </TableCell>
                  {
                    item.sub_products ?
                      item.sub_products.map(subproduct =>
                        <TableRow key={subproduct.uuid}>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <Typography variant="subheading">
                              {subproduct.qty}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <Typography variant="subheading">
                              {subproduct.name}
                            </Typography>
                            {(subproduct.instructions || []).map(i => <Typography key={i} variant="body1">{i}</Typography>)}
                          </TableCell>
                        </TableRow>
                      )
                      :
                      <TableCell></TableCell>
                  }
                  <TableCell style={{ width: '99%' }}>
                    <NumberField
                      source="net_price"
                      options={{ style: 'currency', currency: 'EUR' }}
                      record={item}
                    />
                  </TableCell>
                  <TableCell align="right" padding="default">
                    {
                      item.unavailable || inert ? null : <OutOfStockButton record={item} />
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter style={{ borderTop: '2px solid #AAA' }}>
            <TableRow>
              <TableCell>
                <Typography variant="subheading">
                  <span style={{ fontWeight: 'bold' }}>
                    {props.record.items.reduce((a, n) => a + n.qty, 0)}
                  </span>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              {props.record.items.filter(i => i.sub_products && i.sub_products.length).length ? <TableCell></TableCell> : null}
              <TableCell>
                <Typography variant="subheading">
                  <NumberField
                    source="total"
                    style={{ fontWeight: 'bold' }}
                    options={{ style: 'currency', currency: 'EUR' }}
                    record={{ total: props.record.items.reduce((a, n) => a + n.net_price, 0) }}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContentInner>
      <ToolbarComponent record={record} />
    </div>
  )
}

// class OrderForm extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       items: props.record.items
//     }
//   }


//   render() {
//     const {
//       record,
//       subheading,
//       toolbar,
//       extra,
//       rightHeader,
//       inert
//     } = this.props
//     const { customer = {} } = record

//     const SubheadingComponent = subheading
//     const ToolbarComponent = toolbar
//     const ExtraComponent = extra
//     const RightHeaderComponent = rightHeader

//     const formatDate = recordDate => {
//       let date_ob = new Date(recordDate)
//       let date = ("0" + date_ob.getDate()).slice(-2)
//       let month = ("0" + (date_ob.getMonth() + 1)).slice(-2)
//       let year = date_ob.getFullYear()
//       let hours = date_ob.getHours()
//       let minutes = date_ob.getMinutes()
//       return "Le " + date + "/" + month + "/" + year + " à " + hours + ":" + minutes
//     }

//     return (
//       <div>
//         <CardContentInner>
//           <div style={{ display: 'flex', floatDirection: 'row', justifyContent: 'space-between' }}>
//             <div>
//               <Typography component="h2" variant="headline">
//                 {t('Order.order_of', { name: customer.name })}
//               </Typography>
//               <Typography component="h2" variant="headline">
//                 {formatDate(record.creation_date)}
//               </Typography>
//               {
//                 customer.table ?
//                   <Typography component="h2" variant="headline">
//                     {t('Order.table_number', { number: customer.table })}
//                   </Typography>
//                   :
//                   null
//               }
//               {
//                 customer.phone ?
//                   <Typography variant="subheading">
//                     {t('Order.order_of_phone', { phone: customer.phone })}
//                   </Typography>
//                   :
//                   null
//               }
//               {
//                 customer.email ?
//                   <Typography variant="subheading">
//                     {t('Order.order_of_email', { email: customer.email })}
//                   </Typography>
//                   :
//                   null
//               }
//               <SubheadingComponent record={record} />
//             </div>
//             <div>
//               {
//                 rightHeader ? <RightHeaderComponent record={record} /> : null
//               }
//             </div>
//           </div>
//           {
//             extra ?
//               <div style={{ marginTop: '2em', marginBottom: '2em' }}>
//                 <ExtraComponent record={record} />
//               </div> :
//               null
//           }
//           {
//             record.note ?
//               <Paper style={{ padding: '10px' }}>
//                 <Typography variant="subheading" style={{ fontWeight: 'bold', paddingBottom: '10px' }}>{t('Order.customer_note')}</Typography>
//                 <Typography variant="body1">{record.note}</Typography>
//               </Paper>
//               :
//               null
//           }
//           <Table style={{ marginTop: '2em', marginBottom: '2em' }}>
//             <TableBody>
//               {
//                 this.state.items.map(item => (
//                   <TableRow key={item.uuid} style={item.unavailable ? { backgroundColor: '#CCC' } : {}}>
//                     <TableCell style={{ whiteSpace: 'nowrap' }}>
//                       <Typography variant="subheading">
//                         {item.qty}
//                       </Typography>
//                     </TableCell>
//                     <TableCell style={{ whiteSpace: 'nowrap' }}>
//                       <Typography variant="subheading">
//                         {item.name}
//                       </Typography>
//                       {item.instructions ? item.instructions.map(i => <Typography variant="body1">{i}</Typography>) : null}
//                     </TableCell>
//                     {
//                       item.sub_products ?
//                         item.sub_products.map(subproduct =>
//                           <TableRow key={subproduct.uuid}>
//                             <TableCell style={{ whiteSpace: 'nowrap' }}>
//                               <Typography variant="subheading">
//                                 {subproduct.qty}
//                               </Typography>
//                             </TableCell>
//                             <TableCell style={{ whiteSpace: 'nowrap' }}>
//                               <Typography variant="subheading">
//                                 {subproduct.name}
//                               </Typography>
//                             </TableCell>
//                           </TableRow>
//                         )
//                         :
//                         <TableCell></TableCell>
//                     }
//                     <TableCell style={{ width: '99%' }}>
//                       <NumberField
//                         source="net_price"
//                         options={{ style: 'currency', currency: 'EUR' }}
//                         record={item}
//                       />
//                     </TableCell>
//                     <TableCell align="right" padding="dense">
//                       {
//                         item.unavailable || inert ? null : <OutOfStockButton record={item} />
//                       }
//                     </TableCell>
//                   </TableRow>
//                 ))
//               }
//             </TableBody>
//             <TableFooter style={{ borderTop: '2px solid #AAA' }}>
//               <TableRow>
//                 <TableCell>
//                   <Typography variant="subheading">
//                     <span style={{ fontWeight: 'bold' }}>
//                       {this.state.items.reduce((a, n) => a + n.qty, 0)}
//                     </span>
//                   </Typography>
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell>
//                   <Typography variant="subheading">
//                     <NumberField
//                       source="total"
//                       style={{ fontWeight: 'bold' }}
//                       options={{ style: 'currency', currency: 'EUR' }}
//                       record={{ total: this.state.items.reduce((a, n) => a + n.net_price, 0) }}
//                     />
//                   </Typography>
//                 </TableCell>
//               </TableRow>
//             </TableFooter>
//           </Table>
//         </CardContentInner>
//         <ToolbarComponent record={record} />
//       </div>
//     )
//   }
// }


const OrderForm = withStyles(styles)(RestaurantOrderForm)

export default OrderForm

// OrderForm = withStyles(styles)(OrderForm)

// export default OrderForm
