import React, { useState } from 'react'
import { List, TextField, Mutation, Datagrid, BooleanField } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import { SortableDataGrid } from '../SortableDataGrid'
import { t } from '../../i18n'

const UnavailableSwitch = ({ source, record }) => {

  const [checked, setChecked] = useState(!!record[source])

  return (
    <Mutation
      type="UPDATE"
      resource="section-availability"
      payload={{ id: record.id, data: { ...record, [source]: !checked } }}
      options={{ undoable: false }}>
      {
        (toggleUnavailable) => (
          <Switch
            onClick={event => {
              event.stopPropagation()
              setChecked(!checked)
              record[source] = !record[source]
              toggleUnavailable()
            }}
            color="primary"
            checked={checked} />
        )
      }
    </Mutation>
  )
}

const onSort = sections => {
  const jwt = localStorage.getItem('token')
  fetch(`${window._env_.REACT_APP_API_URL}/menu/section/sort`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
    }),
    body: JSON.stringify({ sections: sections })
  }).then(() => window.location.reload())
}

const SectionList = ({ permissions, ...props }) =>
  permissions && permissions.reader ?
  <List {...props} bulkActionButtons={false} pagination={null} title={t('Menu.title')}>
    <Datagrid rowClick="show">
      <TextField source="name" label={t('Menu.name')} sortable={false} />
      <BooleanField source="unavailable" label={t('Menu.unavailable')} sortable={false} />
    </Datagrid>
  </List>
  :
  <List {...props} bulkActionButtons={false} pagination={null} title={t('Menu.title')}>
    <SortableDataGrid rowClick="edit" onSort={sections => onSort(sections)}>
      <TextField source="name" label={t('Menu.name')} sortable={false} />
      <UnavailableSwitch source="unavailable" label={t('Menu.unavailable')} sortable={false} />
    </SortableDataGrid>
  </List>

export default SectionList
