import React from 'react'
import { List, Datagrid, TextField, DateField, Pagination, Filter, TextInput } from 'react-admin'
import QRField from './QRField'
import { t } from '../../i18n'

const VenuePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
const VenueFilter = (props) => (
  <Filter {...props}>
    <TextInput label={t('Venue.search')} source="name" alwaysOn style={{width: '300px'}} />
  </Filter>
)

const VenueStatus = props => {
  const { record } = props

  return (  
      record.active ? <div style={{height: '16px', width: '16px', borderRadius: '8px', backgroundColor: 'lime'}} /> : <div style={{height: '16px', width: '16px', borderRadius: '8px', backgroundColor: 'red'}} /> 
  )
}

const VenueList = (props) =>
  <List {...props} bulkActionButtons={false} perPage={25} pagination={<VenuePagination />} filters={<VenueFilter />} title={t('Venue.title')}>
    <Datagrid rowClick="edit">
      <VenueStatus sortable={false} />
      <TextField source="name" label={t('Venue.name')} sortable={false}/>
      <TextField source="address" label={t('Venue.address')} sortable={false}/>
      <DateField source="creation_date" label={t('Venue.creation')} sortable={false}/>
      <QRField sortable={false}/>
    </Datagrid>
  </List>

export default VenueList
