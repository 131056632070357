import React, { useState } from 'react'
import { List, Datagrid, TextField, NumberField, Mutation } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import { t } from '../../i18n'

const HTPrice = ({ source, record, ...props }) => {
  const { net_price, tax_rate } = record
  const price = net_price / (tax_rate + 1)

  return (<NumberField {...props} record={{ ...record, [source]: price }} source={source} />)
}

const UnavailableSwitch = ({ source, record, ...props }) =>  {

  const [checked, setChecked] = useState(!!record[source])

  return (
    <Mutation
      type="UPDATE"
      resource="cost"
      payload={{ id: record.id, data: { ...record, [source]: !checked } }}
      options={{ undoable: false }}>
      {
        (toggleUnavailable) => (
          <Switch
            onClick={event => {
              event.stopPropagation()
              setChecked(!checked)
              toggleUnavailable()
            }}
            color="primary"
            checked={checked} />
        )
      }
    </Mutation>
  )
}

const CostList = (props) =>
    <List {...props} bulkActionButtons={false} pagination={null} title={t('Product.title')}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={t('Product.name')} sortable={false} />
        <TextField source="section_name" label={t('Menu.title')} sortable={false} />
        <TextField source="category_name" label={t('Product.category')} sortable={false} />
        <NumberField source="net_price" label={t('Product.incl_tax_price')} options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
        <NumberField source="tax_rate" label={t('Product.tax_rate')} options={{ style: 'percent' }} sortable={false} />
        <HTPrice source="ht_price" label={t('Product.excl_tax_price')} options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
        <UnavailableSwitch source="unavailable" label={t('Product.unavailable')} sortable={false} />
      </Datagrid>
    </List>

export default CostList
