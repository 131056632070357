import React, { useEffect, useState } from 'react'
import { Layout, AppBar, UserMenu, GET_ONE, AUTH_GET_PERMISSIONS, AUTH_LOGOUT, MenuItemLink } from 'react-admin'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'
import PublishIcon from '@material-ui/icons/Publish'
import ComputerIcon from '@material-ui/icons/Computer'
import { withStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import { authProvider } from '../../clients/providers'
import { loadVenue } from '../../actions/venue'

import decodeJwt from 'jwt-decode'

import packageJson from '../../../package.json'

import { t } from '../../i18n'
import LocaleSwitcher from '../action-buttons/LocaleSwitcher'

const VenueSearch = props =>
  <TextField
    id="search"
    label={t('Venue.venue')}
    type="search"
    style={{ width: "80%", margin: "0px 20px 10px 20px" }}
    margin="normal"
    onChange={event => {
      if (event.target.value === '') {
        props.onChange(props.venues)
      } else {
        props.onChange(props.venues.filter(venue => venue.name.toLowerCase().includes(event.target.value.toLowerCase())))
      }
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />


const VenuePicker = props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [venueList, setVenueList] = useState([])
  const { onClick, classes, title, selected } = props

  useEffect(() => {
    if (!venueList.length && props.venues) {
      setVenueList(props.venues)
    }
  })

  return (
    <div>
      <Button aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={event => setAnchorEl(event.currentTarget)} style={{ color: 'white', textTransform: 'capitalize' }}>
        {title || ''}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {props.venues.length > 1 && <VenueSearch venues={props.venues} onChange={venues => setVenueList(venues)} />}
        {
          venueList.map(venue =>
            <MenuItemLink key={venue.id} className={selected === venue.id ? classes.selected : ''} to={"/"} primaryText={venue.name} onClick={() => onClick(venue.id)} />
          )
        }
      </Menu>
    </div>
  )
}

const styles = theme => ({
  selected: {
    color: blue[500]
  }
})

const CustomUserMenu = props => {
  const [venues, setVenues] = useState([])
  const [selected, setSelected] = useState(null)
  const { classes, name } = props

  useEffect(() => {
    const token = localStorage.getItem('token')
    const decodedToken = decodeJwt(token)
    const { mgr = [], bar = [], id } = decodedToken

    fetch(`${window._env_.REACT_APP_API_URL}/venue/user/${id}`, {
      method: 'GET',
      headers: new Headers({ 'Authorization': `Bearer ${token}` })
    })
      .then(response => response.json())
      .then(venues => {
        if (venues.code && venues.message) {
          authProvider(AUTH_LOGOUT)
        } else {
          setVenues(venues)
        }
        if (venues && venues.length) {
          localStorage.setItem('business', venues.filter(v => v.id === localStorage.getItem('venue'))[0].name)
        }
      })

    setSelected(localStorage.getItem('venue'))
  }, [])

  const clickHandler = id => {
    localStorage.setItem('venue', id)
    setSelected(id)
    loadVenue(id)
    setTimeout(() => {
      window.location.replace('/')
    }, 100)
  }

  return (
    <>
      <LocaleSwitcher />
      <VenuePicker onClick={clickHandler} classes={classes} title={name} venues={venues} selected={selected} />
      <UserMenu {...props}>
        <MenuItemLink to={"/venue/" + selected} primaryText={t('Profile.title')} leftIcon={<SettingsIcon />} />
        <MenuItemLink to={"/menu/create"} primaryText={t('Resources.import')} leftIcon={<PublishIcon />} />
        {localStorage.getItem('email') === 'ash@ashleycorbion.com' && <MenuItemLink to={"/console"} primaryText={t('Console.title')} leftIcon={<ComputerIcon />} />}
      </UserMenu>
    </>
  )
}

const ApperoUserMenu = withRouter(withStyles(styles)(CustomUserMenu))

const CustomAppBar = props => {
  const [isManager, setIsManager] = useState(false)
  const { loadVenue, venue, ...sanitizedProps } = props

  useEffect(() => {
    loadVenue(localStorage.getItem('venue'))
    authProvider(AUTH_GET_PERMISSIONS).then(
      ({ manager, admin }) => setIsManager(manager || admin)
    )
  }, [])

  return (
    <AppBar {...sanitizedProps} userMenu={<ApperoUserMenu name={venue.name} />} position="sticky" style={{ height: 48, background: 'linear-gradient(to right bottom, #223f67, #1e6694, #0091be, #00bee2, #00ecff)' }}>
      <Typography
        variant="title"
        color="inherit"
        id="react-admin-title"
        style={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }} />
    </AppBar>
  )
}

const ApperoAppBar = connect(
  ({ venue }) => ({
    venue
  }),
  {
    loadVenue
  }
)(CustomAppBar)

const ApperoLayout = props =>
  <>
    <Layout {...props} appBar={ApperoAppBar} />
    <div style={{ position: 'fixed', bottom: '10px', right: '10px', fontSize: 10, zIndex: 1000 }}>
      v{packageJson.version}
    </div>
  </>

export default ApperoLayout
