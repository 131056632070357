import { TextField } from "muiCustom";
import React from "react";
import { Field } from "redux-form";
import "./textInputField.css";


const renderTextField = ({ input, label, meta: { touched, error }, ...custom }, props) => {
  return (
    <TextField
      variant='outlined'
      multiline
      label={label}
      error={!!(touched && error)}
      helperText={touched && error}
      {...input}
      type={"textarea"}
      style={{ margin: "25px 0 0 0" }}
      minRows={2}
    />
  );
};

const TextInputField = (props) => {
  return <Field name={props.source || ""} required={props.validate} component={renderTextField} label={props.label} />;
};

export default TextInputField;
