import React from 'react'
import { Edit, SimpleForm, NumberInput } from 'react-admin'
import { t } from '../../i18n'

const TableEdit = (props) =>
    <Edit title={t('Table.edit')} undoable={false} {...props}>
        <SimpleForm redirect="list">
            <NumberInput source="number" label={t('Venue.table_number')} />
        </SimpleForm>
    </Edit>

export default TableEdit