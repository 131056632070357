import React from 'react'
import Typography from '@material-ui/core/Typography'
import OrderForm from './OrderForm'
import { t } from '../../../i18n'

class InertOrderForm extends React.Component {
  render() {
    return (
      <OrderForm
        inert={true}
        {...this.props}
        subheading={
          () =>
            <Typography component="h3" variant="subheading">
              {t('Order.abandoned')}
            </Typography>
        }
        toolbar={() => null} />
    )
  }
}

export default InertOrderForm
