import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { t } from '../../../i18n'
import 'react-calendar/dist/Calendar.css'

export default props => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [interval, setInterval] = useState(1)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = interval => {
		setAnchorEl(null)
		setInterval(interval)
		props.onIntervalPicked(interval)
	}

	return (
		<div style={{ margin: "0px 5px" }}>
			<Button aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={handleClick} style={{ textTransform: 'none', border: '1px solid black', minHeight: '0px', height: '22px', fontWeight: 'bold' }}>
				{interval === 1 ? t('Analytics.daily') : t('Analytics.hourly')}
			</Button>
			<Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => handleClose(1)} disabled={interval === 1} selected={interval != 1}>{t('Analytics.daily')}</MenuItem>
				<MenuItem onClick={() => handleClose(-3600000)} disabled={interval === -3600000} selected={interval != -3600000}>{t('Analytics.hourly')}</MenuItem>
			</Menu>
		</div>
	)
}