import React from 'react'
import { Datagrid, List, TextField, NumberField, FunctionField, CardActions } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { t } from '../../i18n'

const styles = {
  itemContainer : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'flex-begin'
  }
}

const productsCount = ({items}) =>
  (items || []).reduce((a,n) => a + n.qty, 0)

const totalPrice = ({items}) =>
  (items || []).reduce((a,n) => a + n.net_price, 0)

const TotalPriceField = ({record, source}) => {
  const price = totalPrice(record);
  const newRecord = {...record, [source] : price}

  return <NumberField
    source={source}
    options={{ style: 'currency', currency: 'EUR' }}
    record={newRecord}
  />
}

const ItemsField = withStyles(styles)(({record, source, classes}) => {
  const items = record[source]

  return (
    <div>
      {
        items.map(item =>
          <div key={item.name} className={classes.itemContainer}>
            <div>
              <Typography variant="body1">
                {item.qty} x
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                &nbsp;{item.name}
              </Typography>
            </div>
          </div>
        )
      }
    </div>
  )
})

const createRowStyle = (record) => {
  if (record.status === 'READY') {
    return {
      backgroundColor : '#CCFFCC'
    }
  }
  if (record.status === 'IN_PROGRESS') {
    return {
      backgroundColor : '#FFF'
    }
  }
  if (record.status === 'ON_HOLD') {
    return {
      backgroundColor : '#CCCCCC'
    }
  }
}

const Toolbar = (props) => {
  const { ids } = props;

  return (
    <CardActions>
      <Typography variant="headline">
        {t('Order.queue', { queue: ids.length })}
      </Typography>
    </CardActions>
  )
}


const RestaurantDeliveriesDataGrid = ({ids, data, ...props}) => <Datagrid
  ids={ids.filter(id => {
    const order = data[id] || {}
    return order.status === 'IN_PROGRESS' || order.status === 'READY'
  }).sort(
    (a,b) => new Date((data[a] || {}).creation_date).getTime() - new Date((data[b] || {}).creation_date).getTime()
  )}
  data={data}
  {...props}
/>

const RestaurantDeliveriesList = (props) =>
  <List actions={<Toolbar />} {...props} bulkActionButtons={false} pagination={null} title={t('Order.preparation')}>
    <RestaurantDeliveriesDataGrid rowClick="edit" rowStyle={createRowStyle}>
      <TextField label={t('Order.client')} source="customer.name" sortable={false}/>
      <TextField label={t('Order.table')} source="customer.table" sortable={false}/>
      <FunctionField label={t('Order.number_products')} render={productsCount} sortable={false}/>
      <ItemsField label={t('Order.products')} source="items" sortable={false}/>
      <TotalPriceField label={t('Order.price')} source="total_price" sortable={false}/>
      <TextField label='ID' source="ref" sortable={false} />
    </RestaurantDeliveriesDataGrid>
  </List>

export default RestaurantDeliveriesList
