import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SegmentedControl from './SegmentedControl'
import { t } from '../../../i18n'
import * as Analytics from '../Analytics'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { formatDate, sheetName, graphTitle } from '../dashboardHelpers'
import WorldMap from 'react-svg-worldmap'
import ExportXLSXButton from './ExportXLSXButton'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import iso from 'iso-3166-1'

const LanguagesCard = props => {
	const [languageData, setLanguageData] = useState([])
	const [showCalendarPicker, setShowCalendarPicker] = useState(false)
	const [dateRange, setDateRange] = useState()
	const [title, setTitle] = useState('')
	const [sheet, setSheet] = useState('')
	const [selectedControl, setSelectedControl] = useState(1)

	const getCountryAlpha2 = country => {
		let isoCode
		let index = country.indexOf('-')
		if (index >= 0) {
			isoCode = iso.whereAlpha2(country.substring(index + 1))
			if (isoCode) {
				return isoCode.alpha2
			} else {
				isoCode = iso.whereNumeric(parseInt(country.substring(index + 1)))
				if (isoCode) {
					return isoCode.alpha2
				} else {
					isoCode = iso.whereAlpha3(country.substring(index + 1))
					return isoCode ? isoCode.alpha2 : country.slice(0, 2)
				}
			}
		} else {
			let isoCode = iso.whereAlpha2(country)
			if (isoCode) {
				return isoCode.alpha2
			} else {
				isoCode = iso.whereAlpha3(country)
				if (isoCode) {
					return isoCode.alpha2
				} else {
					isoCode = iso.whereNumeric(parseInt(country))
					return isoCode ? isoCode.alpha2 : country.slice(0, 2)
				}
			}
		}
	}

	const getUserLanguageData = days => {
		setShowCalendarPicker(false)
		setTitle(graphTitle(days))
		setSheet(sheetName(days))
		let now = new Date()
		now.setDate(now.getDate() - 1)
		let end = formatDate(now)
		now.setDate(now.getDate() - days)
		let start = formatDate(now)
		Analytics.getUserLanguagesInRange(props.venue, start, end).then(
			data => {
				if (data && data.forEach) {
					let formatted = []
					data.forEach(item => {
						let country = getCountryAlpha2(item.label)
						let exists = formatted.find(e => e.country === country)
						if (exists) {
							exists.value += item.value
						} else {
							formatted.push({ country: country, value: item.value })
						}
					})
					setLanguageData(formatted.sort((a, b) => b.value - a.value))
				}
			}
		).catch(error => {
			localStorage.clear()
			props.push('/login')
		})
	}

	const controlOptions = [
		{
			label: t('Analytics.week'),
			action: () => { getUserLanguageData(7); setSelectedControl(0) }
		},
		{
			label: t('Analytics.month'),
			action: () => { getUserLanguageData(30); setSelectedControl(1) }
		},
		{
			label: <div style={{ display: 'block', margin: '2px auto' }} ><svg width="16" height="16" viewBox="0 0 16 16" className="Icon_icon__2tZrT Icon_small__2paD0"><g fillRule="nonzero"><path d="M14.482 1.22h-1.067V.61a.61.61 0 00-1.22 0v.61H3.66V.61a.61.61 0 00-1.22 0v.61H1.372C.616 1.22 0 1.835 0 2.59v11.89c0 .757.616 1.373 1.372 1.373h13.11c.756 0 1.372-.616 1.372-1.372V2.592c0-.757-.616-1.372-1.372-1.372zM1.372 2.439h1.067v.61a.61.61 0 001.22 0v-.61h8.536v.61a.61.61 0 001.22 0v-.61h1.067c.084 0 .152.068.152.152v1.677H1.22V2.591c0-.084.068-.152.152-.152zm13.11 12.195H1.372a.153.153 0 01-.152-.152V5.488h13.414v8.994a.153.153 0 01-.152.152z"></path><circle cx="4.61" cy="9.61" r="1"></circle><circle cx="8.049" cy="9.61" r="1"></circle><circle cx="11.488" cy="9.61" r="1"></circle></g></svg></div>,
			action: () => { setShowCalendarPicker(!showCalendarPicker); setSelectedControl(2) }
		}
	]

	const exportSchema = () => {
		return [
			{
				column: t('Analytics.user_language'),
				type: String,
				value: language => language.country,
				width: t('Analytics.user_language').length + 2
			},
			{
				column: t('Analytics.users'),
				type: Number,
				format: '#,##0',
				value: language => language.value
			}
		]
	}

	useEffect(() => {
		if (props.venue && languageData.length === 0) {
			getUserLanguageData(30)
		}
	}, [])

	const onDatesPicked = value => {
		if (value && value.length === 2) {
			setShowCalendarPicker(false)
			setDateRange(value)
			setTitle(graphTitle(null, value))
			setSheet(sheetName(null, value))
			Analytics.getUserLanguagesInRange(props.venue, formatDate(value[0]), formatDate(value[1])).then(
				data => {
					if (data && data.forEach) {
						let formatted = []
						data.forEach(item => {
							let country = getCountryAlpha2(item.label)
							let exists = formatted.find(e => e.country === country)
							if (exists) {
								exists.value += item.value
							} else {
								formatted.push({ country: country, value: item.value })
							}
						})
						setLanguageData(formatted.sort((a, b) => b.value - a.value))
					}
				}
			).catch(error => {
				localStorage.clear()
				props.push('/login')
			})
		}
	}

	const mapStyling = ({
		countryValue,
		countryCode,
		minValue,
		maxValue,
		color,
	}) => {
		const values = languageData.map(l => l.value)
		const labels = languageData.map(l => l.country)
		const min = Math.min.apply(Math, values)
		const max = Math.max.apply(Math, values)
		const opacityLevel = countryValue ? 0.1 + (1.5 * (countryValue - min)) / (max - min) : 0

		return {
			fill: labels.includes(countryCode) ? color : 'lightgray',
			fillOpacity: min === max ? 1.0 : opacityLevel,
			stroke: labels.includes(countryCode) ? '#89CFF0' : 'black',
			strokeWidth: 1,
			strokeOpacity: 0.2,
			cursor: 'pointer',
		}
	}

	return (
		<Card style={{ width: '97%', height: '600px', margin: '5px', textAlign: 'center' }}>
			<CardContent>
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
					<Typography variant="headline">{t('Analytics.user_language')}</Typography>
					<div style={{ flex: 1 }}></div>
					<SegmentedControl key="sc4" className="sc4" options={controlOptions} style={{ flex: 1, width: '100%' }} selected={selectedControl} />
					<ExportXLSXButton fileName="languages.xlsx" data={languageData} schema={exportSchema()} sheet={sheet} />
				</div>
				<Typography variant="body2" style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>{title}</Typography>
				{showCalendarPicker &&
					<div style={{ display: 'block', position: 'absolute', right: '5%', zIndex: 10 }}>
						<Calendar onChange={onDatesPicked} value={dateRange} defaultView="month" selectRange={true} returnValue="range" />
					</div>
				}
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
					<WorldMap
						color="#318CE7"
						title=""
						size="xl"
						valueSuffix="users"
						data={languageData}
						styleFunction={mapStyling}
					/>
					<div style={{ height: "450px", overflowY: "scroll" }}>
						{
							languageData.map(l =>
								<div key={l.country} style={{ textAlign: "left", fontFamily: "Roboto, sans-serif" }}>
									{t(`Countries.${l.country.toUpperCase()}`) + `: ${l.value}`}
								</div>)
						}
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

LanguagesCard.propTypes = {
	push: PropTypes.func
}

export default connect(
	null,
	{ push }
)(LanguagesCard)