import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormatSizeIcon from "@material-ui/icons/FormatSize";

import { t } from "../../../i18n";
import { BORDER_TOP_WIDTH } from "../constants";

const SeparatorsBorderInput = ({ handleChangeStyle, property, perimeter, value }) => {
  const [borderWidth, setBorderWidth] = useState(value[BORDER_TOP_WIDTH] || "");

  useEffect(
    () => {
      setBorderWidth(value[BORDER_TOP_WIDTH] || "");
    },
    [value]
  );

  const handleChange = (event) => {
    setBorderWidth(event.target.value);
    handleChangeStyle(event.target.value, property, perimeter);
  };

  return (
    <FormControl style={{ minWidth: 30, marginRight: 10 }}>
      <Select value={borderWidth} onChange={handleChange} style={{ fontSize: 14 }}>
        {Array.from({ length: 10 }, (v, k) => k + 1).map((size, index) => (
          <MenuItem key={`border-width ${size}-${index}`} value={`${size}px`}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SeparatorsBorderInput;
